/**
 * Traverse an object based on a path and return the value at the destination
 * @param pathSrc - String value of the path IE: application.users[1].addresses[2].city
 * @param entitySrc - The object or array to extract the vaue from
 * @param indexesSrc - Override array indexes set by pathSrc using string notation. Supports nested arrays: { application.users : 1, application.users.addresses[3] }.
 * @param subProp - Extract from a lower level value than the object. Intended to use with anguar reactive forms to extract controls since the use the following format: form.controls.application.users[0].controls. IE 'controls'
 */
export const objectTraverser = (path: string, entity: any, indexes?: { [key: string]: number }, prop?: string): any => {
  // console.log('objectTraverser', path, entity, indexes, prop);
  // Determine if this path has children or is the last prop in path
  const pathInfo = hasChildren(path);
  if (pathInfo.pathNext) {
    const isArr = isArray(pathInfo.pathCurrent, indexes);
    // console.log('isArr', isArr);
    const result = getValue(isArr.index, pathInfo.pathCurrent, entity, prop);
    // console.log('result', result);
    return objectTraverser(pathInfo.pathNext, result, isArr.indexes, prop);
  } else {
    // This is the last property in the path
    const isArr = isArray(path, indexes);
    let result;
    // If final path is an array
    // TODO: Move this logic into isArray
    if (path.match(/\[(.*?)\]/gi)) {
      result = getValue(true, path, entity, prop);
    } else {
      result = getValue(isArr.index, path, entity, prop);
    }

    return result;
  }
};

/**
 *  Extract the correct value out of the destination path
 * @param isArr
 * @param pathSrc
 * @param obj
 * @param prop
 */
const getValue = (isArr: number | boolean, path: string, entity: any, prop?: string) => {
  try {
    // console.log('getValue', isArr, path, entity, prop);
    if (isArr === false) {
      // Final destination is an item
      return prop ? entity[prop][path] : entity[path];
    }
    if (isArr === true) {
      // If final destination is an ARRAY and not an item within the array
      // Need to remove brackets from path
      return prop ? entity[prop][path.replace(/\[(.*?)\]/gi, '')] : entity[path.replace(/\[(.*?)\]/gi, '')];
    } else {
      // Final destination is an item within an array
      const pathNew = path.split('[')[0];
      // Note that for arrays use following format: array.controls[].entry.controls
      return prop ? entity[prop][pathNew][prop][isArr] : entity[pathNew][isArr];
    }
  } catch (err) {
    console.error('Unable to find the following field: ', path);
  }
};

/**
 * Determines which array index to use. Returns false if none.
 * Will return an updated index object that will mirror the current path as it traverses
 * @param path
 * @param indexes
 */
const isArray = (
  path: string,
  indexes: { [key: string]: number },
): { index: number | false; indexes: { [key: string]: number } } => {
  // console.info('isArray 1', path, indexes);
  // Determine if brackets are present in curren tpath
  const res = path.match(/\[(.*?)\]/gi);
  // Create a new index aray that holds reduced indexes
  const indexesNew: { [key: string]: number } = {};
  // Hold the index override which replaces the fixed index if present
  let indexOverride: number = null;
  // Loop through current index
  if (indexes && Object.keys(indexes).length) {
    Object.keys(indexes).forEach(key => {
      const pathRoute = hasChildren(key);
      if (path.replace(/\[(.*?)\]/gi, '') === pathRoute.pathCurrent) {
        const propNew = key
          .split('.')
          .slice(1)
          .join('.');
        indexesNew[propNew] = indexes[key];
        // If there is no next path, set override
        if (!pathRoute.pathNext) {
          indexOverride = indexes[key];
        }
      }
    });
  }
  // Get the index if specified in the input path
  const indexFixed = res
    ? res.map(strSub => {
        const resSub = strSub.match(/(?:\[)(.+?)(?:\])/g);
        return resSub && resSub.length ? parseInt(resSub[0][1]) : 0;
      })[0]
    : null;

  const result = {
    index: <number | false>false,
    indexes: indexesNew,
  };

  // console.log('hasChildren', hasChildren(path));

  // If this is an overridden index, use that, then default to indexFixed if present
  if (indexOverride !== null) {
    result.index = indexOverride;
  } else if (indexFixed !== null) {
    result.index = indexFixed;
  }

  return result;
};

/**
 * Check if a curent path has children
 * @param path
 */
const hasChildren = (path: string) => {
  if (path.indexOf('.') !== -1) {
    // Get the first property in the path
    const pathCurrent = path.split('.')[0];
    // Get the remaining path by removing the current path
    const pathNext = path
      .split('.')
      .slice(1)
      .join('.');
    return {
      pathCurrent: pathCurrent,
      pathNext: pathNext,
    };
  }
  return {
    pathCurrent: path,
    pathNext: null,
  };
};

/**
 * Example
const app = {
  applications: {
    borrowers: [
      {
        address: 'Address 0',
        phones: [
          {
            num: 'Address 0 Phone 0',
          },
          {
            num: 'Address 0 Phone 1',
          },
          {
            num: 'Address 0 Phone 2',
          },
        ],
      },
      {
        address: 'Address 1',
        phones: [
          {
            num: 'Address 1 Phone 0',
          },
          {
            num: 'Address 1 Phone 1',
          },
          {
            num: 'Address 1 Phone 2',
          },
        ],
      },
      {
        address: 'Address 2',
        phones: [
          {
            num: 'Address 2 Phone 0',
          },
          {
            num: 'Address 2 Phone 1',
          },
          {
            num: 'Address 2 Phone 2',
          },
        ],
      },
    ],
  },
  emptyProp: '',
};

  console.warn(
    'Result:',
    objectTraverser('applications.borrowers[].address', app, {
      'applications.borrowers': 1,
    }),
  );
  console.warn(
    'Result:',
    objectTraverser('applications.borrowers[].address', app, {
      'applications.borrowers': 0,
    }),
  );

console.warn(
  'Result:',
  objectTraverser('applications.borrowers[].phones[].num', app, {
    'applications.borrowers': 2,
    'applications.borrowers.phones': 2,
  }),
);

*/
