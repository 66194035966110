import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, EventEmitter } from '@angular/core';
import { UIStoreService } from '$ui';
import { combineLatest } from 'rxjs';
import { ICPOSAppConfig } from 'src/app/shared/models';
import { map, filter, distinctUntilChanged, startWith } from 'rxjs/operators';

export enum FieldTypes {
  'backgroundcolor' = 'backgroundcolor',
  'backgroundimage' = 'backgroundimage',
  'textcolor' = 'textcolor',
  'textline1' = 'textline1',
  'textline2' = 'textline2',
  'overlap' = 'overlap',
}

interface Banner {
  [index: string]: any;
  backgroundcolor: string;
  backgroundimage: string;
  textcolor: string;
  textline1: string;
  textline2: string;
  overlap: boolean;
}

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent implements OnInit, OnChanges {

  @Input() location: string;

  private changed = new EventEmitter();

  //Bug 382496 Added startWith so that banner has initial value
  //seems to be a timing issue, as it behaves differently depending on the environment
  banner$ = combineLatest(this.ui.select.config$, this.changed).pipe(
    map(([config]) => config),
    filter(config => !!config),
    distinctUntilChanged(),
    map(config => this.bannerConfigCreate(config, this.location)),
    startWith({})
  );

  constructor(private ui: UIStoreService) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.changed.emit();
  }

  /**
   * Extract the banner properties from the config and create a banner object
   * Supports global level banner properties with page specific overrides
   * @param config
   * @param location
   */
  private bannerConfigCreate(config: Record<string, ICPOSAppConfig>, location?: string) {
    const configValue = (key: string) => config[key] ? config[key].value : null;
    const banner: Banner = {
      backgroundcolor: configValue('clover.global.banner.background.color'),
      backgroundimage: configValue('clover.global.banner.background.image'),
      textcolor: configValue('clover.global.banner.text.color'),
      textline1: configValue('clover.global.banner.text.line1'),
      textline2: configValue('clover.global.banner.text.line2'),
      overlap: configValue('clover.global.banner.overlap'),
    };

    // If a location is passed, override properties in the banner object IF PRESENT
    if (location) {
      // Rename some locations to match config values
      if (location === 'loan-purpose') location = 'purpose';
      if (location === 'review') location = 'submit';
      // Loop through the config properties
      Object.keys(config).forEach(key => {
        // Split to be able to get current location
        const locCurrent = key.split('.');
        // Location property is always in index 1, check to see if there is a match
        if (location === locCurrent[1] && locCurrent[2] === 'banner') {
          // Extract the property to override
          const propOverride = locCurrent[locCurrent.length - 2] + locCurrent[locCurrent.length - 1];
          // If present, add OR override
          banner[propOverride] = config[key].value;
        }
      });
    }

    // Banner must have at least one value in order to show
    const hasValues = Object.values(banner).reduce((acc, value) => acc || !!value, false);
    // If at least one banner property is set, return banner object, otherwise return false
    // console.log(hasValues, banner);
    return hasValues ? banner : null;
  }
}
