export const reviewRouting: CvFormBuilder.Routes = {
  // Final review
  guidRouteA: {
    pageGuid: 'guidPageA',
    routeNext: 'guidRouteB',
  },

  guidRouteB: {
    pageGuid: 'guidPageB',
    sectionComplete: true,
  },
};
