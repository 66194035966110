// Common env settings that don't change between dev and prod
export const local = {
  isLocal: false,
  settings: {
    /** Is an authentication endpoint available? If so make sure to update the endpoints in this file */
    enableAuth: true,
  },
  endpoints: {
    apiUrl: 'https://dev20loancenter.cloudvirga.com',
    // version: 'assets/mock-data/version.json',
  },
};
