import { Injectable } from '@angular/core';
import { UIStoreService } from '$ui';
import { take } from 'rxjs/operators';
import { ICPOSAppConfig } from '../models';

@Injectable({
  providedIn: 'root',
})
export class HeadContentService {

  // Store DOM element IDs
  private insertedDOMIds: string[] = [];

  constructor(
    private ui: UIStoreService,
  ) {}

  /**
   * Manage DOM elements inserted into the <head> tag
   */
  public start() {
    // When styling changes come through the UI store
    this.ui.select.config$.pipe(take(2)).subscribe(configs => {
      this.clearPreviousDOMElements();
      this.createNewDOMElements(configs);
      this.updateExistingDOMElements(configs);
    });
  }

  /**
   * Remove any previously inserted DOM elements from <head>
   */
  private clearPreviousDOMElements(): void {

    if (!this.insertedDOMIds.length) return;

    // Reference to <head> tag
    const head = document.head || document.getElementsByTagName('head')[0];

    // Remove each element
    this.insertedDOMIds.forEach(id => {
      const elem = document.getElementById(id);
      head.removeChild(elem);
    });

    // Clear array
    this.insertedDOMIds = [];

  }

  /**
   * Create each DOM element and insert into <head>
   * @param configs
   */
  private createNewDOMElements(configs: Record<string, ICPOSAppConfig>): void {

    const webfontCSSURL = configs['clover.global.font.url']
      && configs['clover.global.font.url'].value
      || null;

    const newDOMStrings: string[] = [];

    // Add web font CSS URL
    if (webfontCSSURL) {
      newDOMStrings.push(`<link href="${webfontCSSURL}" rel="stylesheet">`);
    }

    // Convert DOM element strings to DOM elements
    const elements = this.createDOMElementsFromStrings(newDOMStrings);
    // Insert DOM elements into <head>
    this.insertDOMElements(elements);

  }
  /**
   * Create each DOM element and insert into <head>
   * @param configs
   */
  private updateExistingDOMElements(configs: Record<string, ICPOSAppConfig>): void {
    // Store fav icon path
    const favIconPath = configs['clover.favicon.folder']
      && configs['clover.favicon.folder'].value
      || null;

    if (!favIconPath) return;

    const favIcon16 = document.getElementById(`favicon-icon-16`);
    const favIcon32 = document.getElementById(`favicon-icon-32`);
    const favIconApple180 = document.getElementById(`favicon-apple-180`);
    const faviconManifest = document.getElementById(`favicon-manifest`);
    const faviconSafariPinned = document.getElementById(`favicon-safari-pinned`);

    if (favIcon16) favIcon16.setAttribute(`href`, `${favIconPath}favicon-16x16.png`);
    if (favIcon32) favIcon32.setAttribute(`href`, `${favIconPath}favicon-32x32.png`);
    if (favIconApple180) favIconApple180.setAttribute(`href`, `${favIconPath}apple-touch-icon.png`);
    if (faviconManifest) faviconManifest.setAttribute(`href`, `${favIconPath}site.webmanifest`);
    if (faviconSafariPinned) faviconSafariPinned.setAttribute(`href`, `${favIconPath}safari-pinned-tab.svg`);

  }

  /**
   * Used to create DOM elements from strings
   * @param domStrings Strings to convert to DOM elements
   */
  private createDOMElementsFromStrings(domStrings: string[] = []): Element[] {
    const fragments = document.createRange().createContextualFragment(domStrings.join(''));
    // IE does not support .children
    const children = fragments.children
      ? fragments.children
      : fragments.childNodes;
    return <Element[]>Array.from(children);
  }

  /**
   * Take a DOM element and insert it into the head
   * @param domElements DOM elements to insert
   */
  private insertDOMElements(domElements: Element[]): void {
    // Reference to <head> tag
    const head = document.head || document.getElementsByTagName('head')[0];
    // Insert DOM element for each item in the array
    domElements.forEach(newElement => {
      // Add unique ID
      const randomGuid = this.createGuid();
      newElement.id = randomGuid;
      // Store unique ID for each element
      this.insertedDOMIds.push(randomGuid);
      head.appendChild(newElement);
    });
  }

  private createGuid(): string {
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      // tslint:disable-next-line:no-bitwise
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
}
