<header class="py-md-3 px-0">
  <div class="container">
    <div class="row">
      <div class="col-2 col-md-4">
        <ng-content select="[left-column]"></ng-content>
      </div>
      <div class="col-8 col-md-4">
        <div class="main-header-logo">
          <app-company-logo></app-company-logo>
        </div>
      </div>
      <div class="col-2 col-md-4">
        <ng-content select="[right-column]"></ng-content>
      </div>
    </div>
  </div>
</header>
