import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DashboardGuard implements CanActivate {
  constructor(
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    // Handle redirects while preserving query parameters
    this.router.navigate(['/'], { queryParams: route.queryParams });
    return true;
  }
}

