import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { AppSettings, AuthService, AnalyticsService } from '$shared';
import { Subscription } from 'rxjs';
import { UIStoreService } from '$ui';
import { take } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { AuthenticationStatusEnum } from 'src/app/shared/models';
import { LoanUtils } from 'src/app/shared/utils/loan-utils';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  public config$ = this.ui.select.config$;
  public formMain: FormGroup;
  public waiting: boolean;
  public errorApi: IErrorApi;
  public sessionExpired: boolean;
  public loggedout: boolean;
  public returnUrl: string;
  /** Toggle password visibility in UI */
  public showPassword = false;
  public subs: Subscription[] = [];
  public isLsidAvailable = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private ui: UIStoreService,
    private title: Title,
    private settings: AppSettings,
    private analytics: AnalyticsService
  ) { }

  public ngOnInit() {

    // Check to see if LSID has been set
    this.isLsidAvailable = this.settings.lsid != null;
    this.returnUrl = '/my-loans';

    this.subs = [
      this.route.queryParams.subscribe(params => {
        if (params.session === 'expired') {
          this.sessionExpired = true;
        }
        if (params.session === 'loggedout') {
          this.loggedout = true;
        }
      }),
    ];

    if (isPlatformBrowser) {
      window.clearTimeout(this.authService.sessionTimer); // When the page is loaded, clear any legacy timeouts
    }

    this.authService.logOutModal = null; // Get rid of logout modal if it persists

    this.formMain = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]],
      loanId: [null, []]
    });

    // Check if user is activated
    this.checkUserActivation();

    // get return url from route parameters or default to '/'
    if (this.route.snapshot.queryParams['token']) {
      this.returnUrl = '/';
    } else if (this.route.snapshot.queryParams['returnUrl']) {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/my-loans';
    }
    // Do not use return url on application section
    if (this.returnUrl.indexOf('application') !== -1) {
      this.returnUrl = '/';
    }
  }

  ngAfterViewInit(): void {
    // Add company name to the title of this page
    const newTitle = this.settings.clientName
      ? `${this.title.getTitle()} - ${this.settings.clientName}`
      : this.title.getTitle();
    this.title.setTitle(newTitle);
  }

  isRegisterationAvailable(): boolean {
    return this.settings && this.settings.clientId && this.settings.clientId.toLowerCase() !== 'arv';
  }

  /**
   * Check for a token in the URL and redirect to the activation screen if the user is already activated
   */
  checkUserActivation(): void {
    this.settings.secureLinkId = null;
    const activationToken = this.route.snapshot.queryParams['token'];
    if (!activationToken) return;
    this.authService.checkUserActivationByToken(activationToken)
      .pipe(take(1))
      .subscribe(activationResponse => {
        if (!activationResponse) return;
        if (activationResponse && activationResponse.isValidUserAccount === true)
        {
          LoanUtils.setSecureLinkId(this.route.snapshot.queryParams, this.settings);
        }
        // If account is not active
        if (activationResponse.isUserAccountActive === false) {
          this.router.navigate(['/activation'], { queryParamsHandling: 'preserve' });
        } else if (activationResponse.userName) {
          // Use the username from the token in the form
          this.formMain.patchValue({
            userName: activationResponse.userName,
            loanId: activationResponse.loanId
          });
        }
      });
  }

  /**
   * Submit the form
   */
  public onLogin(form: FormGroup): void {

    // Do nothing if form is invalid
    if (form.invalid) return;

    this.waiting = true;
    this.resetErrors();

    // Authenticate
    this.authService.logIn(form.value).subscribe(
      (apiResponse) => {
        this.waiting = false;
        const mfaToken = apiResponse.mfaToken;
        const mfaRequired = !!mfaToken;
        if (mfaRequired) {
          if (!(this.route.snapshot.queryParams['token'])) {
            this.returnUrl = '/my-loans';
          }
          this.router.navigate(['/mfa'], {
            queryParams: {
              userName: form.value.userName,
              mfaToken: mfaToken,
            },
            queryParamsHandling: 'merge'
          });
        } else if (apiResponse.succeeded) {
          // In order to view the series of mixpanel events triggered in Dev Console.
          this.analytics.mixpanelSetDebugTrue(true);
          this.analytics.mixpanelIdentify(this.settings.userId);
          this.analytics.mixpanelPeople({ 'User': 'Borrower' });
          this.analytics.trackEvent('Please Log In BC');
          this.analytics.trackEvent('Login Success BC');
          // Check if the username has changed, if so reset the return url so the new user doesn't go to the old users last page
          // Note that the userName prop in the UI store is wiped out on log out so it's not usable
          if (!(this.route.snapshot.queryParams['token'])) {
            this.returnUrl = '/my-loans';
          }

          // Store username in localstorage
          window.localStorage.setItem('userName', form.value.userName);
          // Navigate to the proper route
          this.router.navigate([this.returnUrl], { queryParamsHandling: 'merge' });
        } else if (apiResponse.authenticationStatus === AuthenticationStatusEnum.AccountLocked) {
          // Account lock was detected
          this.errorApi = { errorMsg: `Your account has been locked as a result of too many unsuccessful login attempts. Please contact your loan officer to unlock your account.` };
        } else {
          // Generic error message
          this.errorApi = { errorMsg: `Incorrect email or password. After 5 failed attempts, the account will be locked.` };
        }
      },
      error => {
        error.errorMsg = 'Error logging in.';
        if ((error.statusText === 'Unauthorized')) {
          error.errorMsg = 'Invalid username or password, please try again.';
        }

        this.errorApi = error;
        this.waiting = false;
      },
    );
  } // end onSubmit

  public resetErrors() {
    this.loggedout = false;
    this.errorApi = null;
    this.sessionExpired = false;
  }

  ngOnDestroy() {
    if (this.subs.length) {
      this.subs.forEach(sub => sub.unsubscribe());
    }
  }
}
