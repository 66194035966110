<div [ngClass]="{'mat-form-field-invalid': showInvalidStreetErrorMessage}">
  <mat-form-field>
    <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
    <ng-container *ngIf="autoCompleteControl.touched && autoCompleteControl.errors">
      <i
        *ngIf="showSuccess && autoCompleteControl.valid && autoCompleteControl.value !== null && autoCompleteControl.value !== ''"
        class="fa fa-thumbs-up green field-icon anim-bounce-in"
        aria-hidden="true"
      ></i>
      <i
        class="fa fa-warning red field-icon anim-bounce-in fa-lg"
        aria-hidden="true"
        *ngIf="autoCompleteControl.invalid"
      ></i>
    </ng-container>
    <input
      type="text"
      #address
      [placeholder]="placeholder"
      matInput
      [formControl]="autoCompleteControl"
      autocomplete="off"
      [required]="isRequired"
      (blur)="validateTBDStreetAddress()"
    />
    <!-- Error messages -->
    <div *ngIf="showInvalidStreetErrorMessage && autoCompleteControl.valid" 
      class="anim-fade-in text-center mat-form-field-subscript-wrapper mat-error"
      style="padding-top: 20px;">
      <div class="small" style="font-size:11.6px;">
        Please enter a valid street address.
      </div>
    </div>

    <mat-error
      *ngIf="autoCompleteControl.invalid && autoCompleteControl.touched && autoCompleteControl.errors"
      class="anim-fade-in text-center"
      style="padding-top: 6px;"
    >
      <div *ngIf="autoCompleteControl?.errors?.required" class="small" style="font-size:11.6px;">
        {{ placeholder || 'This field' }} is <strong>required</strong>
      </div>
      <div *ngIf="autoCompleteControl?.errors?.minlength" class="small">
        Minimum length is <strong>{{ autoCompleteControl?.errors?.minlength?.requiredLength }} characters</strong>
      </div>
    </mat-error>
  </mat-form-field>
</div>
