<div *ngIf="formControlRef" class="w-100" automationid="Section_Form">
  <div class="d-flex align-items-stretch" [ngClass]="class + ' fieldtype-' + type">
    <div class="flex-grow-1" [ngClass]="parentClass">
      <!-- Text field or select -->
      <ng-container
        *ngIf="
          type === 'text' ||
          type === 'email' ||
          type === 'number' ||
          type === 'numberAsString' ||
          type === 'currency' ||
          type === 'phoneNumber' ||
          type === 'select' ||
          type === 'textarea' ||
          type === 'date' ||
          type === 'dateText' ||
          type === 'ssn' ||
          type === 'password' ||
          isInputmask
        "
      >
        <ng-container *ngTemplateOutlet="formField"></ng-container>
      </ng-container>

      <!-- Checkboxes-->
      <ng-container *ngIf="type === 'checkbox'">
        <ng-container *ngTemplateOutlet="checkbox"></ng-container>
      </ng-container>

      <!-- autoComplete-->
      <ng-container *ngIf="type === 'autoComplete'">
        <ng-container *ngTemplateOutlet="autoComplete"></ng-container>
      </ng-container>

      <!-- autoCompleteAddress
      <ng-container *ngIf="type === 'autoCompleteAddress'">
        <ng-container *ngTemplateOutlet="autoCompleteAddress"></ng-container>
      </ng-container>
-->
      <!-- Button Group -->
      <ng-container *ngIf="type === 'buttonGroup'">
        <ng-container *ngTemplateOutlet="buttonGroup"></ng-container>
      </ng-container>

      <!-- Toggles -->
      <ng-container *ngIf="type === 'toggle'">
        <ng-container *ngTemplateOutlet="toggle"></ng-container>
      </ng-container>

      <!-- Icon Group -->
      <ng-container *ngIf="type === 'iconGroup'">
        <ng-container *ngTemplateOutlet="iconGroup"></ng-container>
      </ng-container>
    </div>
    <!-- Tooltip -->
    <a
      class="ms-auto me-0 align-self-center px-2"
      *ngIf="tooltip"
      #thisTooltip="matTooltip"
      [matTooltip]="tooltip"
      (click)="thisTooltip.toggle()"
      (mouseenter)="thisTooltip.show()"
      (mouseleave)="thisTooltip.hide()"
    >
      <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>
    </a>
  </div>

  <!-- Error messages -->
  <mat-error
    *ngIf="formControlRef.invalid && formControlRef.touched && formControlRef.errors && showError"
    class="anim-fade-in text-center"
  >
    <div *ngIf="errorCustom && formControlRef?.errors?.required" [innerHtml]="errorCustom"></div>
    <div *ngIf="!errorCustom && formControlRef?.errors?.required" class="small">
      {{ placeholder || 'This field' }} is <strong>required</strong>
    </div>
    <div *ngIf="formControlRef?.errors?.minlength" class="small">
      Minimum length is <strong>{{ formControlRef?.errors?.minlength?.requiredLength }} characters</strong>
    </div>
    <div *ngIf="formControlRef?.errors?.max" class="small">
      Maximum length is <strong>{{ formControlRef?.errors?.max?.max }} characters</strong>
    </div>
    <div *ngIf="formControlRef?.errors?.email" class="small">Please enter a valid <strong>email address</strong></div>
    <div *ngIf="formControlRef?.errors?.pattern" class="small">Please enter a valid <strong>value</strong></div>
    <div *ngIf="formControlRef?.errors?.requiredCustom" class="small">
      {{ placeholder || 'This field' }} is <strong>required</strong>
    </div>
    <div *ngIf="customMessageMin && (formControlRef?.errors?.min || formControlRef?.errors?.min === 0)" [innerHtml]="customMessageMin" class="small"></div>
    <div *ngIf="!customMessageMin && (formControlRef?.errors?.min || formControlRef?.errors?.min === 0)" class="small">
      Minimum value is <strong>{{ formControlRef?.errors?.min }}</strong>
    </div>
  </mat-error>
</div>

<!--
  AutoComplete
   <ng-container *ngIf="optionsLabel && optionsValue; else optionsSelectStringArray">
   <mat-option *ngFor="let option of options" [value]="option[optionsValue]">
-->
<ng-template #autoComplete>
  <app-autocomplete
    [terms]="options"
    [placeholder]="placeholder"
    [hint]="hint"
    termLabel="label"
    termValue="value"
    [formControlRef]="formControlRef"
  ></app-autocomplete>
</ng-template>

<!--
  Checkbox
-->
<ng-template #checkbox>
  <mat-checkbox [formControl]="formControlRef" [attr.automationid]="'FormFieldChk_' + placeholder">
    <span [innerHtml]="placeholder"></span>
    <!-- <span *ngIf="required" aria-hidden="true" class="red">*</span> -->
  </mat-checkbox>
</ng-template>

<!--
  Icon Group
-->
<ng-template #iconGroup>
  <div class="icon-group text-center" automationid="Section_IconGroup" role="group" [attr.aria-label]="placeholder">
    <ng-container *ngFor="let option of options; let i = index">
      <!-- If options is an array of objects-->
      <ng-container *ngIf="optionsLabel && optionsValue; else optionsIconGroupArray">
        <button
          type="button"
          [disabled]="disabled || option.disabled"
          class="btn icon-group-btn btn-outline-secondary mx-3 d-inline-block icon-group-custom-class"
          (click)="formControlRef.patchValue((isDeselectable && option[optionsValue] === formControlRef.value) ? null : option[optionsValue]);"
          [ngClass]="{
            'icon-group-btn-invalid': formControlRef.invalid && formControlRef.touched,
            'icon-group-btn-active': option[optionsValue] === formControlRef.value,
            'icon-group-btn-lg': size === 'large'
          }"
          [attr.automationid]="'BtnFormField_' + option.text"
          [attr.aria-pressed]="option[optionsValue] === formControlRef.value ? true : false"
        >
          <div
            class="icon-group-img mx-auto"
            [ngClass]="'icon-group-img-' + (option[optionsLabel] | slug) + '-' + (option[optionsValue] | slug)"
          >
            <i class="cvi cvi-{{ option[optionsLabel] | slug }}" aria-hidden="true"></i>
          </div>
          <p class="p-0 m-0" [innerHTML]="option[optionsLabel]" [class.font-weight-bold]="option['description']"></p>
          <p class="p-0 mx-0 mt-2" *ngIf="option['description']">{{option['description']}}</p>
        </button>
      </ng-container>
      <!-- If options is a string array-->
      <ng-template #optionsIconGroupArray>
        <button class="btn btn-outline-secondary mx-2" [attr.aria-controls]="placeholder | slug" [attr.automationid]="'BtnFormField2_' + option.text">
          {{ option }}
        </button>
      </ng-template>
    </ng-container>
  </div>
</ng-template>

<!--
  Button Group
-->
<ng-template #buttonGroup>
  <div
    class="mat-button-toggle-group-wrapper"
    [ngClass]="{
      'mat-button-toggle-group-invalid': formControlRef.invalid && formControlRef.touched && formControlRef.errors
    }"
    automationid="Section_ButtonGroup"
    role="group"
    [attr.aria-label]="placeholder"
  >
    <!-- <span *ngIf="required && !placeholder" aria-hidden="true" class="required red">*</span> -->
    <mat-button-toggle-group [vertical]="true" [formControl]="formControlRef" class="w-100 text-start mb-2">
      <!-- If options is an array of objects-->
      <ng-container *ngIf="optionsLabel && optionsValue; else optionsBtnStringArray">
        <mat-button-toggle
          [value]="option[optionsValue]"
          *ngFor="let option of options"
          [attr.aria-pressed]="option[optionsValue] === formControlRef.value ? true : false"
        >
          <mat-radio-button value="1" [disabled]="disabled" [tabIndex]="tabIndex ? 0 : -1">
            <!-- Safe HTML requires property binding with innerhtml -->
            <span [innerHtml]="option[optionsLabel]"></span>
          </mat-radio-button>
        </mat-button-toggle>
      </ng-container>
      <!-- If options is a string array-->
      <ng-template #optionsBtnStringArray>
        <mat-button-toggle [value]="option" *ngFor="let option of options">
          <mat-radio-button value="option" [disabled]="disabled">{{ option }}</mat-radio-button>
        </mat-button-toggle>
      </ng-template>
    </mat-button-toggle-group>
  </div>
</ng-template>

<!--
  Button toggle
-->
<ng-template #toggle>
  <div class="d-flex justify-content-between align-items-start yes-no" automationid="Section_ButtonToggle">
    <label class="button-toggle me-2">
      {{ placeholder }}
    </label>
    <div style="min-width:160px;">
      <button
        *ngFor="let option of options; let i = index"
        type="button"
        [disabled]="disabled"
        class="btn btn-outline-secondary btn-yes-no"
        (click)="formControlRef.patchValue(option.value); formControlRef.markAsTouched()"
        [ngClass]="{ selected: formControlRef.value === option.value, 'ms-2': i !== 0 }"
        [attr.automationid]="'BtnFormFieldToggle_' + option.label"
      >
        <i class="fa fa-check" aria-hidden="true"></i>
        <span>{{ option.label }}</span>
      </button>
    </div>
  </div>
</ng-template>

<!--
  Text input, select dropdown
-->
<ng-template #formField>
  <mat-form-field [appearance]="appearance" [ngClass]="{'cv-touched': formControlRef.touched }">
    <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
    <ng-container *ngIf="formControlRef.touched && formControlRef.errors">
      <i
        *ngIf="showSuccess && formControlRef.valid && formControlRef.value !== null && formControlRef.value !== ''"
        class="fa fa-thumbs-up green field-icon anim-bounce-in"
        aria-hidden="true"
      ></i>
      <i
        class="fa fa-warning red field-icon anim-bounce-in fa-lg"
        aria-hidden="true"
        *ngIf="formControlRef.invalid"
      ></i>
    </ng-container>

    <!-- Text Box -->
    <ng-container *ngIf="type === 'text'">
      <input
        matInput
        [id]="name"
        [name]="name"
        [formControl]="formControlRef"
        [required]="required"
        type="text"
        [minlength]="minlength"
        [maxlength]="maxlength"
        [attr.automationid]="'FormFieldText_' + name"
      />
    </ng-container>

    <!-- Masked Text Box -->
    <ng-container *ngIf="isInputmask">
      <input
        matInput
        [id]="name"
        [name]="name"
        [formControl]="formControlRef"
        [required]="required"
        type="text"
        [minlength]="minlength"
        [maxlength]="maxlength"
        [attr.automationid]="'FormFieldText_' + name"
        [appInputmask]="inputmask"
      />
    </ng-container>

    <!-- Number box that returns number as string -->
    <ng-container *ngIf="type === 'numberAsString'">
      <input
        matInput
        [id]="name"
        [name]="name"
        [formControl]="formControlRef"
        [required]="required"
        type="number"
        [max]="max"
        [min]="min"
        [attr.automationid]="'FormFieldTextNumber_' + name"
      />
    </ng-container>

    <!-- Number box that returns number as number -->
    <ng-container *ngIf="type === 'number'">
      <input
        matInput
        [id]="name"
        [name]="name"
        [required]="required"
        type="number"
        [formControl]="controlCustom"
        [minlength]="minlength"
        [maxlength]="maxlength"
        [max]="max"
        [min]="min"
        (input)="numberChange($event)"
        [attr.automationid]="'FormFieldNumberNumber_' + name"
      />
    </ng-container>

    <ng-container *ngIf="type === 'password'">
      <!--
          (blur)="secure.type === 'password'"
        -->
      <input
        matInput
        [id]="name"
        [name]="name"
        autocomplete="off"
        [formControl]="formControlRef"
        [required]="required"
        type="password"
        [minlength]="minlength"
        [maxlength]="maxlength"
        [attr.automationid]="'FormFieldPassword_' + name"
      />
    </ng-container>

    <!-- SSN -->
    <ng-container *ngIf="type === 'ssn'">
      <input
        matInput
        [id]="name"
        [name]="name"
        #fieldRef
        autocomplete="new-password"
        [formControl]="controlCustom"
        [required]="required"
        minlength="11"
        maxlength="11"
        type="password"
        (blur)="inputChangeType($event, 'password')"
        (focus)="inputChangeType($event, 'text')"
        [attr.automationid]="'FormFieldSSN_' + name"
      />
    </ng-container>

    <!-- Datepicker -->
    <ng-container *ngIf="type === 'date'">
      <input
        matInput
        #fieldRef
        [formControl]="formControlRef"
        type="date"
        [attr.aria-label]="
          'Please enter ' +
          placeholder +
          ' in an 8 digit format for month, day, and year. For example if the date is January 15th, 1995, enter: 01, forward-slash, 15, forward-slash, 1995.'
        "
        [attr.automationid]="'FormFieldDatePicker_' + placeholder"
      />
      <!--
         [matDatepicker]="picker"
           <mat-datepicker #picker startView="multi-year" [startAt]="dateStart"></mat-datepicker>
      <mat-datepicker-toggle
        matSuffix
        [for]="picker"
        *ngIf="!suffix"
        style="position: absolute;right: 0;bottom: 0;"
      ></mat-datepicker-toggle>
      -->
    </ng-container>

    <!-- Text field datepicker -->
    <ng-container *ngIf="type === 'dateText'">
      <input
        matInput
        [id]="name"
        [name]="name"
        #fieldRef
        [formControl]="controlCustom"
        type="text"
        (blur)="formControlRef.markAsTouched()"
        [attr.aria-label]="
          'Please enter ' +
          placeholder +
          ' in an 8 digit format for month, day, and year. For example if the date is January 15th, 1995, enter: 01, forward-slash, 15, forward-slash, 1995.'
        "
        [attr.automationid]="'FormFieldTextFieldDepricate_' + name"
      />
    </ng-container>

    <!-- Input Box -->
    <ng-container *ngIf="type === 'email'">
      <input [id]="name" [name]="name" matInput [formControl]="formControlRef" [required]="required" [type]="type" [attr.automationid]="'FormFieldInput_' + name"/>
    </ng-container>
    <!-- Has to go outside or won't render correctly-->
    <span matSuffix *ngIf="type === 'email' && !suffix"
      ><i class="fa fa-envelope-o fa-lg ms-2" aria-hidden="true"></i
    ></span>

    <!-- Currency -->
    <ng-container *ngIf="type === 'currency'">
      <input
        matInput
        [id]="name"
        [name]="name"
        [required]="required"
        type="text"
        [formControl]="controlCustom"
        (blur)="formControlRef.markAsTouched()"
        [minlength]="minlength"
        [maxlength]="maxlength"
        [attr.automationid]="'FormFieldCurrency_' + name"
      />
    </ng-container>

    <!-- Phone Number -->
    <ng-container *ngIf="type === 'phoneNumber'">
      <input
        matInput
        #fieldRef
        [id]="name"
        [name]="name"
        [required]="required"
        type="text"
        [formControl]="controlCustom"
        (blur)="formControlRef.markAsTouched()"
        maxlength="14"
        [attr.automationid]="'FormFieldPhone_' + name"
      />
      <!-- (keyup)="phoneNumberFormat(controlCustom.value, $event)" -->
    </ng-container>
    <!-- Has to go outside or won't render correctly-->
    <span matSuffix *ngIf="type === 'phoneNumber'"><i class="fa fa-phone fa-lg ms-2" aria-hidden="true"></i></span>

    <!-- Text area -->
    <ng-container *ngIf="type === 'textarea'">
      <textarea
        matInput
        [id]="name"
        [name]="name"
        [formControl]="formControlRef"
        [required]="required"
        [rows]="rows"
        [attr.automationid]="'TxtAreaFormField_' + name"
      ></textarea>
    </ng-container>

    <!-- Select box -->
    <ng-container *ngIf="type === 'select'">
      <!-- Non multiple selection -->
      <mat-select
      *ngIf="!multiple"
        [placeholder]="placeholder"
        [id]="name"
        [formControl]="formControlRef"
        [required]="required"
        aria-label="{{ labelCustom }}"
        [attr.automationid]="'SlcFormField_' + name"
      >
        <ng-container *ngIf="optionsLabel && optionsValue; else optionsSelectStringArray">
          <mat-option *ngFor="let option of options" [value]="option[optionsValue]">
            {{ option[optionsLabel] }}
          </mat-option>
        </ng-container>
        <ng-template #optionsSelectStringArray>
          <mat-option *ngFor="let option of options" [value]="option"> {{ option }} </mat-option>
        </ng-template>
      </mat-select>
      <!-- Multiple selection -->
      <mat-select
      *ngIf="multiple"
        [placeholder]="placeholder"
        [id]="name"
        [formControl]="formControlRef"
        [required]="required"
        aria-label="{{ labelCustom }}"
        multiple
        [attr.automationid]="'SlcFormField_' + name"
      >
        <ng-container *ngIf="optionsLabel && optionsValue; else optionsSelectStringArray">
          <mat-option *ngFor="let option of options" [value]="option[optionsValue]">
            {{ option[optionsLabel] }}
          </mat-option>
        </ng-container>
        <ng-template #optionsSelectStringArray>
          <mat-option *ngFor="let option of options" [value]="option"> {{ option }} </mat-option>
        </ng-template>
      </mat-select>
    </ng-container>

    <span class="me-2" matPrefix *ngIf="prefix" [innerHtml]="prefix"></span>
    <span class="ms-1" matSuffix *ngIf="suffix" [innerHtml]="suffix"></span>

    <mat-hint *ngIf="hint || type === 'date' || type === 'dateText'">
      {{ hint }}
      <span *ngIf="type === 'date' || type === 'dateText'" aria-hidden="true">Example: 01/31/2019</span>
    </mat-hint>
  </mat-form-field>
</ng-template>
