import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AppSettings } from '../app.settings';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from '$api';
import { AuthService } from '../services/auth.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  /**
   * Append bearer token to auth settings
   * @param settings
   */
  constructor(private settings: AppSettings, private router: Router, private api: ApiService, private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If token present, add bearer token
    var headers = req.headers;
    if (this.settings.token) {
      headers = headers.set('Authorization', this.settings.token);
    }
    headers = headers.set('x-cv-client-id', 'clover'); // needed for backend to trigger front-end logic (sigh)
    // Create headers element
    // Clone request, add headers
    const cloneReq = next.handle(req.clone({ headers: headers }));
    // Return request, handle errors globally here
    return cloneReq.pipe(catchError(error => {
      // If forbidden error, end session
      if (error.status === 401 || error.status === 403) {
        this.sessionEnd();
      }
      // Catch and rethrow error
      return throwError(error);
    }) as any);
  }

  /**
   * End the user's session based on auth failure
   */
  public sessionEnd() {
    this.api.resetStore(); // Reset api store and api cache
    this.auth.logOut();
    this.router.navigate(['/login'], { queryParams: { session: 'expired' } }); // Bounce to login page
  }
}
