import { AbstractControl, FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { map, switchMap } from 'rxjs/operators';
import { ApiService } from '$api';
import { timer as TimerObservable } from 'rxjs';

/**
 * Form Group Validators
 */
export class AuthorizationFormValidators {
  static samePasswords(group: FormGroup | AbstractControl) {
    const password = group.get('password').value;
    const passwordConfirm = group.get('passwordConfirm').value;

    // If both are blank or both are equal, do not show an error
    return (!password && !passwordConfirm) || password === passwordConfirm ? null : { notSamePassword: true };
  }
  static sameEmails(group: AbstractControl) {
    const email = `${group.get('email')?.value}`.toLowerCase();
    const emailConfirm = `${group.get('emailConfirm')?.value}`.toLowerCase();

    return email === emailConfirm ? null : { notSameEmail: true };
  }
  static oneLowercase(control: FormControl) {
    return !control.value || AuthorizationFormUtils.hasLowercase(control.value) ? null : { oneLowercase: true };
  }
  static oneUppercase(control: FormControl) {
    return !control.value || AuthorizationFormUtils.hasUppercase(control.value) ? null : { oneUppercase: true };
  }
  static oneDigit(control: FormControl) {
    return !control.value || AuthorizationFormUtils.hasDigit(control.value) ? null : { oneDigit: true };
  }
  static specialCharacters(control: FormControl) {
    return !control.value || AuthorizationFormUtils.hasSpecialCharacter(control.value) ? null : { specialCharacters: true };
  }
  static eightCharacters(control: FormControl) {
    return !control.value || AuthorizationFormUtils.hasEightCharacters(control.value) ? null : { specialCharacters: true };
  }
  static doesNotEqual(comparisonString: string): ValidatorFn {
    return (control: AbstractControl) => {
      return control.value === comparisonString ? { exactMatchNotAllowed: true } : null;
    };
  }
  static validEmail(control: FormControl) {
    if (!control.value) {
      return null;
    }
    return AuthorizationFormUtils.isValidEmail(control.value) ? null : { validEmail: true };
  }
  static emailNotTaken(apiService: ApiService) {
    return (control: AbstractControl) => {
      // Using this pattern will act as a debounce to avoid unnecessary API calls
      return TimerObservable(750).pipe(switchMap(() => {
        // Check if the email is already taken first before we attempt to create the account
        return apiService.userAccount.checkActivationByUserName(control.value).pipe(
          map(apiResponse => {
            if (!(apiResponse && apiResponse.response)) return null;
            if (apiResponse.response.isValidUserAccount) {
              if (apiResponse.response.isUserAccountActive) {
                return {emailTaken: true};
              }
              return {emailTaken: true, accountInactive: true};
            }
            return null;
          })
        );
      }));
    };
  }
}

/**
 * Reusable tests can be used in validators or in other Angular components
 */
export class AuthorizationFormUtils {
  static hasLowercase(value: string) {
    return /[a-z]/.test(value);
  }
  static hasUppercase(value: string) {
    return /[A-Z]/.test(value);
  }
  static hasDigit(value: string) {
    return /[0-9]/.test(value);
  }
  static hasSpecialCharacter(value: string) {
    return /[!@#\$%\^\&*\)\(+=._-]/.test(value);
  }
  static hasEightCharacters(value: string) {
    return value && value.length && value.length >= 8;
  }
  static isValidEmail(value: string) {
    return /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z]{1,}|[a-zA-Z0-9-]{2,})+\.)+([a-zA-Z]{2,4})+$/.test(value);
  }
}




/**
 * Error state matchers for Material Input fields
 */

export class RepeatPasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (
      control
      && (control.invalid || control.parent.get('password').value !== control.value)
      && (control.touched || (form && form.submitted))
    );
  }
}

export class RepeatEmailErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isEmailDifferent = AuthorizationFormValidators.sameEmails(control.parent) !== null;
    return (
      control
      && (control.invalid || isEmailDifferent)
      && (control.touched || (form && form.submitted))
    );
  }
}
