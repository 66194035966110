import { RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';

export const creditRouting: CvFormBuilder.Routes = {
  guidRouteA: {
    pageGuid: 'guidPageA',
    ruleGroups: [
      {
        routeNext: 'guidRouteB',
        rules: [
          {
            field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
            operator: RuleExprOp.GT,
            value: null,
          },
        ],
      },
      {
        routeNext: 'guidRouteB',
        rules: [
          {
            field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
            operator: RuleExprOp.EQ,
            value: false,
          },
        ],
      },
      {
        routeNext: 'guidRouteBB',
        rules: [
          {
            field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
    ],
  },

  guidRouteB: {
    pageGuid: 'guidPageB', // Single borrower credit screen
    routeNext: 'guidRouteE',
  },

  guidRouteBB: {
    pageGuid: 'guidPageBB', // 2 borrower credit screen
    routeNext: 'guidRouteE',
  },

  guidRouteE: {
    pageGuid: 'guidPageE', // Show credit scores
    sectionComplete: true,
  },
};
