import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { ApiService } from '$api';
import { EconsentResult, EconsentService } from '../../shared/services/econsent.service';
import { UIStoreService } from '$ui';
import { AppSettings } from '$shared';
import { TermsAndPrivacyService } from '../../shared/services/terms-and-privacy.service';
import { combineLatest, Unsubscribable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TcpaVerbiageService } from 'src/app/shared/services/tcpa-verbiage.service';
import { IBorrowerViewModel, ConsentStatusEnum, BorrowerContextTypeEnum } from 'src/app/shared/models';
import { FormControl } from '@angular/forms';


const cloneDeep = require('lodash/cloneDeep');

@UntilDestroy()
@Component({
  selector: 'app-econsent-form',
  templateUrl: './econsent-form.component.html',
  styleUrls: ['./econsent-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EconsentFormComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  /** Borrower on the loan */
  @Input() borrower: IBorrowerViewModel;
  /** Coborrower on the loan */
  @Input() coBorrower: IBorrowerViewModel;
  /** Boolean set by the modal to force single user mode */
  @Input() isForcedSingleBorrowerMode: boolean;
  /** Show saving indicator */
  @Input() isSaving = false;
  /** Used to display errors in the UI */
  @Input() error: string;
  /** Output the result of the user actions */
  @Output() eConsentResult: EventEmitter<EconsentResult> = new EventEmitter();
  @ViewChild('tcpa') tcpa: ElementRef<HTMLElement>;
  /** UI config from API */
  config$ = this.ui.select.config$;
  /** Currently active loan */
  loan$ = this.api.getApiStoreData(this.api.select.megaLoan$);
  /** List of borrower consent statuses before changes are made */
  originalEconsentStatuses: ConsentStatusEnum[] = [];
  /** Status that is selected in the UI */
  selectedConsentStatus: ConsentStatusEnum;
  /** List of all consenting borrowers */
  borrowersHasConsented: IBorrowerViewModel[] = [];
  /** List of all non-consenting borrowers */
  borrowersHasNotConsented: IBorrowerViewModel[] = [];
  /** List of all non-consenting borrowers */
  borrowersToPick: IBorrowerViewModel[] = [];
  /** Used in UI to compare */
  consentStatusEnum = ConsentStatusEnum;
  /** Toggle full / condensed view of the consent text */
  showAllText = false;
  /** Show the option for this borrower in the array */
  showBorrowerByIndex: number;
  /** Used to set a flag to Decline eConsent for all borrowers */
  declineEconsent = false;
  /** Used to show WordPress variables in template */
  showCommunicationConsentText = false;
  /** Used to show variable in template */
  companyName: string;
  /** eConsent text shown in the UI */
  eConsentText: string = null;
  tcpaDisclaimerMarkup: string = null;
  /** eConsent joint borrowers config */
  eConsentJointBorrowersConfig: boolean = null;
  private subscriptions: Unsubscribable[] = [];

  radioControl = new FormControl();

  constructor(
    private api: ApiService,
    private econsentService: EconsentService,
    private ref: ChangeDetectorRef,
    private ui: UIStoreService,
    private settings: AppSettings,
    private renderer: Renderer2,
    private tcpaVerbiageService: TcpaVerbiageService,
    private termsAndPrivacyService: TermsAndPrivacyService,
  ) {}

  ngOnInit() {
    combineLatest(this.loan$, this.config$)
      .pipe(untilDestroyed(this))
      .subscribe(([loan, config]) => {
        this.showCommunicationConsentText = config
          && config['config.area.tcpa.enabled']
          && config['config.area.tcpa.enabled'].value === true
          && loan
          && !loan.isLegalDisclaimerConfirmed;
        this.eConsentText = config
          && config['sys.conf.econsent']
          && config['sys.conf.econsent'].value
          || null;
        this.tcpaDisclaimerMarkup = config
          && config['sys.conf.tcpa']
          && config['sys.conf.tcpa'].value
          || null;
        this.eConsentJointBorrowersConfig = this.settings.config
          && config['cPOS.eConsent.JointApplicants.AskAllBorrowerseConsent'].value === true
          || false;
        this.companyName = this.settings.clientName;
      });
  }

  ngAfterViewInit() {
    if (this.showCommunicationConsentText) {
      const {nativeElement: tcpa} = this.tcpa;
      this.tcpaVerbiageService.applyVerbiage(this.renderer, tcpa, this.subscriptions, {
        onTermsClick: () => this.onTermsClick(),
        onPrivacyClick: () => this.onPrivacyClick(),
        onThirdPartyClick: () => this.onThirdPartyClick(),
        tcpaDisclaimerMarkup: this.tcpaDisclaimerMarkup,
        companyName: this.companyName
      });
    }

    // Clear errors
    this.setError(null);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && (changes.borrower || changes.coBorrower)) {
      this.separateBorrowers(this.borrower, this.coBorrower);
    }
  }

  // Needed for .pipe(untilDestroyed(this))
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  separateBorrowers(borrower: IBorrowerViewModel, coBorrower: IBorrowerViewModel): void {
    // New, empty array
    this.borrowersHasConsented = [];
    this.borrowersHasNotConsented = [];
    this.borrowersToPick = [];

    // Check if current user is borrower or coborrower...
    let currentLoggedInUser = coBorrower && coBorrower.userAccount.userAccountId == +this.settings.userId
      ? BorrowerContextTypeEnum.CoBorrower
      : BorrowerContextTypeEnum.Borrower;
    // Check if they have separate accounts. If there is no coBorrower, this flag will be false...
    const separateAccounts = borrower && coBorrower && borrower.email != coBorrower.email;
    // Do not add borrower to the arrays if current user is coBorrower and they have separate accounts
    // and joint config is false and if borrower has not accept/decline eConsent yet...
    const ignoreBorrower = currentLoggedInUser == BorrowerContextTypeEnum.CoBorrower && separateAccounts
      && this.eConsentJointBorrowersConfig !== true && !this.econsentService.hasBorrowerEconsented(borrower);
    // Add borrower to appropriate array
    if (borrower && !ignoreBorrower) {
      this.econsentService.hasBorrowerEconsented(borrower)
        ? this.borrowersHasConsented.push(cloneDeep(borrower))
        : this.borrowersHasNotConsented.push(cloneDeep(borrower));
    }
    if (coBorrower) {
      // Add coBorrower to appropriate array
      this.econsentService.hasBorrowerEconsented(coBorrower)
        ? this.borrowersHasConsented.push(cloneDeep(coBorrower))
        : this.borrowersHasNotConsented.push(cloneDeep(coBorrower));
    }


    // if both users have consented, and separate emails only pick the logged in one
    if (borrower && coBorrower && this.borrowersHasNotConsented.length === 0 && borrower.userAccount.username != coBorrower.userAccount.username) {
      currentLoggedInUser == (BorrowerContextTypeEnum.Borrower) ? this.borrowersToPick.push(cloneDeep(borrower)) : this.borrowersToPick.push(cloneDeep(coBorrower));
    }
    else {
      this.borrowersToPick = this.borrowersHasConsented;
    }
    // Automatically "select" if there is only one non-consenting borrower
    // Otherwise no borrower will show in the UI and there will be no way
    // to select one
    this.showBorrowerByIndex = this.borrowersHasNotConsented.length >= 1 ? 0 : null;

    //Auto select first borrower if eConsent config enabled for joint borrower
    if (this.eConsentJointBorrowersConfig === true && this.borrowersHasNotConsented.length) {
      this.showBorrowerByIndex = 0;
      if (this.econsentService.hasBorrowerEconsented(borrower)) {
        this.showBorrowerByIndex = 1;
      }
    }
    // Get original statuses for all non-consenting borrowers
    // This will be used later to see if the statuses have changed
    this.originalEconsentStatuses = this.mapBorrowerStatuses(this.borrowersHasNotConsented);
    this.ref.markForCheck();
  }

  /** Used to store original status values */
  mapBorrowerStatuses(borrowers: IBorrowerViewModel[]): ConsentStatusEnum[] {
    return borrowers.map(borrower => {
      return borrower.eConsent && borrower.eConsent.consentStatus || null;
    });
  }

  /** Show more / hide text */
  onReadMoreClick(): boolean {
    this.showAllText = !this.showAllText;
    this.ref.markForCheck();
    return false;
  }

  /** Toggle saving flag */
  setIsSaving(isSaving = true): void {
    this.isSaving = isSaving;
    this.ref.markForCheck();
  }


  /**
   * Handle terms of use link click
   */
  onTermsClick(): void {
    return this.termsAndPrivacyService.viewTermsOfUse();
  }

  /**
   * Handle privacy policy link click
   */
  onPrivacyClick(): void {
    return this.termsAndPrivacyService.viewPrivacyPolicy();
  }

  /**
   * Handle third party supplier link click
   */
  onThirdPartyClick(): void {
    return this.termsAndPrivacyService.viewThirdPartySuppliers();
  }

  /**
   * Show error message in UI
   * @param error Message to display in UI
   */
  setError(error: string = `An error occurred. Please try again.`): void {
    this.error = error;
    this.ref.markForCheck();
  }

  /**
   * Handle when save button is clicked
   * @param borrowers
   * @param selectedBorrowerIndex
   * @param originalStatuses
   */
  onSubmit(): void {
    const radioControlValue = this.radioControl.value;

    // skip radio buttons validation is all required borrowers already e-consented
    if (this.borrowersHasConsented.length === 0
      || (this.eConsentJointBorrowersConfig === true && this.borrowersHasNotConsented.length > 0)) {
      if (radioControlValue == null || radioControlValue == undefined) {
        this.radioControl.setErrors({required: true});
  
        return;
      }
      else {
        this.radioControl.setErrors(null);
      }
    }

    const borrowers = this.borrowersHasNotConsented;
    const selectedBorrowerIndex = this.showBorrowerByIndex;
    const originalStatuses = this.originalEconsentStatuses;
    const selectedConsentStatus = this.selectedConsentStatus;

    // Clear errors
    this.setError(null);

    // Decline all checkbox is checked
    if (this.declineEconsent) {
      if (this.isForcedSingleBorrowerMode) {
        this.eConsentResult.emit({showStep: 2});
      }
      else {
        this.borrowersHasConsented = this.borrowersToPick;
        this.eConsentResult.emit({borrowersToDecline: this.borrowersHasConsented});
      }
      return;
    }

    // Only update one borrower at a time
    const selectedBorrower = this.eConsentJointBorrowersConfig === true ? cloneDeep(borrowers[0]) : cloneDeep(borrowers[selectedBorrowerIndex]);
    if (selectedBorrower && selectedBorrower.eConsent) {
      selectedBorrower.eConsent.consentStatus = selectedConsentStatus;
    }
    // Supports multiple borrowers, one at a time
    const selectedBorrowers = selectedBorrower ? [selectedBorrower] : [];
    const changedBorrowers = selectedBorrowers
      .filter((borrower, index) => {
        const currentStatus = borrower
          && borrower.eConsent
          && borrower.eConsent.consentStatus || null;
        return currentStatus !== originalStatuses[index] || currentStatus === ConsentStatusEnum.Decline;
      })
      .map(borrower => {
        // Update the signed dates
        borrower.eConsent.statusAt = new Date().toISOString();
        borrower.eConsent.source = window.location.origin;
        return borrower;
      });

    const numberOfDecliningBorrowers = changedBorrowers.filter(borrower => {
      return borrower.eConsent.consentStatus === ConsentStatusEnum.Decline;
    }).length;

    const numberOfConsentingBorrowers = changedBorrowers.filter(borrower => {
      return borrower.eConsent.consentStatus === ConsentStatusEnum.Accept;
    }).length;

    // No one is eConsenting; show decline confirmation modal
    if (numberOfConsentingBorrowers === 0 && numberOfDecliningBorrowers > 0) {
      this.eConsentResult.emit({borrowersToDecline: changedBorrowers});
      return;
    } else {
      // If nothing has changed, just go back to the dashboard
      if (changedBorrowers.length === 0) {
        this.eConsentResult.emit({noChanges: true});
        return;
      }

      // We know there is at least one borrower eConsenting...
      this.setIsSaving(true);

      // Save the eConsent status
      this.econsentService.saveEconsentStatus(changedBorrowers, this.showCommunicationConsentText).subscribe(() => {
        //reset select button for coBorrower
        this.selectedConsentStatus = 0;
        // Set loading state
        this.setIsSaving(false);
        // Force reload of loan
        this.api.megaLoan.get(true).subscribe();
        // Emit success
        this.eConsentResult.emit({ wasEconsentSuccessful: true, isCoborrowerTurn: changedBorrowers[0].isCoBorrower});
      }, () => {
        // Show an error in the UI
        this.setError(`An error occurred while saving. Please try again.`);
        // Set loading state
        this.setIsSaving(false);
        // Emit failure
        this.eConsentResult.emit({wasEconsentSuccessful: false});
      });
    }
  }
}
