import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppSettings } from '$shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UIStoreService } from '$ui';

@UntilDestroy()
@Component({
  selector: 'app-company-logo',
  templateUrl: './company-logo.component.html',
  styleUrls: ['./company-logo.component.scss'],
})
export class CompanyLogoComponent implements OnInit, OnDestroy {
  /** What config key to use for values */
  @Input('configKey') configKey = `clover.logo.header.image`;
  /** Logo URLs */
  mainLogoSrc: string;
  mainLogoBackupSrc: string;
  numberOfImageErrors = 0;

  constructor(
    private settings: AppSettings,
    private ui: UIStoreService,
  ) {}

  public ngOnInit(): void {
    this.getLogoUrls();
  }

  public ngOnDestroy(): void {
  }

  /* Company name
  getter to get the latest data when user is not yet logged in (API execution for retrieving company info is done AFTER company logo is shown/initialized)
  */
  getCompanyName(): string {
    return this.settings.clientName;
  }

  getLogoUrls(): void {
    this.ui.select.config$.pipe(untilDestroyed(this)).subscribe(config => {
      if (config && config[this.configKey]) {
        this.mainLogoSrc = config[this.configKey].value;
        this.mainLogoBackupSrc = config[this.configKey].defaultValue;
        // Ignore ~ at the beginning of the string
        if (this.mainLogoBackupSrc && this.mainLogoBackupSrc.replace) {
          this.mainLogoBackupSrc = this.mainLogoBackupSrc.replace(/^~/, '');
        }
      }
    });
  }

  imageLoadErrorHandler(image: HTMLImageElement) {
    /** Avoid stack overflow issues */
    if (!this.mainLogoBackupSrc || this.numberOfImageErrors > 2) {
      console.warn(`Could not load main image and main image backup src`);
      return;
    }
    this.numberOfImageErrors++;
    image.src = this.mainLogoBackupSrc;
  }

}
