<app-header-simple></app-header-simple>

<main class="container" *ngIf="(config$ | async) as config" automationid="Page_ForgotPassword">
  <div class="row">
    <div class="col col-12 col-lg-8 offset-lg-2">
      <div class="card">
        <div class="card-body">
          <div class="row">
              <div class="col-md-6">
                <div class="text-center mb-5">
                  <h1 *ngIf="config && config['clover.account.forgot.page.title']; else title" [innerHtml]="config['clover.account.forgot.page.title'].value">
                  </h1>
                  <ng-template #title>Forgot my password</ng-template>
                </div>
                <div class="image-container">
                  <img
                    *ngIf="config && config['clover.account.forgot.page.image']; else image"
                    [src]="config['clover.account.forgot.page.image'].value"
                    alt="Forgot Password"
                  />
                  <ng-template #image><img src="/assets/img/forgot-password.png" alt="Forgot Password"/></ng-template>
                </div>
              </div>
            <div class="col-md-6">
              <form [formGroup]="formMain" (submit)="resetPassword(formMain)">
                <div role="alert" class="alert alert-success mb-4" *ngIf="showCheckEmailMessage" automationid="Section_ForgotPassword">
                  Please check your email for your temporary password.
                </div>

                <div role="alert" class="alert alert-danger icon mb-4" *ngIf="error">{{ error }}</div>

                <mat-form-field>
                  <input matInput placeholder="Email" type="text" id="userName" formControlName="userName" automationid="Email" required />
                  <mat-hint>Enter email address associated with your account.</mat-hint>
                  <mat-error>
                    <ng-container *ngIf="formMain.get('userName').hasError('notFound'); else showDefaultError">
                      Email address not recognized
                    </ng-container>
                    <ng-template #showDefaultError>
                      Not a valid email address
                    </ng-template>
                  </mat-error>
                </mat-form-field>

                <div class="button-container mt-4 mb-5">
                  <button
                    mat-flat-button
                    color="blue-outline"
                    class="forgot-password"
                    type="button"
                    (click)="sendTemporaryPassword(formMain)"
                    [disabled]="waiting"
                    [ngClass]="{ 'btn-waiting': waiting }"
                    automationid="BtnPassword"
                  >
                    <ng-container *ngIf="!waiting; else showLoading">
                      {{ showingResetForm ? 'Resend' : 'Send' }} Temporary Password
                    </ng-container>
                    <ng-template #showLoading>
                      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Loading...
                    </ng-template>
                  </button>
                </div>

                <ng-container *ngIf="showingResetForm">
                  <div class="password-container">
                    <mat-form-field>
                      <input
                        matInput
                        placeholder="Enter temporary password"
                        [type]="showTempPassword ? 'text' : 'password'"
                        id="tempPassword"
                        formControlName="tempPassword"
                        automationid="TempPassword"
                        required
                      />
                    </mat-form-field>
                    <button
                      class="toggle-show-password"
                      type="button"
                      (click)="showTempPassword = !showTempPassword"
                      [attr.aria-pressed]="!!showTempPassword"
                      automationid="BtnShowTempPassword"
                    >
                      <i
                        class="fa"
                        [class.fa-eye]="!showTempPassword"
                        [class.fa-eye-slash]="showTempPassword"
                        aria-label="View and hide password toggle button"
                      ></i>
                    </button>
                  </div>

                  <div class="password-container">
                    <mat-form-field>
                      <input
                        matInput
                        placeholder="Enter a new password"
                        [type]="showNewPassword ? 'text' : 'password'"
                        id="password"
                        formControlName="password"
                        (focus)="showPasswordRequirements = true"
                        (blur)="showPasswordRequirements = false"
                        automationid="NewPassword"
                        required
                      />
                    </mat-form-field>
                    <button
                      class="toggle-show-password"
                      type="button"
                      (click)="showNewPassword = !showNewPassword"
                      [attr.aria-pressed]="!!showNewPassword"
                      automationid="BtnShowNewPassword"
                    >
                      <i
                        class="fa"
                        [class.fa-eye]="!showNewPassword"
                        [class.fa-eye-slash]="showNewPassword"
                        aria-label="View and hide password toggle button"
                      ></i>
                    </button>
                  </div>
                  <app-password-requirements
                    [password]="formMain.value.password"
                    [isVisible]="showPasswordRequirements || (formMain.get('password').invalid && formMain.get('password').touched)"
                  ></app-password-requirements>

                  <div class="password-container">
                    <mat-form-field>
                      <input
                        matInput
                        placeholder="Confirm your password"
                        [type]="showConfirmPassword ? 'text' : 'password'"
                        id="passwordConfirm"
                        formControlName="passwordConfirm"
                        [errorStateMatcher]="passwordErrorMatcher"
                        automationid="ConfirmNewPassword"
                        required
                      />
                      <mat-error>
                        <ng-container *ngIf="formMain.hasError('notSamePassword'); else showDefaultConformPasswordError">
                          Password does not match
                        </ng-container>
                        <ng-template #showDefaultConformPasswordError>
                          Confirm password is required
                        </ng-template>
                      </mat-error>
                    </mat-form-field>
                    <button
                      class="toggle-show-password"
                      type="button"
                      (click)="showConfirmPassword = !showConfirmPassword"
                      [attr.aria-pressed]="!!showConfirmPassword"
                      automationid="BtnShowConfirmPassword"
                    >
                      <i
                        class="fa"
                        [class.fa-eye]="!showConfirmPassword"
                        [class.fa-eye-slash]="showConfirmPassword"
                        aria-label="View and hide password toggle button"
                      ></i>
                    </button>
                  </div>

                  <div class="button-container mt-4">
                    <button
                      mat-flat-button
                      color="accent"
                      type="submit"
                      [disabled]="waiting"
                      [ngClass]="{ 'btn-waiting': waiting }"
                      automationid="BtnWaiting"
                    >
                      Update Password
                    </button>
                  </div>
                </ng-container>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<app-footer></app-footer>
