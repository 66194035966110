import { Directive, HostListener, Input } from '@angular/core';
import { AnalyticsService } from '../services/analytics.service';

/**
 * Passes a click event to mixpanel
 * USAGE:  appTrack="Loan purpose edit button clicked"
 */
@Directive({
  selector: '[appTrack]',
})
export class AnalyticsDirective {
  @Input('appTrack') trackEventName: string;
  @HostListener('click')
  trackEvent() {
    this.analytics.trackEvent(this.trackEventName, {});
  }

  constructor(private analytics: AnalyticsService) {}
}
