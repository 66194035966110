import { Component, ChangeDetectionStrategy, SimpleChanges, OnChanges, Input, ChangeDetectorRef } from '@angular/core';
import { AuthorizationFormUtils } from '../../shared/validators/authorization-form.validators';

@Component({
  selector: 'app-password-requirements',
  templateUrl: './password-requirements.component.html',
  styleUrls: ['./password-requirements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordRequirementsComponent implements OnChanges {

  /** Password string used to validate */
  @Input() password: string;
  /** Toggle visibility */
  @Input() isVisible = false;
  /** Show validation status or not */
  showStatus = false;
  /** Validations */
  hasLowercase: boolean;
  hasUppercase: boolean;
  hasDigit: boolean;
  hasSpecialCharacter: boolean;
  hasEightCharacters: boolean;

  constructor(
    private ref: ChangeDetectorRef,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.password) {
      this.checkPassword(this.password);
    }
  }

  /**
   * Called anytime the password is changed in order to re-check validations
   * @param password
   */
  checkPassword(password: string): void {
    this.showStatus = !!password;
    this.hasLowercase = AuthorizationFormUtils.hasLowercase(password);
    this.hasUppercase = AuthorizationFormUtils.hasUppercase(password);
    this.hasDigit = AuthorizationFormUtils.hasDigit(password);
    this.hasSpecialCharacter = AuthorizationFormUtils.hasSpecialCharacter(password);
    this.hasEightCharacters = AuthorizationFormUtils.hasEightCharacters(password);
    this.ref.markForCheck();
  }

}
