import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFieldComponent } from './form-field/form-field.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { VendorModule } from 'src/app/vendor.module';
import { SharedModule } from '$shared';
import { AutocompleteAddressComponent } from './autocomplete-address/autocomplete-address.component';
import { InputmaskDirective } from './directives/inputmask.directive';

const components = [FormFieldComponent, AutocompleteComponent, AutocompleteAddressComponent];
const directives = [InputmaskDirective];

@NgModule({
  imports: [CommonModule, VendorModule, SharedModule],
  declarations: [components, directives],
  exports: [components, directives],
})
export class FormManagerModule {}
