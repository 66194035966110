import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '$api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { ILoanContact, LoanContactType } from 'src/app/shared/models';

interface ModalInputData {
  loanAccessByBorrowerFullName?: string;
  isTaskUnavailable?: boolean;
  isHeaderCloseButtonVisible?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-loan-lock',
  templateUrl: './loan-lock.component.html',
  styleUrls: ['./loan-lock.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoanLockModalComponent implements OnInit {

  public loanContactsState$ = this.api.select.loanContacts$;
  public loanContacts$: Observable<ILoanContact[]> = this.api.getApiStoreData(this.loanContactsState$).pipe(untilDestroyed(this), map((loanContacts: ILoanContact[]) => {

    const loanContactsFiltered = loanContacts
      .filter((contact: ILoanContact) => {
        // always hide 'other'
        if (contact.loanContactType === LoanContactType.Other) return false;
        return true;
      })
      // Sort by LoanContactType
      .sort((a: ILoanContact, b: ILoanContact) => {
        if (a.loanContactType === LoanContactType.LoanOfficer) return -1;
        if (b.loanContactType === LoanContactType.LoanOfficer) return 1;

        if (a.loanContactType === LoanContactType.LoanOfficerAssistant) return -1;
        if (b.loanContactType === LoanContactType.LoanOfficerAssistant) return 1;

        if (a.loanContactType === LoanContactType.Processor) return -1;
        if (b.loanContactType === LoanContactType.Processor) return 1;
      });
    return loanContactsFiltered;
  }));

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: ModalInputData,
    @Inject(MAT_DIALOG_DATA) public dataAlt: any,
    private api: ApiService,
    private analytics: AnalyticsService,
  ) { }

  ngOnInit() {
    // console.log("data object in loan lock comp ", this.data);
  }

  ngOnDestroy() { }

  /**
   * Determine session state based on user's loan count
   * If the user has multiple loans, return to my loans page
   * Else log the user out
  */
  public determineSessionStatus(): void {
    this.analytics.trackEvent('Your Loan is being worked on');
    this.close();
  }

  public getLoanContactTypeText(contactType: number): string {
    switch (contactType) {
      case 1:
        return "Loan Officer"
      case 2:
        return "Processor"
      case 3:
        return "Loan Officer Assistant"
      default:
        return ""
    }
  }

  public get loanAccessIsBorrower(): boolean {
    return this.data.loanAccessByBorrowerFullName && (this.loanAccessByBorrowerFullName.length > 0);
  }

  public get loanAccessByBorrowerFullName(): string {
    return this.data.loanAccessByBorrowerFullName;
  }

  public get isTaskUnavailable(): boolean {
    return this.data.isTaskUnavailable;
  }

  public get isHeaderCloseButtonVisible(): boolean {
    return this.data.isHeaderCloseButtonVisible;
  }

  /**
   * Close modal
  */
  public close(): void {
    this.dialogRef.close();
  }

}
