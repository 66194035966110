import { Pipe, PipeTransform } from '@angular/core';
import { MilitaryServiceTypeEnum, OwnershipStatusTypeEnum, MaritalStatusTypeEnum, DomesticRelationshipRightsEnum } from '../models';

type EnumValue = string | number;
type MappingKey = string;
type Transform = (value: EnumValue) => string;

const mappings: Record<MappingKey, Transform> = {
  'MilitaryServiceTypeEnum': value => {
    switch (value) {
      case MilitaryServiceTypeEnum.Serving:
        return 'Active Duty';
      case MilitaryServiceTypeEnum.Retired:
        return 'Retired / Discharged';
      case MilitaryServiceTypeEnum.Reserve:
        return 'National Guard / Reserves';
      default:
        return null;
    }
  },
  'MilitaryServiceTypeEnum2009': value => {
    switch (value) {
      case 2:
        return 'Active Duty';
      case 3:
        return 'Retired / Discharged';
      case 4:
        return 'National Guard / Reserves';
      case 5:
        return 'Surviving Spouse';
      default:
        return null;
    }
  },
  'OwnershipStatusTypeEnum': value => {
    switch (value) {
      case OwnershipStatusTypeEnum.Own:
        return 'Own';
      case OwnershipStatusTypeEnum.Rent:
        return 'Rent';
      case OwnershipStatusTypeEnum.RentFree:
        return 'Living Rent Free';
      default:
        return null;
    }
  },
  'MaritalStatusTypeEnum': value => {
    switch (value) {
      case MaritalStatusTypeEnum.Married:
        return 'Married';
      case MaritalStatusTypeEnum.Separated:
        return 'Separated';
      case MaritalStatusTypeEnum.Unmarried:
        return 'Unmarried';
      case MaritalStatusTypeEnum.CivilUnion:
        return 'Civil Union';
      case MaritalStatusTypeEnum.DomesticPartnership:
        return 'Domestic Partnership';
      case MaritalStatusTypeEnum.RegisteredReciprocalBeneficiaryRelationship:
        return 'Registered Reciprocal Beneficiary Relationship';
      case MaritalStatusTypeEnum.Other:
        return 'Other';
      default:
        return null;
    }
  },
  'DomesticRelationshipRightsEnum': value => {
    switch (value) {
      case DomesticRelationshipRightsEnum.SelectOne:
      default:
        return 'I don\'t know';
      case DomesticRelationshipRightsEnum.YesThereIsARelationshipWithSomeoneWhoHasPropertyRights:
        return 'Yes';
      case DomesticRelationshipRightsEnum.NoNoOneElseHasPropertyRights:
        return 'No';
    }
  }
};

@Pipe({
  name: 'enumToString',
})
export class EnumToStringPipe implements PipeTransform {
  transform(value: EnumValue, enumType: MappingKey): string {
    const transform = mappings[enumType];
    return transform ? transform(value) : `${value}`;
  }
}
