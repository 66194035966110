import { ButtonActions } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';

export const reviewPages: CvFormBuilder.Pages = {
  guidPageA: {
    title: 'You are ready to <strong>submit</strong> your loan information!',
    pageId: 'final',
    analyticsId: 'You are ready to <strong>submit</strong> your loan application!',
    isLastPage: true,
    showButtonBack: false,
    showButtonNext: false,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div class="text-center mb-3"><i style="font-size: 5.4rem" class="cvi cvi-submit-ready color-primary"></i></div>`,
      },

      <CvFormBuilder.Html>{
        type: 'html',
        html: `<p class="text-center mb-5">Once you submit, your loan officer will review your loan information and contact you to talk about your loan.</p>`,
      },

      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Submit',
            class: 'mat-accent',
            actions: [
              {
                type: ButtonActions.action,
                params: {
                  submit: true,
                },
              },
            ],
          },
        ],
      },
    ],
  }, // end page

  guidPageB: {
    title: 'Thanks for submitting! How was your <strong>experience</strong>?',
    pageId: 'nps',
    isLastPage: true,
    // showButtonBack: false,
    // showButtonNext: true,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div class="d-flex justify-content-center mb-3"><i style="font-size: 5.4rem" class="cvi cvi-submit-done color-primary"></i></div>`,
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div class="mb-5">On a scale from 0 through 10, 0 being the worst and 10 being the best, how likely are you to recommend this experience to a colleague or friend?</div>`,
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'nps',
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        hidden: true,
        placeholder: 'Rating',
        field: 'loan.$$custom.nps',
        formFieldType: 'number',
      },
    ],
  }, // end page
};
