import { Component, OnInit } from '@angular/core';
import { UIStoreService } from '$ui';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {

  customFooterHtml$ = this.ui.select.config$.pipe(map(config => {
    return config['clover.global.footer.html'] && config['clover.global.footer.html'].value || null;
  }));

  constructor(
    public ui: UIStoreService,
  ) {}

  ngOnInit(): void {}

}
