import { LoanUtils } from '../../../../shared/utils/loan-utils';
import { areAddressesEqual } from './addressComparison.util';
import { keyBy, groupBy, cloneDeep } from 'lodash';
import {
  ILoanViewModel,
  LoanPurposeTypeEnum,
  HomeBuyingTypeEnum,
  MaritalStatusTypeEnum,
  MilitaryServiceTypeEnum,
  IBorrowerViewModel,
  IMiscellaneousDebtViewModel,
  PropertyUsageTypeEnum,
  DomesticRelationshipRightsEnum,
  MilestoneStatusTypeEnum,
  ILiabilityViewModel,
  IPropertyViewModel,
  AssetTypeEnum,
  IAssetViewModel,
  ICPOSEmployment,
  IIncomeInfoViewModel,
  IEmploymentInfoViewModel,
  CPOSIncomeTypeEnum,
  EmploymentTypeEnum,
  SelectOneYesNoEnum,
  IncomeTypeEnum,
  PeriodTypeEnum,
  SelfEmploymentCloverEntityTypeEnum,
  SelfEmploymentCloverEntityTypeLLCEnum,
  SelfEmploymentEntityTypeEnum,
  EmploymentStatusTypeEnum,
  URLAFormTypeEnum,
  BankruptcyTypeEnum,
  PropertyTypeEnum
} from 'src/app/shared/models';
import { isDefined } from 'src/app/shared/utils/data-validation-util';
import { CommonUtils } from 'src/app/shared/utils/common-utils';
/**
 * Map the data from the form builder to what is needed for the model
 * @param loanModel
 * @param models
 */
export function mapFormBuilderToLoanModel(
  loanModel: ILoanViewModel,
  models: { [key: string]: any },
  urla: URLAFormTypeEnum,
  clientId: string,
  userId: string
) {
  if (!loanModel) {
    return;
  }
  const loanModelMapped: ILoanViewModel = cloneDeep(loanModel as ILoanViewModel);

  // Buying stage
  if (
    clientId !== 'env' &&
    loanModelMapped.loanPurposeType === LoanPurposeTypeEnum.Purchase &&
    loanModelMapped.$$custom.signedPurchaseAgreement !== true
  ) {
    loanModelMapped.homeBuyingType = HomeBuyingTypeEnum.GetPreApproved;
  }

  // Cash out flags
  //Purpose of Refinance 'OTHER(0)', should have the same value as 'TAKE_SOME_CASH_OUT(4)'
  if(loanModelMapped.loanPurposeType === LoanPurposeTypeEnum.Refinance &&
    loanModelMapped.purposeOfRefinance === 0) {
    loanModelMapped.purposeOfRefinance = 4;
  }

  if (
    loanModelMapped.loanPurposeType === LoanPurposeTypeEnum.Refinance &&
    loanModelMapped.purposeOfRefinance <= 6 &&
    loanModelMapped.otherInterviewData.cashOutAmount >= 0
  ) {
    loanModelMapped.otherInterviewData.cashOut = '1';
  } else {
    loanModelMapped.otherInterviewData.cashOut = '0';
  }
  if (
    loanModelMapped.loanPurposeType === LoanPurposeTypeEnum.Refinance &&
    loanModelMapped.purposeOfRefinance <= 6 &&
    loanModelMapped.purposeOfRefinance >= 0
  ) {
    loanModelMapped.financialInfo.cashOut = '1';
  } else {
    loanModelMapped.financialInfo.cashOut = '0';
  }

  // Infer spouse on loan from marital status
  if (loanModelMapped.transactionInfo.borrowers[0].maritalStatus === MaritalStatusTypeEnum.Unmarried) {
    loanModelMapped.transactionInfo.loanApplications[0].isSpouseOnTheLoan = false;
  }

  if (
    loanModelMapped.transactionInfo.loanApplications[0].isSpouseOnTheLoan &&
    (loanModelMapped.transactionInfo.borrowers[0].maritalStatus === MaritalStatusTypeEnum.Married ||
      loanModelMapped.transactionInfo.borrowers[0].maritalStatus === MaritalStatusTypeEnum.Separated)
  ) {
    loanModelMapped.transactionInfo.borrowers[1].maritalStatus =
      loanModelMapped.transactionInfo.borrowers[0].maritalStatus;
  }

  if (!loanModelMapped.transactionInfo.loanApplications[0].isSpouseOnTheLoan &&
    !!loanModelMapped.transactionInfo.borrowers[1]) {
    loanModelMapped.transactionInfo.borrowers[1].isActive = loanModelMapped.transactionInfo.loanApplications[0].isSpouseOnTheLoan;
  }

  // Military status adjustments
  if (loanModelMapped.$$custom.isMilitary !== true) {
    loanModelMapped.transactionInfo.borrowers[0].militaryServiceType = MilitaryServiceTypeEnum.None;
  }
  if (
    loanModelMapped.transactionInfo.loanApplications[0].isSpouseOnTheLoan &&
    loanModelMapped.$$custom.isMilitarySpouse !== true
  ) {
    loanModelMapped.transactionInfo.borrowers[1].militaryServiceType = MilitaryServiceTypeEnum.None;
  }
  if (loanModelMapped.transactionInfo.borrowers[0].militaryServiceType === 44) {
    loanModelMapped.transactionInfo.borrowers[0].militaryServiceType = MilitaryServiceTypeEnum.Reserve;
  }
  if (
    loanModelMapped.transactionInfo.loanApplications[0].isSpouseOnTheLoan &&
    loanModelMapped.transactionInfo.borrowers[1].militaryServiceType === 44
  ) {
    loanModelMapped.transactionInfo.borrowers[1].militaryServiceType = MilitaryServiceTypeEnum.Reserve;
  }

  // Set preferred phone
  function processPreferredPhoneNumber(borrower: IBorrowerViewModel) {
    if (borrower.phones && borrower.phones.length) {
      borrower.phones.filter(p => p.number === null).forEach(p => p.phoneId = null);
      const [firstPhone] = borrower.phones;
      firstPhone.phoneId = '11111111-1111-1111-1111-111111111111';
      borrower.preferredPhone = firstPhone;
    }
  }
  processPreferredPhoneNumber(loanModelMapped.transactionInfo.borrowers[0]);
  processPreferredPhoneNumber(loanModelMapped.transactionInfo.borrowers[1]);

  if (loanModelMapped.$$custom.haveREAgent) {
    if (loanModelMapped.$$custom.agentPhone) {
      loanModelMapped.$$custom.agentPhone.replace(/[^\d.]+/g, '');
    }

    let agentLoanParticipant = loanModelMapped.loanParticipants.loanParticipants.find(
      lp => lp.recordId === loanModelMapped.$$custom.agentLoanParticipant.recordId,
    );
    if (!agentLoanParticipant) {
      agentLoanParticipant = loanModelMapped.$$custom.agentLoanParticipant;
      // Ensure parent ID is set
      const recordId = agentLoanParticipant.recordId;
      // Set all default ID's and relationships
      agentLoanParticipant.companyPhoneNumber.parentId = agentLoanParticipant.parentId;
      agentLoanParticipant.companyFaxNumber.parentId = '00000000-0000-0000-0000-000000000000';
      agentLoanParticipant.companyFaxNumber.recordId = '00000000-0000-0000-0000-000000000000';
      agentLoanParticipant.emailAddressList.id = recordId;
      agentLoanParticipant.phoneList.id = recordId;
      agentLoanParticipant.emailAddressList.list.forEach((item: any) => (item.parentId = recordId));
      agentLoanParticipant.phoneList.list.forEach((item: any) => (item.parentId = recordId));

      loanModelMapped.loanParticipants.loanParticipants.push(agentLoanParticipant);
    }

    if (loanModelMapped.$$custom.agentName) {
      const nameParts = loanModelMapped.$$custom.agentName.split(' ');
      agentLoanParticipant.contactFullname = loanModelMapped.$$custom.agentName;
      agentLoanParticipant.firstName = nameParts[0];
      agentLoanParticipant.lastName = nameParts[1];
    }

    let reEmailAddress = agentLoanParticipant.emailAddressList.list.find(
      (emailAddress: any) => emailAddress.isPreferred === true,
    );
    if (!reEmailAddress && agentLoanParticipant.emailAddressList.list.length) {
      reEmailAddress = agentLoanParticipant.emailAddressList.list[0];
    }

    if (!reEmailAddress) {
      reEmailAddress = {};
      reEmailAddress.value = {};
      agentLoanParticipant.emailAddressList.list.push(reEmailAddress);
    }

    if (reEmailAddress) {
      reEmailAddress.value.emailAddress = loanModelMapped.$$custom.agentEmail;
    }


    let rePhone = agentLoanParticipant.phoneList.list.find((phone: any) => phone.value.phoneNumberType === 3);
    if (!rePhone) {
      rePhone = agentLoanParticipant.phoneList.list.find((phone: any) => phone.isPreferred === true);
    }
    if (!rePhone && agentLoanParticipant.phoneList.list.length) {
      rePhone = agentLoanParticipant.phoneList.list[0];
    }
    if (rePhone) {
      rePhone.value.phoneNumber = loanModelMapped.$$custom.agentPhone;
    }
  }

  const ALIMONY = 4;
  const CHILD_SUPPORT = 5;
  const SEPARATE_MAINTENANCE = 6;
  const [borrowerPrimary, borrowerSecondary] = loanModelMapped.transactionInfo.borrowers;

  //Alimony - Borrower
  borrowerPrimary.borrowerDetail.hasToPayForAlimony = loanModelMapped.$$custom.alimonyEtc.hasAlimony;
  borrowerPrimary.miscellaneousDebt.forEach((miscellaneousDebt: IMiscellaneousDebtViewModel) => {
    miscellaneousDebt.isRemoved = miscellaneousDebt.isBorrowerEntry &&
      ((miscellaneousDebt.typeId === ALIMONY) ||
        (miscellaneousDebt.typeId === CHILD_SUPPORT) ||
        (miscellaneousDebt.typeId === SEPARATE_MAINTENANCE));
  });
  createOrUpdateAlimonyMiscDebt(
    borrowerPrimary,
    loanModelMapped.$$custom.alimonyEtc.alimonyMiscellaneousDebtId,
    ALIMONY,
    loanModelMapped.$$custom.alimonyEtc.alimonyMonthlyAmount,
    !loanModelMapped.$$custom.alimonyEtc.hasAlimony);
  createOrUpdateAlimonyMiscDebt(
    borrowerPrimary,
    loanModelMapped.$$custom.alimonyEtc.childSupportMiscellaneousDebtId,
    CHILD_SUPPORT,
    loanModelMapped.$$custom.alimonyEtc.ChildSupportMonthlyAmount,
    !loanModelMapped.$$custom.alimonyEtc.hasAlimony);
  createOrUpdateAlimonyMiscDebt(
    borrowerPrimary,
    loanModelMapped.$$custom.alimonyEtc.seperateMiscellaneousDebtId,
    SEPARATE_MAINTENANCE,
    loanModelMapped.$$custom.alimonyEtc.SeperateMaintenanceMonthlyAmount,
    !loanModelMapped.$$custom.alimonyEtc.hasAlimony);

  //Alimony - Co-Borrower
  borrowerSecondary.borrowerDetail.hasToPayForAlimony = loanModelMapped.$$custom.alimonyEtc.hasAlimonySpouse;
  borrowerSecondary.miscellaneousDebt.forEach((miscellaneousDebt: IMiscellaneousDebtViewModel) => {
    miscellaneousDebt.isRemoved = miscellaneousDebt.isBorrowerEntry &&
      ((miscellaneousDebt.typeId === ALIMONY) ||
        (miscellaneousDebt.typeId === CHILD_SUPPORT) ||
        (miscellaneousDebt.typeId === SEPARATE_MAINTENANCE));
  });
  createOrUpdateAlimonyMiscDebt(
    borrowerSecondary,
    loanModelMapped.$$custom.alimonyEtc.alimonyMiscellaneousDebtIdSpouse,
    ALIMONY,
    loanModelMapped.$$custom.alimonyEtc.alimonyMonthlyAmountSpouse,
    !loanModelMapped.$$custom.alimonyEtc.hasAlimonySpouse);
  createOrUpdateAlimonyMiscDebt(
    borrowerSecondary,
    loanModelMapped.$$custom.alimonyEtc.childSupportMiscellaneousDebtIdSpouse,
    CHILD_SUPPORT,
    loanModelMapped.$$custom.alimonyEtc.ChildSupportMonthlyAmountSpouse,
    !loanModelMapped.$$custom.alimonyEtc.hasAlimonySpouse);
  createOrUpdateAlimonyMiscDebt(
    borrowerSecondary,
    loanModelMapped.$$custom.alimonyEtc.seperateMiscellaneousDebtIdSpouse,
    SEPARATE_MAINTENANCE,
    loanModelMapped.$$custom.alimonyEtc.SeperateMaintenanceMonthlyAmountSpouse,
    !loanModelMapped.$$custom.alimonyEtc.hasAlimonySpouse);

  // Infer declaration items from loan data
  // loan.$$custom.subjectPropertyOccupancyType
  if (loanModelMapped.$$custom.subjectPropertyOccupancyType === PropertyUsageTypeEnum.PrimaryResidence) {
    borrowerPrimary.declarationsInfo.propertyAsPrimaryResidence = 0;
    if (loanModelMapped.transactionInfo.loanApplications[0].isSpouseOnTheLoan) {
      borrowerSecondary.declarationsInfo.propertyAsPrimaryResidence = 0;
    }
  } else if (
    loanModelMapped.$$custom.subjectPropertyOccupancyType === PropertyUsageTypeEnum.InvestmentProperty ||
    loanModelMapped.$$custom.subjectPropertyOccupancyType === PropertyUsageTypeEnum.SecondVacationHome
  ) {
    borrowerPrimary.declarationsInfo.propertyAsPrimaryResidence = 1;
    if (loanModelMapped.transactionInfo.loanApplications[0].isSpouseOnTheLoan) {
      borrowerSecondary.declarationsInfo.propertyAsPrimaryResidence = 1;
    }
  }

  // Map DOB's to format needed by megasave (from YYYY-MM-DD to MM/DD/YYYY)
  borrowerPrimary.dateOfBirth = adjustDob(borrowerPrimary.dateOfBirth);
  borrowerSecondary.dateOfBirth = adjustDob(borrowerSecondary.dateOfBirth);

  borrowerSecondary.email = borrowerSecondary.userAccount.username;

  const [borrower0] = loanModelMapped.transactionInfo.borrowers;
  if (borrower0.maritalStatus === 3) {
    const { maritalStatusOther } = loanModelMapped.$$custom.loan.borrowerPrimary;
    borrower0.maritalStatus += maritalStatusOther - 1;
  }

  //Bug 409740:URLA 2.0 - Martial Status - "Other" Relationship Types do not map to Co-Borrower Marital Status in LC
  if ( loanModelMapped && loanModelMapped.transactionInfo && loanModelMapped.transactionInfo.loanApplications.length > 0
    && loanModelMapped.transactionInfo.borrowers.length > 0
    && !!loanModelMapped.transactionInfo.borrowers[0]  && !!loanModelMapped.transactionInfo.borrowers[1]
    && loanModelMapped.transactionInfo.borrowers[0].domesticRelationshipRights === DomesticRelationshipRightsEnum.YesThereIsARelationshipWithSomeoneWhoHasPropertyRights
    && (loanModelMapped.transactionInfo.borrowers[0].maritalStatus === MaritalStatusTypeEnum.DomesticPartnership
      || loanModelMapped.transactionInfo.borrowers[0].maritalStatus === MaritalStatusTypeEnum.Other
      || loanModelMapped.transactionInfo.borrowers[0].maritalStatus === MaritalStatusTypeEnum.CivilUnion
      ||  loanModelMapped.transactionInfo.borrowers[0].maritalStatus === MaritalStatusTypeEnum.RegisteredReciprocalBeneficiaryRelationship)
  )
  {
    loanModelMapped.transactionInfo.borrowers[1].maritalStatus = loanModelMapped.transactionInfo.borrowers[0].maritalStatus;
  }

  const { addressSubject } = loanModelMapped.$$custom.loan;
  if (addressSubject.pudIndicator === null) {
    addressSubject.pudIndicator = 0;
  }
  //Urla 2009 - default units to 2, Urla 2020 - default units to 0
  if (addressSubject.numberOfUnits === null && addressSubject.projectType === PropertyTypeEnum.MultiFamilyTwoToFourUnits) {
    addressSubject.numberOfUnits = LoanUtils.isURLA2020(loanModelMapped.urlaFormType) ? 0 : 2;
  }

  //Bug 468529 - only MultiFamilyTwoToFourUnits has value greater than 1, otherwise we need to re-set to 1
  if (addressSubject.numberOfUnits > 1 && addressSubject.propertyType !== PropertyTypeEnum.MultiFamilyTwoToFourUnits) {
    addressSubject.numberOfUnits = 1;
  }

  // We are defaulting numberOfStories to 1 for condominiums, if not set (bug 423880).
  if ((addressSubject.numberOfStories == null || addressSubject.numberOfStories == 0) && addressSubject.propertyType === PropertyTypeEnum.Condominium) {
    addressSubject.numberOfStories = 1;
  }

  // We are setting the PUD indicator to No for condominiums & co-ops (bug 423880).
  if (addressSubject.propertyType === PropertyTypeEnum.Condominium || addressSubject.propertyType === PropertyTypeEnum.Cooperative) {
    addressSubject.pudIndicator = SelectOneYesNoEnum.No;
  }

  loanModelMapped.transactionInfo.borrowers.forEach(b => {
    if (b.domesticRelationshipRights === null) {
      b.domesticRelationshipRights = DomesticRelationshipRightsEnum.SelectOne;
    }
  });

  const { downPaymentTypeCodeOriginal } = loanModelMapped.$$custom.loan.financialInfo;
  const DPTC_Other = 19;
  const knownDptcs = [1, 4, 6, 12, 7, 11, DPTC_Other];
  if (
    downPaymentTypeCodeOriginal !== null &&
    !knownDptcs.includes(downPaymentTypeCodeOriginal) &&
    loanModelMapped.financialInfo.downPaymentTypeCode === DPTC_Other
  ) {
    loanModelMapped.financialInfo.downPaymentTypeCode = downPaymentTypeCodeOriginal;
  }

  // Map eVOI/eVOE authorization to borrower if necessary
  if (
    loanModelMapped.$$custom.isRunEvoiEvoeAuthorized &&
    loanModelMapped.transactionInfo.borrowers[0].borrowerDetail &&
    loanModelMapped.transactionInfo.borrowers[0].borrowerDetail.employmentVerificationAuthorizedBy == null &&
    loanModelMapped.transactionInfo.borrowers[0].borrowerDetail.employmentVerificationAuthorizedDate == null
  ) {
    // Primary borrower
    loanModelMapped.transactionInfo.borrowers[0].borrowerDetail.employmentVerificationAuthorizedBy = +userId;
    loanModelMapped.transactionInfo.borrowers[0].borrowerDetail.employmentVerificationAuthorizedDate = new Date().toISOString();
  }
  if (
    loanModelMapped.$$custom.isRunEvoiEvoeAuthorizedSpouse &&
    loanModelMapped.transactionInfo.borrowers[1].borrowerDetail &&
    loanModelMapped.transactionInfo.borrowers[1].borrowerDetail.employmentVerificationAuthorizedBy == null &&
    loanModelMapped.transactionInfo.borrowers[1].borrowerDetail.employmentVerificationAuthorizedDate == null
  ) {
    // Secondary borrower
    loanModelMapped.transactionInfo.borrowers[1].borrowerDetail.employmentVerificationAuthorizedBy = +userId;
    loanModelMapped.transactionInfo.borrowers[1].borrowerDetail.employmentVerificationAuthorizedDate = new Date().toISOString();
  }

  // if the borrower is US Citizen set the value of Green card to NO.
  // 0 means YES and 1 means NO! You are shocked right?!
  if (
    loanModelMapped.transactionInfo.borrowers[0].declarationsInfo.usCitizenIndicator === 0 &&
    loanModelMapped.transactionInfo.borrowers[0].declarationsInfo.permanentResidentAlienIndicator === 0
  ) {
    loanModelMapped.transactionInfo.borrowers[0].declarationsInfo.permanentResidentAlienIndicator = 1;
  }

  // if the CoBorrower is US Citizen set the value of Green card to NO.
  if (
    loanModelMapped.transactionInfo.loanApplications[0].isSpouseOnTheLoan &&
    loanModelMapped.transactionInfo.borrowers[1].declarationsInfo.usCitizenIndicator === 0 &&
    loanModelMapped.transactionInfo.borrowers[1].declarationsInfo.permanentResidentAlienIndicator === 0
  ) {
    loanModelMapped.transactionInfo.borrowers[1].declarationsInfo.permanentResidentAlienIndicator = 1;
  }

  mapPropertiesToLoan(loanModelMapped);
  mapLiabilitiesToLoan(loanModelMapped);
  mapAssetsToLoan(loanModelMapped);
  mapEmploymentsToLoan(loanModelMapped, models, urla);
  mapCreditAuth(loanModelMapped);
  mapDeclarations(loanModelMapped, urla);
  updateMilestoneStatus(loanModelMapped);
  mapAdditionalApplicant(loanModelMapped);

  delete (<any>loanModelMapped).$$custom;
  return loanModelMapped;
}

function createOrUpdateAlimonyMiscDebt(borrower: IBorrowerViewModel, alimonyMiscellaneousDebtId: string, alimonyMiscDebtType: number, amount: number, isRemoved: boolean): void {
  if (borrower == null || alimonyMiscellaneousDebtId == null) {
    return;
  }

  let alimonyMiscDebt = borrower.miscellaneousDebt.find(m => m.miscellaneousDebtId === alimonyMiscellaneousDebtId);

  if (alimonyMiscDebt == null) {
    //push new misc-debt object
    alimonyMiscDebt = <IMiscellaneousDebtViewModel>{
      miscellaneousDebtId: alimonyMiscellaneousDebtId,
      borrowerId: borrower.borrowerId,
      typeId: alimonyMiscDebtType,
      isBorrowerEntry: true
    };
    borrower.miscellaneousDebt.push(alimonyMiscDebt);
  }

  alimonyMiscDebt.amount = amount;
  alimonyMiscDebt.isRemoved = isRemoved;
}

function mapDeclarations(loan: ILoanViewModel, urla: URLAFormTypeEnum): void {

  if (urla === URLAFormTypeEnum.URLA2020) {
    const { bankrupcyType, bankrupcyType2 } = loan.$$custom;
    loan.transactionInfo.borrowers[0].declarationsInfo.bankruptcyType = bankrupcyType.reduce((a, b) => (a | b), BankruptcyTypeEnum.SelectOne);
    loan.transactionInfo.borrowers[1].declarationsInfo.bankruptcyType = bankrupcyType2.reduce((a, b) => (a | b), BankruptcyTypeEnum.SelectOne);

    //Citizenship - Borrower
    let usCitizenIndicatorBorrower = loan.transactionInfo.borrowers[0].declarationsInfo.usCitizenIndicator;
    citizenshipDeclarationHelper(loan, usCitizenIndicatorBorrower, 0);

    //Citizenship - CoBorrower
    let usCitizenIndicatorCoBorrower = loan.transactionInfo.borrowers[1].declarationsInfo.usCitizenIndicator;
    citizenshipDeclarationHelper(loan, usCitizenIndicatorCoBorrower, 1);

  }
}
function citizenshipDeclarationHelper(loan: ILoanViewModel, citizenIndicator: number, x: number): void {
  switch (citizenIndicator) {
    case 0:
      loan.transactionInfo.borrowers[x].declarationsInfo.permanentResidentAlienIndicator = 1;
      loan.transactionInfo.borrowers[x].citizenResidencyType = 1;
      loan.transactionInfo.borrowers[x].usCitizen = true;
      loan.transactionInfo.borrowers[x].permanentAlien = false;
      loan.transactionInfo.borrowers[x].NonPermanentAlien = false;
      break;
    case 1:
      loan.transactionInfo.borrowers[x].declarationsInfo.permanentResidentAlienIndicator = 0;
      loan.transactionInfo.borrowers[x].citizenResidencyType = 0;
      loan.transactionInfo.borrowers[x].usCitizen = false;
      loan.transactionInfo.borrowers[x].permanentAlien = true;
      loan.transactionInfo.borrowers[x].NonPermanentAlien = false;
      break;
    case 2:
      loan.transactionInfo.borrowers[x].declarationsInfo.permanentResidentAlienIndicator = 1;
      loan.transactionInfo.borrowers[x].citizenResidencyType = 2;
      loan.transactionInfo.borrowers[x].usCitizen = false;
      loan.transactionInfo.borrowers[x].permanentAlien = false;
      loan.transactionInfo.borrowers[x].NonPermanentAlien = true;
      break;
    default:
  }
}

/**
 * Update the current milestone for the loan
 * @param loan
 */
function updateMilestoneStatus(loan: ILoanViewModel): void {
  // Only update loans that are Propect or PreApproved
  if (
    loan.currentMilestone !== MilestoneStatusTypeEnum.Prospect &&
    loan.currentMilestone !== MilestoneStatusTypeEnum.PreApproved
  ) {
    return;
  }

  const hasCreditRan = LoanUtils.hasCreditRan(loan);
  const isPurchase = loan.loanPurposeType === LoanPurposeTypeEnum.Purchase;

  // Anytime all six pieces are collected, move to Incomplete status
  if (LoanUtils.areSixPiecesAcquiredForAllLoanApplications(loan)) {
    loan.currentMilestone = MilestoneStatusTypeEnum.Incomplete;
  } else if (
    isPurchase &&
    loan.homeBuyingType === HomeBuyingTypeEnum.GetPreApproved &&
    LoanUtils.areSixPiecesAcquiredForAllLoanApplications(loan, true) &&
    hasCreditRan
  ) {
    loan.currentMilestone = MilestoneStatusTypeEnum.PreApproved;
  }
}

function mapAdditionalApplicant(loanModel: ILoanViewModel): void {

  // If additional applicant's user account is not created yet, then include possible changes
  if (loanModel.$$custom.loan.additionalApplicant
    && isDefined(loanModel.$$custom.loan.additionalApplicant.loanApplicationId)
    && loanModel.$$custom.loan.additionalApplicant.isOnlineUser !== true) {

    loanModel.additionalApplicant = {
      ...loanModel.additionalApplicant,
      ...loanModel.$$custom.loan.additionalApplicant
    };
  }
}


/**
 * Manage mapping for credit REOs/liabilities
 * @param loanModel
 */
function mapLiabilitiesToLoan(loanModel: ILoanViewModel) {
  // Create a dictionary of liabilities (mortgages) which were interacted with on the credit page
  const LiabilityRecord: Record<string, ILiabilityViewModel> = {};
  loanModel.$$custom.loan.liabilities.forEach(liability => {
    if (liability.lienPosition === 1) {
      liability.borrowerDebtCommentId = 2;
    }

    LiabilityRecord[liability.liabilityInfoId] = liability;
  });

  // If source liabilities array is empty, add in newly added ones from custom
  if (!loanModel.transactionInfo.liabilities.length) {
    loanModel.transactionInfo.liabilities = [...loanModel.$$custom.loan.liabilities];
  }

  // Map liabilities back to transaction info
  loanModel.transactionInfo.liabilities = loanModel.transactionInfo.liabilities
    // Remove any empty liabilities
    .filter(liability => (!liability.borrowerId ? false : true))
    // Check for liabilities modified by the credit REO page, replace any entries in the array
    .map(liability => {
      // If liability entry found in record, swap with that
      const liabilityNew = LiabilityRecord[liability.liabilityInfoId]
        ? LiabilityRecord[liability.liabilityInfoId]
        : liability;
      return liabilityNew;
    });
}

/**
 * @param loanModel
 */
function mapPropertiesToLoan(loanModel: ILoanViewModel) {
  // Always save occupancy type to loan application
  loanModel.transactionInfo.loanApplications[0].occupancyType = loanModel.$$custom.subjectPropertyOccupancyType;

  loanModel.$$custom.loan.addressSubject.purchaseDate = adjustDate(loanModel.$$custom.loan.addressSubject.purchaseDate);

  loanModel.$$custom.loan.addressSubject.occupancyType = loanModel.$$custom.subjectPropertyOccupancyType;
  loanModel.$$custom.loan.borrowerPrimary.addressCurrent.isSameMailingAsBorrowerCurrentAddress = true;
  loanModel.$$custom.loan.borrowerPrimary.addressCurrent.isSameAsPrimaryBorrowerCurrentAddress = true;
  loanModel.$$custom.loan.borrowerPrimary.addressCurrent.isSameAsPropertyAddress =
    loanModel.$$custom.loan.borrowerPrimary.addressCurrent.streetName &&
    loanModel.$$custom.loan.addressSubject.streetName &&
    areAddressesEqual(loanModel.$$custom.loan.borrowerPrimary.addressCurrent, loanModel.$$custom.loan.addressSubject);

  loanModel.$$custom.loan.borrowerPrimary.addressMailing.isSameAsPropertyAddress = false;
  loanModel.$$custom.loan.borrowerPrimary.addressMailing.isSameAsPrimaryBorrowerCurrentAddress = false;
  loanModel.$$custom.loan.borrowerPrimary.addressMailing.isSameMailingAsBorrowerCurrentAddress =
    loanModel.$$custom.loan.borrowerPrimary.isMailingAddressSameAsCurrent;

  loanModel.$$custom.loan.borrowerSecondary.addressCurrent.isSameMailingAsBorrowerCurrentAddress = false;
  loanModel.$$custom.loan.borrowerSecondary.addressCurrent.isSameAsPrimaryBorrowerCurrentAddress =
    loanModel.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower &&
    loanModel.$$custom.loan.borrowerSecondary.isMailingAddressSameAsCurrent;

  loanModel.$$custom.loan.borrowerSecondary.addressMailing.isSameMailingAsBorrowerCurrentAddress =
    loanModel.$$custom.loan.borrowerSecondary.isMailingAddressSameAsCurrent;

  let subjectProeprty = loanModel.$$custom.loan.addressSubject;
  if (loanModel.$$custom.loan.isSubjectPropertyInValid) {
    subjectProeprty = loanModel.$$custom.loan.originalSubjectProeprty;
  } else {
    loanModel.$$custom.loan.originalSubjectProeprty = loanModel.$$custom.loan.addressSubject;
  }
  setStateIdForAddresses(loanModel);
  // Extract properties out of the custom loan object, map to properties array
  const cvProperties = [
    subjectProeprty,
    loanModel.$$custom.loan.borrowerPrimary.addressCurrent,
    loanModel.$$custom.loan.borrowerPrimary.addressMailing,
    loanModel.$$custom.loan.borrowerSecondary.addressCurrent,
    loanModel.$$custom.loan.borrowerSecondary.addressMailing,
  ];

  mapPreviousAddress(
    loanModel,
    loanModel.transactionInfo.borrowers[0],
    loanModel.$$custom.loan.borrowerPrimary.addressCurrent,
    loanModel.$$custom.loan.borrowerPrimary.addressHistory,
    cvProperties,
  );

  mapPreviousAddress(
    loanModel,
    loanModel.transactionInfo.borrowers[1],
    loanModel.$$custom.loan.borrowerSecondary.addressCurrent,
    loanModel.$$custom.loan.borrowerSecondary.addressHistory,
    cvProperties,
  );

  const cvPropertyMap = keyBy(cvProperties, 'propertyId');
  let properties: IPropertyViewModel[] = loanModel.properties.map(property => {
    if (cvPropertyMap[property.propertyId]) {
      return {
        ...property,
        ...cvPropertyMap[property.propertyId],
      };
    }
    return property;
  });

  // Add any new properties to the loan model
  const loanPropertyMap = keyBy(loanModel.properties, 'propertyId');
  cvProperties.forEach((property: IPropertyViewModel) => {
    if (!loanPropertyMap[property.propertyId]) {
      properties.push(property);
    }
  });

  // If this is a non-spouse coborrower flow, set the non spouse properties to link to the child loan ID
  /**
  if (loanModel.$$custom.nonSpouseCoborrower) {
    const borrowerId = loanModel.$$custom.loan.borrowerPrimary.borrowerId;
    const childLoanId = loanModel.$$custom.loan.borrowerPrimary.loanApplicationId;
    properties.forEach(prop => {
      if (prop.borrowerId === borrowerId) {
        prop.loanApplicationId = childLoanId;
        prop.loanId = childLoanId;
      }
    });
  }
   */

  // Update loan model
  loanModel.properties = properties;
  loanModel.transactionInfo.properties = properties;
}

function setStateIdForAddresses(loanModel: ILoanViewModel) {
  setStateId(loanModel.$$custom.loan.addressSubject);
  setStateId(loanModel.$$custom.loan.borrowerPrimary.addressCurrent);
  setStateId(loanModel.$$custom.loan.borrowerPrimary.addressMailing);
  setStateId(loanModel.$$custom.loan.borrowerSecondary.addressCurrent);
  setStateId(loanModel.$$custom.loan.borrowerSecondary.addressMailing);
}

function setStateId(address: IPropertyViewModel) {
  if (!address) {
    return;
  }
  let stateLookup = CommonUtils.getStates().find(state => state.text == address.stateName);
  if (stateLookup) {
    address.stateId = stateLookup.value;
  }
}


function mapPreviousAddress(
  loanModel: ILoanViewModel,
  borrower: IBorrowerViewModel,
  currentAddress: IPropertyViewModel,
  previousAddress: IPropertyViewModel,
  allAddresses: any[],
) {
  const years: number = currentAddress.timeAtAddressYears;
  const months: number = currentAddress.timeAtAddressMonths ? currentAddress.timeAtAddressMonths : 0;
  if (years * 12 + months < 24.0) {
    previousAddress.loanId = loanModel.transactionInfo.loanApplications[0].loanApplicationId;
    previousAddress.loanApplicationId = loanModel.transactionInfo.loanApplications[0].loanApplicationId;
    previousAddress.borrowerId = borrower.borrowerId;

    previousAddress.isSubjectProperty = false;
    previousAddress.isSameAsPropertyAddress = false;
    previousAddress.isSameMailingAsBorrowerCurrentAddress = false;

    previousAddress.addressTypeId = 2;
    if (!previousAddress.previousAddressOrdinal) {
      previousAddress.previousAddressOrdinal = 1;
    }

    allAddresses.push(previousAddress);
    if (!borrower.previousAddressIds) {
      borrower.previousAddressIds = [];
    }

    if (!borrower.previousAddressIds.includes(previousAddress.propertyId)) {
      borrower.previousAddressIds.push(previousAddress.propertyId);
    }
  }
}

/**
 * @param loanModel
 */
function mapAssetsToLoan(loanModel: ILoanViewModel) {
  // If purchase, add a down payment to the assets array
  const downPaymentAsset = loanModel.$$custom.loan.downPaymentAsset;
  if (loanModel.$$custom.loan.addressSubject.downPayment) {
    downPaymentAsset.ownerId = loanModel.transactionInfo.borrowers[0].borrowerId;
    downPaymentAsset.assetType = AssetTypeEnum.Other;
    downPaymentAsset.isDownPayment = true;
    downPaymentAsset.assetValue = loanModel.$$custom.loan.addressSubject.downPayment;
    downPaymentAsset.monthlyAmount = loanModel.$$custom.loan.addressSubject.downPayment;
  }
  loanModel.$$custom.loan.assets.push(downPaymentAsset);

  // Fix-up asset values
  loanModel.$$custom.loan.assets.forEach((asset: IAssetViewModel) => {
    if (asset.assetType !== null && asset.assetType >= 0 && asset.monthlyAmount !== null && asset.monthlyAmount > 0) {
      // Primary borrower is the default owner
      if (!asset.ownerId) {
        asset.ownerId = loanModel.transactionInfo.borrowers[0].borrowerId;
      }

      // Set jointAccount flag
      if (asset.ownerId === 'joint') {
        asset.ownerId = loanModel.transactionInfo.borrowers[0].borrowerId;
        asset.jointAccount = true;
      } else {
        asset.jointAccount = false;
      }

      // Joint accounts show up as part of the primary borrower array, so set it in case of previous owner
      if (asset.previousOwnerId === 'joint') {
        asset.previousOwnerId = loanModel.transactionInfo.borrowers[0].borrowerId;
      }

      // If the owner stayed the same, remove the previousOwnerId
      if (asset.previousOwnerId === asset.ownerId) {
        delete asset.previousOwnerId;
      }
    }
  });

  loanModel.transactionInfo.borrowers.forEach(borrower => {
    // Empty out the assets, except for asset types that Clover does not manage
    borrower.assets = borrower.assets.filter((asset: IAssetViewModel) => {
      return [AssetTypeEnum.Automobile, AssetTypeEnum.LifeInsuranceCashValue].includes(asset.assetType);
    });

    // Loop through the custom assets array and place into the correct asset bucket for the correct borrower
    loanModel.$$custom.loan.assets.forEach((asset: IAssetViewModel) => {
      if (
        (asset.assetType !== null && asset.assetType >= 0 && asset.monthlyAmount !== null && asset.monthlyAmount > 0) ||
        asset.assetType === null // Brand new asset with bank chosen from AutoComplete
      ) {
        // Add the asset to borrower's assets
        if (asset.ownerId === borrower.borrowerId) {
          asset.isRemoved = asset.forDelete === true;
          borrower.assets.push(asset);
        } else if (
          // If an asset previously belonged to this borrower, we need to explicitly delete it
          asset.previousOwnerId &&
          asset.previousOwnerId === borrower.borrowerId
        ) {
          const clonedAsset = cloneDeep(asset);
          clonedAsset.isRemoved = true;
          borrower.assets.push(clonedAsset);
        }
      }
    });
  });

  /**
  // console.log('cvAssets', loanModel.$$custom.loan.assets);
  loanModel.transactionInfo.borrowers.forEach(borrower => {
    // console.log('F2L Assets', borrower.assets);
  });
   */

  /**
  // Map assets back to the appropriate borrower
  loanModelMapped.transactionInfo.borrowers[0].assets = loanModelMapped.transactionInfo.borrowers[0].assets.filter(
    (asset: any) => {
      if (!asset.institiutionContactInfo.companyName || asset.institiutionContactInfo.companyName === '') {
        return false;
      } else if (asset.ownerId === loanModelMapped.transactionInfo.borrowers[0].borrowerId) {
        return true;
      } else {
        loanModelMapped.transactionInfo.borrowers[1].assets.push(asset);
        return false;
      }
    },
  );
 */
}

/**
 * Unwind the flat internal employment model back into individual loan employments
 * and incomes, on top of an existing model, being careful not to overwrite/delete
 * objects that are not tracked by Clover.
 *
 * @param loanModel
 * @param models
 */
function mapEmploymentsToLoan(loanModel: ILoanViewModel, models: { [key: string]: any }, urla: URLAFormTypeEnum) {
  // TODO: Better way of tracking current/previous employment
  const borrowersWithCurrentEmployments: string[] = [];

  // Fix-up the borrower ids if employment has no borrower id assigned
  loanModel.$$custom.loan.employments.forEach((cvEmployment: ICPOSEmployment) => {
    cvEmployment.borrowerId = cvEmployment.borrowerId || loanModel.transactionInfo.borrowers[0].borrowerId;
  });

  addOtherCvEmploymentIfNeeded(
    loanModel.transactionInfo.borrowers[0].borrowerId,
    loanModel.$$custom.loan.employments,
    models,
  );

  addOtherCvEmploymentIfNeeded(
    loanModel.transactionInfo.borrowers[1].borrowerId,
    loanModel.$$custom.loan.employments,
    models,
  );

  // Map used to attach internal employment models to existing employments
  const loanEmploymentsMap = keyBy(loanModel.transactionInfo.employments, 'employmentInfoId');

  // Map used to attach internal employment models to existing incomes (which don't reference any employments)
  const nonEmploymentIncomes = loanModel.transactionInfo.incomes.filter((income: IIncomeInfoViewModel) => {
    return income.employmentInfoId === null ? true : false;
  });
  const nonEmploymentIncomesMap = keyBy(nonEmploymentIncomes, 'incomeInfoId');

  // Empty employment objects should be re-used per borrower
  const emptyLoanEmployments = loanModel.transactionInfo.employments.filter((employment: IEmploymentInfoViewModel) => {
    return employment.employmentTypeId === null || employment.employmentTypeId < 0 ? true : false;
  });
  const borrowerEmptyLoanEmploymentsMap = groupBy(emptyLoanEmployments, 'borrowerId');

  // Loop over internal, flat employment list
  loanModel.$$custom.loan.employments.forEach((cvEmployment: ICPOSEmployment) => {
    // Ignore employments with no types, regardless of what other data may be filled out
    if (cvEmployment.cvIncomeTypeId === null || cvEmployment.cvIncomeTypeId < 0) {
      return;
    }

    // Ignore temporary TWN employments
    if (
      cvEmployment.preserveOnTwnFilter !== true &&
      ((cvEmployment.cvIncomeTypeId === CPOSIncomeTypeEnum.Employed &&
        cvEmployment.employerInfo &&
        !cvEmployment.employerInfo.name) ||
        (cvEmployment.cvIncomeTypeId === CPOSIncomeTypeEnum.PensionRetirement &&
          cvEmployment.income.retirementStartDate === null &&
          cvEmployment.income.monthlyPension === null))
    ) {
      return;
    }

    // Employment-types
    if (
      [
        CPOSIncomeTypeEnum.Employed,
        CPOSIncomeTypeEnum.SelfEmployed,
        CPOSIncomeTypeEnum.MilitaryPay,
        CPOSIncomeTypeEnum.PensionRetirement,
        CPOSIncomeTypeEnum.NoIncome,
      ].includes(cvEmployment.cvIncomeTypeId) &&
      !cvEmployment.income.retirementIncomeOther
    ) {
      // Get current employment from loan
      let loanEmployment: IEmploymentInfoViewModel = loanEmploymentsMap[cvEmployment.employmentInfoId];

      // Try setting loan employment to one of the empty loan employments provided by the back-end
      if (!loanEmployment) {
        // Get empty employment, if any, for the current borrower
        const borrowerEmptyLoanEmployments = borrowerEmptyLoanEmploymentsMap[cvEmployment.borrowerId];
        if (borrowerEmptyLoanEmployments) {
          loanEmployment = borrowerEmptyLoanEmployments.shift();
        }

        if (loanEmployment) {
          cvEmployment.employmentInfoId = loanEmployment.employmentInfoId;

          // Attempt to construct an income matrix from empty employment
          const incomeMatrix = loanModel.transactionInfo.incomes.filter((income: IIncomeInfoViewModel) => {
            return income.employmentInfoId === loanEmployment.employmentInfoId ? true : false;
          });

          // Use empty employment income matrix, if it exists
          if (incomeMatrix.length > 0) {
            cvEmployment.income.incomeMatrix = incomeMatrix;
          }
        }
      }

      // If no existing employments to map to, create a new one
      if (!loanEmployment) {
        loanEmployment = cloneDeep(models['loan.transactionInfo.borrowers.employments']);
        loanModel.transactionInfo.employments.push(loanEmployment);
      }

      // Carry-over existing ids
      loanEmployment.employmentInfoId = cvEmployment.employmentInfoId;
      loanEmployment.borrowerId = cvEmployment.borrowerId;

      // Based on employment type, data gets filled out differently
      if (cvEmployment.cvIncomeTypeId === CPOSIncomeTypeEnum.Employed) {
        loanEmployment.employmentTypeId = EmploymentTypeEnum.SalariedEmployee;

        /**
         * Fill out salaried-employee-specific properties
         */
        loanEmployment.name = cvEmployment.employerInfo.name;
        loanEmployment.address.streetName = cvEmployment.employerInfo.address;
        loanEmployment.address.cityName = cvEmployment.employerInfo.city;
        loanEmployment.address.stateName = cvEmployment.employerInfo.state;
        loanEmployment.address.zipCode = cvEmployment.employerInfo.zip;
        loanEmployment.businessPhone = cvEmployment.employerInfo.phone;
        loanEmployment.address.unitNumber = cvEmployment.employerInfo.unitNumber;

        loanEmployment.positionDescription = cvEmployment.positionInfo.title;
        loanEmployment.employmentStartDate = adjustDate(cvEmployment.positionInfo.dateStart);
        loanEmployment.employmentEndDate = adjustDate(cvEmployment.positionInfo.dateEnd);
        loanEmployment.yearsInThisProfession = cvEmployment.positionInfo.yearsInPosition ? +cvEmployment.positionInfo.yearsInPosition : null;
        loanEmployment.monthsInThisProfession = cvEmployment.positionInfo.monthsInPosition ? +cvEmployment.positionInfo.monthsInPosition : null;
        loanEmployment.specialBorrowerEmployerRelationship = cvEmployment.isSpecialBorrowerEmployerRelationship ? SelectOneYesNoEnum.Yes : SelectOneYesNoEnum.No;
        loanEmployment.isSeasonal = cvEmployment.isSeasonal;
        loanEmployment.isForeign = cvEmployment.isForeign;
        loanEmployment.notes = cvEmployment.notes;

        /**
         * Adjust salaried employee income matrix
         */

        cvEmployment.income.incomeMatrix.forEach(income => {
          income.employmentInfoId = cvEmployment.employmentInfoId;
          income.borrowerId = cvEmployment.borrowerId;
          income.isRemoved = ![
            IncomeTypeEnum.BaseEmployment,
            IncomeTypeEnum.Overtime,
            IncomeTypeEnum.Bonuses,
            IncomeTypeEnum.Commissions,
          ].includes(income.incomeTypeId);
        });
        const incomeMatrixMap = keyBy(cvEmployment.income.incomeMatrix, 'incomeTypeId');

        /**
         * Fill out salaried-employee-specific incomes
         */

        const baseIncome: IIncomeInfoViewModel = incomeMatrixMap[IncomeTypeEnum.BaseEmployment];
        baseIncome.preferredPaymentPeriodId = cvEmployment.income.basePaymentPeriodId;
        baseIncome.amount = cvEmployment.income.baseAmount;
        baseIncome.hourPerWeek = cvEmployment.income.baseHoursPerWeek;
        if (baseIncome.preferredPaymentPeriodId === PeriodTypeEnum.Hourly) {
          baseIncome.payPerHour = cvEmployment.income.baseAmount;
        }

        const overtimeIncome: IIncomeInfoViewModel = incomeMatrixMap[IncomeTypeEnum.Overtime];
        overtimeIncome.preferredPaymentPeriodId = PeriodTypeEnum.Annually;
        overtimeIncome.amount = cvEmployment.income.annualOvertime;

        const bonusesIncome: IIncomeInfoViewModel = incomeMatrixMap[IncomeTypeEnum.Bonuses];
        bonusesIncome.preferredPaymentPeriodId = PeriodTypeEnum.Annually;
        bonusesIncome.amount = cvEmployment.income.annualBonus;

        const commissionsIncome: IIncomeInfoViewModel = incomeMatrixMap[IncomeTypeEnum.Commissions];
        commissionsIncome.preferredPaymentPeriodId = PeriodTypeEnum.Annually;
        commissionsIncome.amount = cvEmployment.income.annualCommission;
      } else if (cvEmployment.cvIncomeTypeId === CPOSIncomeTypeEnum.SelfEmployed) {
        loanEmployment.employmentTypeId = EmploymentTypeEnum.SelfEmployed;

        /**
         * Fill out self-employment-specific properties
         */

        loanEmployment.name = cvEmployment.employerInfo.name;
        loanEmployment.address.streetName = cvEmployment.employerInfo.address;
        loanEmployment.address.cityName = cvEmployment.employerInfo.city;
        loanEmployment.address.stateName = cvEmployment.employerInfo.state;
        loanEmployment.address.zipCode = cvEmployment.employerInfo.zip;
        loanEmployment.businessPhone = cvEmployment.employerInfo.phone;
        loanEmployment.address.unitNumber = cvEmployment.employerInfo.unitNumber;

        loanEmployment.positionDescription = cvEmployment.positionInfo.title;
        loanEmployment.employmentStartDate = adjustDate(cvEmployment.positionInfo.dateStart);
        loanEmployment.employmentEndDate = adjustDate(cvEmployment.positionInfo.dateEnd);
        loanEmployment.yearsInThisProfession = cvEmployment.positionInfo.yearsInPosition
          ? +cvEmployment.positionInfo.yearsInPosition
          : null;
        loanEmployment.monthsInThisProfession = cvEmployment.positionInfo.monthsInPosition
          ? +cvEmployment.positionInfo.monthsInPosition
          : null;
        if (cvEmployment.isPrevious) {
          loanEmployment.ownershipInterestType = null;
        } else {
          loanEmployment.ownershipInterestType = cvEmployment.positionInfo.OwnershipInterestType;
        }

        // Self employment legal entity types are different from LC and needs to be mapped before adding to loan

        let cloverLegalEntityType: number = cvEmployment.employerInfo.cloverLegalEntityType;
        let cloverLegalEntityLLC: number = cvEmployment.employerInfo.cloverLegalEntityLLC;
        let legalEntityType: number;
        if (cloverLegalEntityType == SelfEmploymentCloverEntityTypeEnum.LLC) {
          switch (cloverLegalEntityLLC) {
            case SelfEmploymentCloverEntityTypeLLCEnum.CCorp:
              legalEntityType = SelfEmploymentEntityTypeEnum.LLCCCorp;
              break;
            case SelfEmploymentCloverEntityTypeLLCEnum.Partnership:
              legalEntityType = SelfEmploymentEntityTypeEnum.LLCPartnership;
              break;
            case SelfEmploymentCloverEntityTypeLLCEnum.SCorp:
              legalEntityType = SelfEmploymentEntityTypeEnum.LLCSCorp;
              break;
            case SelfEmploymentCloverEntityTypeLLCEnum.SoleProprietorship:
              legalEntityType = SelfEmploymentEntityTypeEnum.LLCSoleProprietorship;
              break;
          }
        } else if (cloverLegalEntityType == SelfEmploymentCloverEntityTypeEnum.CCorp) {
          legalEntityType = SelfEmploymentEntityTypeEnum.CCorp;
        } else if (cloverLegalEntityType == SelfEmploymentCloverEntityTypeEnum.Partnership) {
          legalEntityType = SelfEmploymentEntityTypeEnum.Partnership;
        } else if (cloverLegalEntityType == SelfEmploymentCloverEntityTypeEnum.SCorp) {
          legalEntityType = SelfEmploymentEntityTypeEnum.SCorp;
        } else if (cloverLegalEntityType == SelfEmploymentCloverEntityTypeEnum.SoleProprietorship) {
          legalEntityType = SelfEmploymentEntityTypeEnum.SoleProprietorship;
        }

        loanEmployment.legalEntityType = legalEntityType;

        /**
         * Adjust self employment income matrix
         */

        cvEmployment.income.incomeMatrix.forEach(income => {
          income.employmentInfoId = cvEmployment.employmentInfoId;
          income.borrowerId = cvEmployment.borrowerId;
          income.isRemoved = ![
            IncomeTypeEnum.Overtime,
            IncomeTypeEnum.Bonuses,
            IncomeTypeEnum.Commissions,
            IncomeTypeEnum.SelfEmployedIncome,
          ].includes(income.incomeTypeId);
        });
        const incomeMatrixMap = keyBy(cvEmployment.income.incomeMatrix, 'incomeTypeId');

        /**
         * Fill out self-employment-specific incomes
         */

        const baseIncome: IIncomeInfoViewModel = incomeMatrixMap[IncomeTypeEnum.SelfEmployedIncome];
        baseIncome.preferredPaymentPeriodId = cvEmployment.income.basePaymentPeriodId;
        baseIncome.amount =
          cvEmployment.income.isLoss && cvEmployment.income.baseAmount > 0
            ? -1 * cvEmployment.income.baseAmount
            : cvEmployment.income.baseAmount;
        baseIncome.hourPerWeek = cvEmployment.income.baseHoursPerWeek;
        if (baseIncome.preferredPaymentPeriodId === PeriodTypeEnum.Hourly) {
          baseIncome.payPerHour = cvEmployment.income.baseAmount;
        }

        const overtimeIncome: IIncomeInfoViewModel = incomeMatrixMap[IncomeTypeEnum.Overtime];
        overtimeIncome.preferredPaymentPeriodId = PeriodTypeEnum.Annually;
        overtimeIncome.amount = cvEmployment.income.annualOvertime;

        const bonusesIncome: IIncomeInfoViewModel = incomeMatrixMap[IncomeTypeEnum.Bonuses];
        bonusesIncome.preferredPaymentPeriodId = PeriodTypeEnum.Annually;
        bonusesIncome.amount = cvEmployment.income.annualBonus;

        const commissionsIncome: IIncomeInfoViewModel = incomeMatrixMap[IncomeTypeEnum.Commissions];
        commissionsIncome.preferredPaymentPeriodId = PeriodTypeEnum.Annually;
        commissionsIncome.amount = cvEmployment.income.annualCommission;
      } else if (cvEmployment.cvIncomeTypeId === CPOSIncomeTypeEnum.MilitaryPay) {
        loanEmployment.employmentTypeId = EmploymentTypeEnum.ActiveMilitaryDuty;

        /**
         * Fill out military-specific properties
         */
        loanEmployment.address.streetName = cvEmployment.employerInfo.address;
        loanEmployment.address.cityName = cvEmployment.employerInfo.city;
        loanEmployment.address.stateName = cvEmployment.employerInfo.state;
        loanEmployment.address.zipCode = cvEmployment.employerInfo.zip;
        loanEmployment.businessPhone = cvEmployment.employerInfo.phone;

        loanEmployment.branchOfService = '' + cvEmployment.positionInfo.branch;
        loanEmployment.positionDescription = cvEmployment.positionInfo.rank;
        loanEmployment.employmentStartDate = adjustDate(cvEmployment.positionInfo.dateStart);
        loanEmployment.employmentEndDate = adjustDate(cvEmployment.positionInfo.dateEnd);
        loanEmployment.yearsInThisProfession = cvEmployment.positionInfo.yearsInPosition
          ? +cvEmployment.positionInfo.yearsInPosition
          : null;
        loanEmployment.monthsInThisProfession = cvEmployment.positionInfo.monthsInPosition
          ? +cvEmployment.positionInfo.monthsInPosition
          : null;

        if (!cvEmployment.isPrevious) {
          if (cvEmployment.borrowerId === loanModel.transactionInfo.borrowers[0].borrowerId) {
            loanModel.transactionInfo.borrowers[0].expirationOfService = adjustDate(
              cvEmployment.positionInfo.expirationOfService,
            );
          } else {
            loanModel.transactionInfo.borrowers[1].expirationOfService = adjustDate(
              cvEmployment.positionInfo.expirationOfService,
            );
          }
        }

        /**
         * Adjust military income matrix
         */

        cvEmployment.income.incomeMatrix.forEach(income => {
          income.employmentInfoId = cvEmployment.employmentInfoId;
          income.borrowerId = cvEmployment.borrowerId;
          income.isRemoved = ![
            IncomeTypeEnum.MilitaryBasePay,
            IncomeTypeEnum.MilitaryClothesAllows,
            IncomeTypeEnum.MilitaryCombatPay,
            IncomeTypeEnum.MilitaryFlightPay,
            IncomeTypeEnum.MilitaryHazardPay,
            IncomeTypeEnum.MilitaryOverseasPay,
            IncomeTypeEnum.MilitaryPropPay,
            IncomeTypeEnum.MilitaryQuartersAllowance,
            IncomeTypeEnum.MilitaryRationsAllowance,
            IncomeTypeEnum.MilitaryVariableHousingAllowance,
          ].includes(income.incomeTypeId);
        });
        const incomeMatrixMap = keyBy(cvEmployment.income.incomeMatrix, 'incomeTypeId');

        /**
         * Fill out military-specific incomes
         */

        const baseIncome: IIncomeInfoViewModel = incomeMatrixMap[IncomeTypeEnum.MilitaryBasePay];
        baseIncome.preferredPaymentPeriodId = cvEmployment.income.basePaymentPeriodId;
        baseIncome.amount = cvEmployment.income.baseAmount;
        baseIncome.hourPerWeek = cvEmployment.income.baseHoursPerWeek;
        if (baseIncome.preferredPaymentPeriodId === PeriodTypeEnum.Hourly) {
          baseIncome.payPerHour = cvEmployment.income.baseAmount;
        }
      } else if (cvEmployment.cvIncomeTypeId === CPOSIncomeTypeEnum.PensionRetirement) {
        loanEmployment.employmentTypeId = EmploymentTypeEnum.Retired;

        /**
         * Fill out retirement-specific properties
         */

        loanEmployment.employmentStartDate = adjustDate(cvEmployment.income.retirementStartDate);
        loanEmployment.employmentEndDate = adjustDate(cvEmployment.income.retirementEndDate);
        loanEmployment.notes = cvEmployment.notes;

        /**
         * Adjust retirement income matrix
         */

        cvEmployment.income.incomeMatrix.forEach(income => {
          income.employmentInfoId = cvEmployment.employmentInfoId;
          income.borrowerId = cvEmployment.borrowerId;
          income.isRemoved = true;
        });

        /**
         * Fill out retirement-specific income
         * In this case, the income does not come from the income matrix, we have to look for
         * the RetirementPensionIncome type in "other" incomes
         */

        let loanIncome: IIncomeInfoViewModel = nonEmploymentIncomesMap[cvEmployment.incomeInfoId];
        if (!loanIncome) {
          loanIncome = cloneDeep(models['loan.transactionInfo.borrowers.incomes']);
          loanModel.transactionInfo.incomes.push(loanIncome);
        }

        /**
         * Fill out retirement-specific properties
         */

        loanIncome.incomeInfoId = cvEmployment.incomeInfoId;
        loanIncome.borrowerId = cvEmployment.borrowerId;
        loanIncome.incomeTypeId = IncomeTypeEnum.RetirementPensionIncome;
        loanIncome.preferredPaymentPeriodId = PeriodTypeEnum.Monthly;
        loanIncome.amount = cvEmployment.income.monthlyPension;
      } else if (cvEmployment.cvIncomeTypeId === CPOSIncomeTypeEnum.NoIncome) {
        loanEmployment.employmentTypeId = EmploymentTypeEnum.OtherOrUnemployed;
      }

      /**
       * Reconcile current/previous employments
       */

      if (cvEmployment.isPrevious === true) {
        loanEmployment.isPresent = false;
        loanEmployment.employmentStatusId = EmploymentStatusTypeEnum.Previous;
        loanEmployment.isAdditional = true;
      } else {
        loanEmployment.isPresent = true;
        loanEmployment.employmentStatusId = EmploymentStatusTypeEnum.Current;

        if (borrowersWithCurrentEmployments.includes(cvEmployment.borrowerId)) {
          loanEmployment.isAdditional = true;
        } else {
          loanEmployment.isAdditional = false;
          if (!cvEmployment.forDelete) {
            borrowersWithCurrentEmployments.push(cvEmployment.borrowerId);
          }
        }
      }

      // Remove existing incomes for this employment
      // The income matrix was already created from these incomes in mapLoanToFormBuilder
      loanModel.transactionInfo.incomes = loanModel.transactionInfo.incomes.filter((income: IIncomeInfoViewModel) => {
        return income.employmentInfoId === loanEmployment.employmentInfoId ? false : true;
      });

      // Refresh this employment's income matrix, add to main incomes
      (<any>loanEmployment).incomeMatrix = cvEmployment.income.incomeMatrix;
      loanModel.transactionInfo.incomes = loanModel.transactionInfo.incomes.concat(cvEmployment.income.incomeMatrix);

      loanEmployment.isRemoved = false;
    } else if (
      // Incomes
      [CPOSIncomeTypeEnum.SocialSecurity, CPOSIncomeTypeEnum.OtherUnemployed].includes(cvEmployment.cvIncomeTypeId) ||
      cvEmployment.income.retirementIncomeOther
    ) {
      // Get current income from loan, or make default
      let loanIncome: IIncomeInfoViewModel = nonEmploymentIncomesMap[cvEmployment.incomeInfoId];
      if (!loanIncome) {
        loanIncome = cloneDeep(models['loan.transactionInfo.borrowers.incomes']);
        loanModel.transactionInfo.incomes.push(loanIncome);
      }

      // Carry-over existing ids
      loanIncome.incomeInfoId = cvEmployment.incomeInfoId;
      loanIncome.borrowerId = cvEmployment.borrowerId;

      if (cvEmployment.cvIncomeTypeId === CPOSIncomeTypeEnum.SocialSecurity) {
        loanIncome.incomeTypeId = IncomeTypeEnum.SocialSecurity;

        /**
         * Fill out social-security-specific properties
         */

        loanIncome.preferredPaymentPeriodId = PeriodTypeEnum.Monthly;
        loanIncome.amount = cvEmployment.income.monthlySocialSecurityPay;
      } else if (cvEmployment.cvIncomeTypeId === CPOSIncomeTypeEnum.OtherUnemployed) {
        loanIncome.incomeTypeId = cvEmployment.income.otherIncomeTypeId;

        /**
         * Fill out other-income-specific properties
         */

        loanIncome.preferredPaymentPeriodId = cvEmployment.income.otherPaymentPeriodId;
        loanIncome.amount = cvEmployment.income.otherIncomeAmount;

        if (cvEmployment.income.otherIncomeTypeId === IncomeTypeEnum.Other) {
          loanIncome.description = cvEmployment.income.otherIncomeDescription;
        } else if (
          urla !== URLAFormTypeEnum.URLA2020 &&
          cvEmployment.income.otherIncomeTypeId === IncomeTypeEnum.DividendsInterest
        ) {
          loanIncome.preferredPaymentPeriodId = PeriodTypeEnum.Annually;
        } else if (cvEmployment.income.otherIncomeTypeId === IncomeTypeEnum.DividendsInterest) {
          loanIncome.preferredPaymentPeriodId = PeriodTypeEnum.Annually;
        } else {
          loanIncome.description = null;
        }
      } else if (cvEmployment.cvIncomeTypeId === CPOSIncomeTypeEnum.PensionRetirement) {
        loanIncome.incomeTypeId = IncomeTypeEnum.RetirementPensionIncome;
        loanIncome.preferredPaymentPeriodId = PeriodTypeEnum.Monthly;
        loanIncome.amount = cvEmployment.income.monthlyPension;
      }

      loanIncome.isRemoved = false;
    }
  });

  // Create a string array of employments to delete
  const employmentsToDelete = loanModel.$$custom.loan.employments
    .filter(employment => (employment.employmentInfoId !== null && employment.forDelete ? true : false))
    .map(employment => employment.employmentInfoId);

  // Create a string array of incomes to delete
  const incomesToDelete = loanModel.$$custom.loan.employments
    .filter(employment => (employment.incomeInfoId !== null && employment.forDelete ? true : false))
    .map(employment => employment.incomeInfoId);

  // Mark employments for removal
  loanModel.transactionInfo.employments.forEach(employment => {
    if (employmentsToDelete.includes(employment.employmentInfoId)) {
      employment.isRemoved = true;
    }
  });

  // Mark incomes for removal
  loanModel.transactionInfo.incomes.forEach(incomes => {
    if (incomesToDelete.includes(incomes.incomeInfoId) || employmentsToDelete.includes(incomes.employmentInfoId)) {
      incomes.isRemoved = true;
    }
  });

  /**
  // Now remove those employments
  loanModel.transactionInfo.employments = loanModel.transactionInfo.employments.filter(
    employment => !employmentsToDelete.includes(employment.employmentInfoId),
  );

  // Now remove those remove associated incomes
  loanModel.transactionInfo.incomes = loanModel.transactionInfo.incomes.filter(
    incomes => !employmentsToDelete.includes(incomes.employmentInfoId),
  );
   */

  // console.log('cvEmployments', loanModel.$$custom.loan.employments);
  // console.log('F2L Employments', loanModel.transactionInfo.employments);
  // console.log('F2L Incomes', loanModel.transactionInfo.incomes);
}

function addOtherCvEmploymentIfNeeded(
  borrowerId: string,
  cvEmployments: ICPOSEmployment[],
  models: { [key: string]: any },
) {
  const borrowerOtherCvEmployments = cvEmployments.filter((e: ICPOSEmployment) => {
    return (
      e.borrowerId === borrowerId &&
      [CPOSIncomeTypeEnum.SocialSecurity, CPOSIncomeTypeEnum.OtherUnemployed].includes(e.cvIncomeTypeId)
    );
  });

  const hasOtherCvEmployment = borrowerOtherCvEmployments.some((e: ICPOSEmployment) => {
    return e.forDelete !== true;
  });

  if (!hasOtherCvEmployment) {
    return;
  }

  const hasRealCvEmployment = cvEmployments.some((e: ICPOSEmployment) => {
    return (
      e.borrowerId === borrowerId &&
      e.forDelete !== true &&
      [
        CPOSIncomeTypeEnum.Employed,
        CPOSIncomeTypeEnum.SelfEmployed,
        CPOSIncomeTypeEnum.MilitaryPay,
        CPOSIncomeTypeEnum.PensionRetirement,
        CPOSIncomeTypeEnum.NoIncome,
      ].includes(e.cvIncomeTypeId)
    );
  });

  if (hasRealCvEmployment) {
    return;
  }

  // Create a new "other" loan employment, use the first "other" income id

  const cvEmployment: ICPOSEmployment = cloneDeep(models['loan.$$custom.loan.employments']);
  cvEmployment.cvIncomeTypeId = CPOSIncomeTypeEnum.NoIncome;
  cvEmployment.employmentInfoId = borrowerOtherCvEmployments[0].employmentInfoId;
  cvEmployment.borrowerId = borrowerId;
  cvEmployment.isPrevious = false;
  cvEmployment.forDelete = false;

  cvEmployments.push(cvEmployment);
}

/* if the credit auth event was triggered, record credit authorization */
function mapCreditAuth(loanModel: ILoanViewModel) {
  if (!loanModel.$$custom.recordCreditAuth) {
    return;
  }

  // for the next save, set this so credit auth is logged, following saves keep this as false
  loanModel.transactionInfo.loanApplications[0].isNewCreditAuthorizationDateSet = true;
}

function adjustDob(date: string) {
  if (!date) {
    return date;
  }

  const dateParts = date.split('-');
  return dateParts[1] + '/' + dateParts[2] + '/' + dateParts[0];
}

function adjustDate(date: string) {
  return date;
}
