import { environment } from '$env';
import { StringUtils } from '$utils';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { isDefined } from './utils/data-validation-util';
import { constants } from './global';

// Enum of app setting properties. Only needed if using the propGet and propSet methods in this file
export enum AppSettingsProps {
  token = 'token',
  mfatoken = 'mfatoken',
  apiUrl = 'apiUrl',
  userName = 'userName',
  userFullName = 'userFullName',
  branchId = 'branchId',
  config = 'config',
  ui = 'ui',
  version = 'version',
  loanId = 'loanId',
  loanApplicationId = 'loanApplicationId',
  userId = 'userId',
  loUserId = 'loUserId',
  loUserIdOriginal = 'loUserIdOriginal',
  temporaryUserId = 'temporaryUserId',
  lsid = 'lsid',
  isDefaultCorporateLeadSource = 'isDefaultCorporateLeadSource',
  borrowerId = 'borrowerId',
  clientId = 'clientId',
  clientName = 'clientName',
  hasSeenNewDesignModal = 'hasSeenNewDesignModal',
  canSaveAppState = 'canSaveAppState',
  mixpanelKey = 'mixpanelKey',
  viewedAppraisalDocIds = 'viewedAppraisalDocIds',
  landingUrl = 'landingUrl',
  urla = 'urla',
  secureLinkId = 'secureLinkId',
  primaryAppSixPieces = 'primaryAppSixPieces',
  cookieDomain = 'cookieDomain'
}

type Propkey = keyof typeof AppSettingsProps;

// Getter/setters for app settings. Will read/write to app settings and on app load will try to reload from localstorage/sessionstorage
@Injectable()
export class AppSettings {
  /** Property to store app settings in local or session storage */
  private localProp = 'settings-app-' + environment.properties.appName.length;
  /** If obfusicated, pad settings with this many characters */
  private pad = 100;
  private localStorage: { [key in AppSettingsProps]?: string } = {};
  private sessionStorage: { [key in AppSettingsProps]?: string } = {};
  /** Property to store app settings in local or session storage */
  public isBrowser = isPlatformBrowser(this.platformId);
  /** API token */
  private _token: string | null = null;
  /** Loan ID */
  private _loanId: string | null = null;
  /** Loan Application ID */
  private _loanApplicationId: string | null = null;
  /** User ID */
  private _userId: string | null = null;
  /** User ID */
  private _loUserId: string | null = null;
  /** User ID */
  private _loUserIdOriginal: string | null = null;
  /** Temporary User ID */
  private _lsidUserId: string | null = null;
  /** UserName */
  private _userName: string | null = null;
   /** UserName */
   private _userFullName: string | null = null;
  /** LSID */
  private _lsid: string | null = null;
  /** LSID */
  private _isDefaultCorporateLeadSource: boolean | null = null;
  /** Borrower ID */
  private _borrowerId: string | null = null;
  /** API token */
  private _ui: string | null = null;
  /** User */
  private _version: string | null = null;
  /** Brand styling */
  private _config: Record<string, any> | null = null;
  /** Which client ID */
  private _branchId: string | null = null;
  /** URL of web api */
  private _apiUrl: string | null = null;
  /** The id of the client using this app */
  private _clientId: string | null = null;
  /** The name of the client using this app */
  private _clientName: string | null = null;
  /** Used after initial launch of the app to show a new design modal */
  private _hasSeenNewDesignModal: string | null = null;
  /** Determines if app state API can be called */
  private _canSaveAppState: boolean | null = null;
  /** Mixpanel key to identify prod or lower environments */
  private _mixpanelKey: string | null = null;
  /** Mixpanel key to identify prod or lower environments */
  private _urla:  number;
  /** Local cache of viewed Appraisal documents */
  private _viewedAppraisalDocIds: string[] = null;
  /** When user lands on the Clover*/
  private _landingUrl: string | null = null;
  /** Determine if the email page and security question page should be shown or not */
  private _coborrowerAccount: boolean | null = null;
  /** Secure link */
  private _secureLinkId: string | null = null;
  /** Primary App Six Pieces **/
  private _primaryAppSixPieces: boolean | null = null;
  /** The domain name used by iMP to set and read cookies */
  private _cookieDomain: string | null = null;

  /** Used by the error interceptor to pass global error messages to app.component.html */
  public error$ = new BehaviorSubject<string>(null);

   /** Mixpanel Key */
   public get mixpanelKey(): string | null {
    return this._mixpanelKey || this.propGet(AppSettingsProps.mixpanelKey);
  }
  public set mixpanelKey(value: string | null) {
    this._mixpanelKey = value;
    this.propSet(AppSettingsProps.mixpanelKey, value);
  }

  /** Urla version */
  public get urla(): number {
    return this._urla != null ? this._urla : JSON.parse(this.propGet(AppSettingsProps.urla));
  }
  public set urla(value: number) {
    this._urla = value;
    this.propSet(AppSettingsProps.urla, JSON.stringify(value));
  }

  /** Primary Application Six Pieces */
  public get primaryAppSixPieces(): boolean | null {
    return this._primaryAppSixPieces != null ? this._primaryAppSixPieces : JSON.parse(this.propGet(AppSettingsProps.primaryAppSixPieces));
  }

  public set primaryAppSixPieces(value: boolean) {
    this._primaryAppSixPieces = value;
    this.propSet(AppSettingsProps.primaryAppSixPieces, JSON.stringify(value));
  }

  /** UI State */
  public get ui(): string | null {
    return this._ui || this.propGet(AppSettingsProps.ui);
  }
  public set ui(value: string | null) {
    this._ui = value;
    this.propSet(AppSettingsProps.ui, value);
  }
  /** Get the UI state directly from localstorage and not from memory */
  public uiState() {
    // Reload session and localstorage
    this.sessionStorage = this.settingsRestore(window.sessionStorage.getItem(this.localProp));
    this.localStorage = this.settingsRestore(window.localStorage.getItem(this.localProp));
    return this.propGet(AppSettingsProps.ui);
  }

  /** Branch Id */
  public get branchId(): string | null {
    return this._branchId || this.propGet(AppSettingsProps.branchId);
  }
  public set branchId(value: string | null) {
    this._branchId = value;
    this.propSet(AppSettingsProps.branchId, value);
  }

  /** Configuration */
  public get config(): Record<string, any> | null {
    if (this._config) return this._config;
    const config = this.propGet(AppSettingsProps.config);
    try {
      return JSON.parse(config);
    } catch (e) {
      // stored config was prior to settings typing, reset it
      this.propSet(AppSettingsProps.config, null);
      return null;
    }
  }
  public set config(value: Record<string, any> | null) {
    this._config = value;
    this.propSet(AppSettingsProps.config, JSON.stringify(value));
  }

  public get serviceAccountId() {
    return isDefined(this.config) ? this.config[constants.serviceaccountid].value ? this.config[constants.serviceaccountid].value : this.config[constants.serviceaccountid].defaultValue : -1;
  }

  /** API URL location */
  public get apiUrl(): string | null {
    return this._apiUrl || this.propGet(AppSettingsProps.apiUrl);
  }
  public set apiUrl(value: string | null) {
    this._apiUrl = value;
    this.propSet(AppSettingsProps.apiUrl, value);
  }

  /** API token */
  public get token(): string | null {
    return this._token || this.propGet(AppSettingsProps.token, 'sessionStorage');
  }
  public set token(value: string | null) {
    this._token = value;
    this.propSet(AppSettingsProps.token, value, 'sessionStorage');
  }

  /** Loan ID */
  public get loanId(): string | null {
    return this._loanId || this.propGet(AppSettingsProps.loanId);
  }
  public set loanId(value: string | null) {
    this._loanId = value;
    this.propSet(AppSettingsProps.loanId, value);
  }

  /** Loan ID */
  public get loanApplicationId(): string | null {
    return this._loanApplicationId || this.propGet(AppSettingsProps.loanApplicationId);
  }
  public set loanApplicationId(value: string | null) {
    this._loanApplicationId = value;
    this.propSet(AppSettingsProps.loanApplicationId, value);
  }

  /** User ID */
  public get userId(): string | null {
    return this._userId || this.propGet(AppSettingsProps.userId);
  }
  public set userId(value: string | null) {
    this._userId = value;
    this.propSet(AppSettingsProps.userId, value);
  }

  /** Loan officer User ID */
  public get loUserId(): string | null {
    return this._loUserId || this.propGet(AppSettingsProps.loUserId);
  }
  public set loUserId(value: string | null) {
    this._loUserId = value;
    this.propSet(AppSettingsProps.loUserId, value);
  }

  /** Original Loan officer User ID on login */
  public get loUserIdOriginal(): string | null {
    return this._loUserIdOriginal || this.propGet(AppSettingsProps.loUserIdOriginal, 'sessionStorage');
  }
  public set loUserIdOriginal(value: string | null) {
    this._loUserIdOriginal = value;
    this.propSet(AppSettingsProps.loUserIdOriginal, value, 'sessionStorage');
  }

  /** Temporary User ID used for registering new accounts */
  public get lsidUserId(): string | null {
    return this._lsidUserId || this.propGet(AppSettingsProps.temporaryUserId);
  }
  public set lsidUserId(value: string | null) {
    this._lsidUserId = value;
    this.propSet(AppSettingsProps.temporaryUserId, value);
  }

  /** UserName */
  public get userName(): string | null {
    return this._userName || this.propGet(AppSettingsProps.userName);
  }
  public set userName(value: string | null) {
    this._userName = value;
    this.propSet(AppSettingsProps.userName, value);
  }

  /** UserFullName */
  public get userFullName(): string | null {
    return this._userFullName || this.propGet(AppSettingsProps.userFullName);
  }
  public set userFullName(value: string | null) {
    this._userFullName = value;
    this.propSet(AppSettingsProps.userFullName, value);
  }

  /** LSID */
  public get lsid(): string | null {
    return this._lsid || this.propGet(AppSettingsProps.lsid);
  }
  public set lsid(value: string | null) {
    this._lsid = value;
    this.propSet(AppSettingsProps.lsid, value);
  }

  /** LSID */
  public get isDefaultCorporateLeadSource(): boolean | null {
    return this._isDefaultCorporateLeadSource != null
      ? this._isDefaultCorporateLeadSource
      : JSON.parse(this.propGet(AppSettingsProps.isDefaultCorporateLeadSource));
  }
  public set isDefaultCorporateLeadSource(value: boolean | null) {
    this._isDefaultCorporateLeadSource = value;
    this.propSet(AppSettingsProps.isDefaultCorporateLeadSource, JSON.stringify(value));
  }

  /** Borrower ID */
  public get borrowerId(): string | null {
    return this._borrowerId || this.propGet(AppSettingsProps.borrowerId);
  }
  public set borrowerId(value: string | null) {
    this._borrowerId = value;
    this.propSet(AppSettingsProps.borrowerId, value);
  }

  /** Client Id */
  public get clientId(): string | null {
    return this._clientId || this.propGet(AppSettingsProps.clientId);
  }
  public set clientId(value: string | null) {
    this._clientId = value;
    this.propSet(AppSettingsProps.clientId, value);
  }

  /** Client Name */
  public get clientName(): string | null {
    return this._clientName || this.propGet(AppSettingsProps.clientName);
  }
  public set clientName(value: string | null) {
    this._clientName = value;
    this.propSet(AppSettingsProps.clientName, value);
  }

  /** Should show the new design modal */
  public get hasSeenNewDesignModal(): string | null {
    return this._hasSeenNewDesignModal || this.propGet(AppSettingsProps.hasSeenNewDesignModal);
  }
  public set hasSeenNewDesignModal(value: string | null) {
    this._hasSeenNewDesignModal = value;
    this.propSet(AppSettingsProps.hasSeenNewDesignModal, value);
  }

  /** Determines if app state API can be called */
  public get canSaveAppState(): boolean | null {
    return this._canSaveAppState != null
      ? this._canSaveAppState
      : JSON.parse(this.propGet(AppSettingsProps.canSaveAppState));
  }
  public set canSaveAppState(value: boolean | null) {
    this._canSaveAppState = value;
    this.propSet(AppSettingsProps.canSaveAppState, JSON.stringify(value));
  }

  /** App version */
  public get version(): string | null {
    return this._version || this.propGet(AppSettingsProps.version);
  }
  public set version(value: string | null) {
    this._version = value;
    this.propSet(AppSettingsProps.version, value);
  }

  public get coborrowerAccount(): boolean | null {
    return this._coborrowerAccount;
  }
  public set coborrowerAccount(value: boolean | null) {
    this._coborrowerAccount = value;
  }

  /** App version */
  public get viewedAppraisalDocIds(): string[] {
    return this._viewedAppraisalDocIds || JSON.parse(this.propGet(AppSettingsProps.viewedAppraisalDocIds)) || [];
  }
  public set viewedAppraisalDocIds(value: string[]) {
    this._viewedAppraisalDocIds = value;
    this.propSet(AppSettingsProps.viewedAppraisalDocIds, JSON.stringify(value));
  }

  public get landingUrl(): string | null {
    return this._landingUrl || this.propGet(AppSettingsProps.landingUrl);
  }

  public set landingUrl(url: string | null) {
    this._landingUrl = url;
    this.propSet(AppSettingsProps.landingUrl, url);
  }

  public get secureLinkId(): string | null {
    return this._secureLinkId || this.propGet(AppSettingsProps.secureLinkId, "sessionStorage");
  }

  public set secureLinkId(val: string | null) {
    this._secureLinkId = val;
    this.propSet(AppSettingsProps.secureLinkId, val, "sessionStorage");
  }

  public get cookieDomain(): string | null {
    return this._cookieDomain || this.propGet(AppSettingsProps.cookieDomain);
  }

  public set cookieDomain(value: string | null) {
    this._cookieDomain = value;
    this.propSet(AppSettingsProps.cookieDomain, value);
  }

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (environment.production) {
      if (this.isBrowser && window.sessionStorage.getItem(this.localProp)) {
        this.sessionStorage = this.settingsRestore(window.sessionStorage.getItem(this.localProp));
      }
      if (this.isBrowser && window.localStorage.getItem(this.localProp)) {
        this.localStorage = this.settingsRestore(window.localStorage.getItem(this.localProp));
      }
    } else {
      this.sessionStorage = {};
      this.localStorage = {};
    }
  }

  /**
   * Clear session storage
   */
  public sessionStorageClear() {
    if (this.isBrowser) {
      window.sessionStorage.clear();
    }
  }

  /**
   * Return a property. Loads it from this service first if available, if not looks in localstorage, if not there either return null
   * @param prop - App settings property
   * @param location - Location of locally stored prop, either sessionStorage or localStorage
   */
  private propGet(propKey: Propkey, location: 'localStorage' | 'sessionStorage' = 'localStorage') {
    if (this.isBrowser) {
      try {
        if (location === 'sessionStorage' && this.sessionStorage[propKey]) {
          return this.sessionStorage[propKey];
        } else if (this.localStorage[propKey]) {
          return this.localStorage[propKey];
        }
      } catch (err) {
        console.log(err);
        window.sessionStorage.clear();
        window.localStorage.clear();
      }
    }
    return null;
  }

  /**
   * Set an app settings property. Write to localstorage if present, delete from localstorage if null
   * @param prop - App settings property
   * @param location - Location of locally stored prop, either sessionStorage or localStorage
   */
  private propSet(
    propKey: Propkey,
    value: string | null,
    location: 'localStorage' | 'sessionStorage' = 'localStorage',
  ) {
    if (this.isBrowser) {
      if (location === 'sessionStorage') {
        this.sessionStorage[propKey] = value;
        try {
          window.sessionStorage.setItem(this.localProp, this.settingsSave(this.sessionStorage));
        } catch (err) {
          console.log(err);
        }
      } else {
        this.localStorage[propKey] = value;
        try {
          window.localStorage.setItem(this.localProp, this.settingsSave(this.localStorage));
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  /**
   * Return an object that has been obfuscated into a string
   * @param state
   */
  private settingsSave(state: Object) {
    try {
      if (state) {
        let str = JSON.stringify(state);
        if (environment.settings.obfuscate) {
          str = StringUtils.pad(str, this.pad, this.pad);
          str = StringUtils.obfuscateAdd(str);
        }
        return str;
      }
    } catch (err) {
      return null;
    }
  }

  /**
   * Return an object that has been de-obfuscated
   * @param state
   */
  private settingsRestore(state: string) {
    try {
      if (state) {
        let str = state;
        if (environment.settings.obfuscate) {
          str = StringUtils.obfuscateRemove(state);
          str = StringUtils.trim(str, this.pad, this.pad);
        }
        const obj = JSON.parse(str);
        return obj;
      }
    } catch (err) {
      return null;
    }
  }
}
