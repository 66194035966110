


import { IPropertyViewModel } from 'src/app/shared/models';

/**
 * Normalize a string for use in comparison
 * @param part
 */
export const normalizeString = (part: string) => {
  if (part === null) {
    return part;
  }

  return String(part)
    .toLowerCase()
    .trim()
    .replace(/[\s]{2,}|[\t]/g, ' ')
    .replace(/[.,\-'"#]/g, '');
};

/**
 * Normalize a street address by standardizing common spellings and abbreviations
 * @param address 
 */
export const normalizeStreetAddress = (address: string) => {
  // Replacements array, the first value will be replaced by the second value
  // tslint:disable-next-line:max-line-length
  const replacements = [
    ['apartment', 'apt'],
    ['avenue', 'ave'],
    ['boulevard', 'blvd'],
    ['building', 'bldg'],
    ['center(s)', 'ctr'],
    ['circle(s)', 'cir'],
    ['court', 'ct'],
    ['drive', 'dr'],
    ['east', 'e'],
    ['expressway', 'expy'],
    ['extension', 'ext'],
    ['fort', 'ft'],
    ['freeway', 'fwy'],
    ['height(s)', 'hts'],
    ['highway', 'hwy'],
    ['island', 'is'],
    ['junction', 'jct'],
    ['lane', 'ln'],
    ['mount(ain)', 'mt'],
    ['north', 'n'],
    ['northeast', 'ne'],
    ['northwest', 'nw'],
    ['parkway', 'pky'],
    ['place', 'pl'],
    ['post office', 'po'],
    ['road', 'rd'],
    ['rural delivery', 'rd'],
    ['rural route', 'rr'],
    ['saint', 'st'],
    ['south', 's'],
    ['southeast', 'se'],
    ['southwest', 'sw'],
    ['spring', 'spg'],
    ['springs', 'spgs'],
    ['square(s)', 'sq'],
    ['street', 'st'],
    ['suite', 'ste'],
    ['terrace', 'ter'],
    ['turnpike', 'tpke'],
    ['west', 'w']
  ];

  // Normalize the address
  let addressNormalized = normalizeString(address).replace(/ /g, '');
  // Loop through the replacements array and replace any matches
  replacements.forEach(x => addressNormalized = addressNormalized.replace(new RegExp(x[0], 'g'), x[1]));
  return addressNormalized;
};

/**
 * Check if 2 addresses match by normalizing address parts
 * @param address1
 * @param address2
 */
export const areAddressesEqual = (address1: IPropertyViewModel, address2: IPropertyViewModel) => {
  return (
    normalizeString(address1.streetName) === normalizeString(address2.streetName) &&
    normalizeString(address1.unitNumber) === normalizeString(address2.unitNumber) &&
    normalizeString(address1.cityName) === normalizeString(address2.cityName) &&
    normalizeString(address1.stateName) === normalizeString(address2.stateName) &&
    normalizeString(address1.zipCode) === normalizeString(address2.zipCode)
  );
};
