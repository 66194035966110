import { Component } from '@angular/core';

/**
 * This component can be used for anything. It should remain entirely empty.
 * It was originally created as a placeholder component for allowing route
 * guards (like the Dashboard Route Guard) to redirect while preserving
 * query parameters.
 */

@Component({
  selector: 'app-dummy-component',
  template: ``,
})
export class DummyComponent {}
