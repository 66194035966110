import { local } from './environment.local';

// Common env settings that don't change between dev and prod
export const common: any = {
  ...local,
  properties: {
    /** Name of application */
    appName: 'Clover',
  },
  settings: {
    /** Google Maps API Key */
    googleMapsKey: 'AIzaSyDwOKPEw8427RRLT8OpBaTKLkTdazi87v8',
    /** Is an authentication endpoint available? If so make sure to update the endpoints in this file */
    enableAuth: true,
    /** Enable service worker functionality */
    enableServiceWorker: false,
    /** Is this app going to communicate with other domains or instances of itself for multiscreen usage?
     * If so, whitelist domains in the domains.listenTo property */
    enableAppComms: true,
    /** Should lazy loaded routes be preloaded on app instantiation? If false will be loaded on demand */
    preloadRoutes: false,
    /** Should data that is written to localstorage (such as app settings and store state) be obfuscated? */
    obfuscate: true,
    ...local.settings,
  },
  domains: {
    /** If App Comms is enabled, whitelist domains to accept messages from here */
    listenTo: <string[]>null,
  },
  endpoints: {
    /** Location to get environment and config settings */
    envConfig: 'env.json',
    /** Fetch current client ud */
    client: '/api/UserService/GetLeadSourceBasicInfoByUrl',
    /** This endpoint supplies client specific configuration such as css styling, 1003 configuration, etc */
    config: '/api/Configuration/ConsumerSiteConfiguration',
    /** Location of API if not getting that from envConfig */
    apiUrl: 'https://dev20loancenter.cloudvirga.com', //'http://localhost:1932',
    /** Login endpoint */
    authLogin: '/SecurityService/GrantOauthTokenForConsumer',
    /** Login MFA endpoint */
    authLoginMfa: '/SecurityService/GrantOauthTokenForConsumerMfa',
    /** Request new MFA endpoint */
    authRequestNewMfa: '/SecurityService/RequestConsumerMfaToken',
    /** Refresh token endpoint */
    authTokenRefresh: '/authentication/token',
    /** Log all the things */
    logging: '/api/log/bulk',
    /** Api version endpoint. If not null then the app will request an update when the version changes */
    // version: '/version',
    /** Log front-end errors to here. Used by error.intercepter */
    // errors: 'webapp/log',
    // Localized
    ...local.endpoints,
  },
  state: {
    /** Which UI store properties to not write to localstorage. IE do not persist confidential/personal information */
    uiStoreBlacklist: <string[]>[],
  },
  licenses: {
  },
};
