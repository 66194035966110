import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface HintData {
  title?: string;
  body?: string;
}

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HintModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: HintData, // @Inject(MAT_DIALOG_DATA) public dataAlt: any,
  ) {}

  ngOnInit() {
  }

  /**
   * Submit the form
   */
  public submit() {
    this.dialogRef.close();
  }
}
