export enum CPOSProgressEnum {
  form1003 = 'form1003', // User is on 1003
  dashboard = 'dashboard', // User is on dashboard/ bnl
  complete = 'complete', // App is complete
}

export enum CPOSIncomeTypeEnum {
  NotSpecified = -1,
  Employed = 0,
  MilitaryPay = 1,
  SocialSecurity = 2,
  PensionRetirement = 3,
  SelfEmployed = 4,
  OtherUnemployed = 5,
  NoIncome = 99,
}

export enum CPOSClientLogLevelEnum {
  Default = 0,
  Trace,
  Debug,
  Info,
  Warn,
  Error,
  Fatal,
}

export enum CPOSCreditVendorEnum {
  experian = 'experian',
  equifax = 'equifax',
  transunion = 'transunion',
}

export enum CPOSNavigationPathEnum {
  'my-loans' = 'my-loans',
  'my-account' = 'my-account',
}

export enum CPOSPageIdEmum {
  'property-located' = 'property-located',
  'looking-to-buy' = 'looking-to-buy',
  'start' = 'start',
  'summary' = 'summary',
}

export enum CPOSsectionIdsEnum {
  'loan-purpose' = 'loan-purpose',
  'personal' = 'personal',
  'property' = 'property',
  'income' = 'income',
  'assets' = 'assets',
  'declarations' = 'declarations',
  'demographics' = 'demographics',
  'credit' = 'credit',
  'review' = 'review',
}

export enum CPOSPageValidatorsEnum {
  'propertyValidation' = 'propertyValidation',
  'assets' = 'assets',
  'joinCredit' = 'joinCredit',
  'joinCredit2' = 'joinCredit2',
  'hasAtLeastOne' = 'hasAtLeastOne',
  'hasAtLeastOneAlimony' = 'hasAtLeastOneAlimony',
  'hasAtLeastOneAlimonySpouse' = 'hasAtLeastOneAlimonySpouse',
  'declarations' = 'declarations',
  'tcpaDisclaimer' = 'tcpaDisclaimer',
  'checkboxAnyValue' = 'checkboxAnyValue',
  'creditPull' = 'creditPull',
  'creditPullOptional' = 'creditPullOptional',
  'creditPullSpouse' = 'creditPullSpouse',
  'creditPullSpouseOptional' = 'creditPullSpouseOptional',
  'purchaseDate' = 'purchaseDate',
  'startDateEndDate' = 'startDateEndDate',
  'pension' = 'pension',
  'ssnEvoiEvoe' = 'ssnEvoiEvoe',
  'compareDates' = 'compareDates',
  'borrowerPrimaryMilitaryServiceType' = 'borrowerPrimaryMilitaryServiceType',
  'borrowerSecondaryMilitaryServiceType' = 'borrowerSecondaryMilitaryServiceType',
  'pudValidation' = 'pudValidation',
}

export enum CPOSEventsEnum {
  'timeAtAddressPrimary' = 'timeAtAddressPrimary',
  'timeAtAddressSecondary' = 'timeAtAddressSecondary',
  'incomeStart' = 'incomeStart',
  'incomeSummary' = 'incomeSummary',
  'creditAuthorized' = 'creditAuthorized',
  'maritalStatus' = 'maritalStatus',
  'applyingWithCoborrower' = 'applyingWithCoborrower',
  'occupyOrUse' = 'occupyOrUse',
  'militaryService' = 'militaryService',
  'militaryStatus' = 'militaryStatus'
}

export enum CPOSdateTypeEnum {
  'start' = 'start',
  'end' = 'end',
  'projectedEndOfService' = 'projectedEndOfService',
  'dob' = 'dob',
  'originalPurchaseDate' = 'originalPurchaseDate',
  'appraisal' = 'appraisal',
  'incomeEndDate' = 'incomeEndDate'
}

export enum CPOSDisableCustomEnum {
  'checkEmploymentCurrentWorkDisabled' = 'checkEmploymentCurrentWorkDisabled'
}
