import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

/**
 * Add guids to any models missing them in the loan model
 * This is necessary because the backend needs the front-end to assign them or it dies a miserable death
 * This method is intended to check to ensure all models have the guid and if not adds them once time only
 * They are added to the form control to ensure that new guids aren't generated on every save
 * @param loan
 * @param guids
 */
export const guidsAdd = (loanModel: AbstractControl, guids: string[]) => {
  // console.log('guidsAdd', loanModel, guids);

  // Create array of form arrays with their unique ID as the key
  const assignGuidsTo: { [key: string]: FormArray }[] = [
    {
      propertyId: <FormArray>loanModel.get('properties'),
    },
    {
      assetId: <FormArray>loanModel
        .get('$$custom')
        .get('loan')
        .get('assets'),
    }, // Assets are in custom array before being remapped
    {
      employmentInfoId: <FormArray>loanModel
        .get('$$custom')
        .get('loan')
        .get('employments'),
    }, // Employments are in custom array before being remapped
    {
      incomeInfoId: <FormArray>loanModel
        .get('$$custom')
        .get('loan')
        .get('employments'),
    }, // Incomes are in custom array before being remapped
    {
      borrowerId: <FormArray>loanModel.get('transactionInfo.borrowers'),
    },
    {
      recordId: <FormArray>loanModel.get('$$custom.agentLoanParticipant.phoneList.list'),
    },
    {
      recordId: <FormArray>loanModel.get('$$custom.agentLoanParticipant.emailAddressList.list'),
    },
    {
      costId: <FormArray>loanModel.get('closingCost.costs'),
    },
  ];

  // Non array properties that need guids
  const assignGuidsToStatic = [
    loanModel.get('$$custom.agentLoanParticipant.recordId'),
    loanModel.get('$$custom.agentLoanParticipant.parentId'),
    loanModel.get('$$custom.agentLoanParticipant.companyPhoneNumber.recordId'),
    loanModel.get('$$custom.alimonyEtc.alimonyMiscellaneousDebtId'),
    loanModel.get('$$custom.alimonyEtc.childSupportMiscellaneousDebtId'),
    loanModel.get('$$custom.alimonyEtc.seperateMiscellaneousDebtId'),
    loanModel.get('$$custom.alimonyEtc.alimonyMiscellaneousDebtIdSpouse'),
    loanModel.get('$$custom.alimonyEtc.childSupportMiscellaneousDebtIdSpouse'),
    loanModel.get('$$custom.alimonyEtc.seperateMiscellaneousDebtIdSpouse')
  ];

  assignGuidsToStatic.forEach(control => {
    const val = control.value;
    // If no guid or guid is empty string
    if (!val || val === '') {
      // Add guid, remove from array so it isn't reused
      control.patchValue(guids ? guids.shift() : Math.random());
    }
  });
 
  let propertiesFormArray = new FormArray([]);
  propertiesFormArray.push(
    loanModel
      .get('$$custom')
      .get('loan')
      .get('borrowerPrimary')
      .get('addressHistory'),
  );
  propertiesFormArray.push(
    loanModel
      .get('$$custom')
      .get('loan')
      .get('borrowerSecondary')
      .get('addressHistory'),
  );
  assignGuidsTo.push({ propertyId: propertiesFormArray });

  let assetsFormArray = new FormArray([]);
  assetsFormArray.push(
    loanModel
      .get('$$custom')
      .get('loan')
      .get('downPaymentAsset'),
  );
  assignGuidsTo.push({ assetId: assetsFormArray });

  // Add incomes from inside employments
  (<FormArray>loanModel
    .get('$$custom')
    .get('loan')
    .get('employments')).controls.forEach(formGroup => {
    assignGuidsTo.push({
      incomeInfoId: <FormArray>formGroup.get('income').get('incomeMatrix'),
    });
  });

  // Add all form arrays from borrowers
  (<FormArray>loanModel.get('transactionInfo').get('borrowers')).controls.forEach(control => {
    // Load phones
    assignGuidsTo.push({ phoneId: <FormArray>control.get('phones') });
    // Assets
    assignGuidsTo.push({ assetId: <FormArray>control.get('assets') });
    // Demographics
    assignGuidsTo.push({ hmdaCodeId: <FormArray>control.get('hmdaDemographics').get('sex') });
    assignGuidsTo.push({ hmdaCodeId: <FormArray>control.get('hmdaDemographics').get('races') });
  });

  // Loop through all form arrays needing to check for guids
  assignGuidsTo.forEach(element => {
    const key = Object.keys(element)[0];
    const controlsArray = element[key];
    if (Array.isArray(controlsArray.controls)) {
      // Loop through all the controls within each form array
      controlsArray.controls.forEach(control => {
        const controlSub = (<FormGroup>control).controls[key];
        if (controlSub) {
          const val = controlSub.value;
          // If no guid or guid is empty string
          if (!val || val === '') {
            // Add guid, remove from array so it isn't reused
            controlSub.patchValue(guids ? guids.shift() : Math.random());
          }
        }
      });
    } else {
      console.error('Invalid form array for adding guids');
    }
  });
};
