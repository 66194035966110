import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '$api';
import { ActivatedRoute } from '@angular/router';
import { AppSettings, AnalyticsService } from '$shared';
import { ILeadSourceInfoViewModel } from 'src/app/shared/models';

@Component({
  selector: 'app-broker',
  templateUrl: './broker.component.html',
  styleUrls: ['./broker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrokerComponent implements OnInit {

  public brokerInfo$ = this.api.select.broker$;

  /** Used in template to show loading state */
  isLoading = false;
  /** Show errors in the UI */
  error: string;

  constructor(
    private api: ApiService,
    private ref: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private settings: AppSettings,
    private analytics: AnalyticsService,
  ) {}

  ngOnInit() {
    this.setBrokenInfo();
  }

  setBrokenInfo(): void {

    const lsid = this.activatedRoute.snapshot.queryParams['lsid'] || this.settings.lsid || null;

    /** Show error if no LSID */
    if (!lsid) {
     return this.setError(`Could not get LSID. Please try again.`);
    }

    /** Get data from API */
    this.setLoading();

    this.api.broker.get(lsid).subscribe(
      apiResponse => {
        if (apiResponse) {
          if (apiResponse.brokerCompanyName) {
            // Update super props in analytics
            this.analytics.mixpanelSuperProps({
              'NMLS Number': apiResponse.nmlsNumber,
            });
          }
        } else {
          this.setError(`Could not load broker info. Please try again`);
        }
        this.setLoading(false);
      },
      () => {
        this.setError(`An error occurred while try get get broker info. Please try again.`);
        this.setLoading(false);
      },
    );
  }

  setLoading(isLoading = true): void {
    this.isLoading = isLoading;
    this.ref.markForCheck();
  }

  setError(error: string = null): void {
    this.error = error;
    this.ref.markForCheck();
  }

  /**
   * Open address in new window
   * @param broker
   */
  openAddress(broker: ILeadSourceInfoViewModel): boolean {
    if (!broker) return;
    let addressString = broker.brokerCompanyStreetAddress ? `${broker.brokerCompanyStreetAddress}` : '';
    addressString += broker.brokerCompanyCity ? ` ${broker.brokerCompanyCity}` : '';
    addressString += broker.brokerCompanyState ? ` ${broker.brokerCompanyState}` : '';
    addressString += broker.brokerCompanyZip ? ` ${broker.brokerCompanyZip}` : '';
    window.open(`https://www.google.com/maps/place/${encodeURI(addressString)}/`, '_blank');
    return false;
  }
}
