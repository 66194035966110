import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  OnChanges,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { SlugPipe } from '$shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  autoCompleteSuggest,
  isRequired,
  isBrowserChrome,
  isBrowserFirefox,
  isBrowserIE,
  isBrowserMobile,
  inputMaskCreate,
} from './utils/form-field.utils';
import { fromEvent } from 'rxjs';

export type ControlType = CvFormBuilder.FormFieldType;

/**
 * A component abstraction for mat-form-field. Adds better design and validation states
 * USAGE:
 * <app-form-field [formControlRef]="formLoan.controls.nameFirst" type="text" placeholder="First Name"></app-form-field>
 */
@UntilDestroy()
@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  /** Form Control reference */
  @Input() formControlRef: FormControl;
  /** Angular materials appearance type */
  @Input() appearance = 'legacy';
  /** Placeholder text */
  @Input() placeholder: string;
  /** Text to use for name and ID attribute */
  @Input() name: string;
  /** Any css classes */
  @Input() class = '';
  @Input() parentClass = '';
  /** Is disabled */
  @Input() disabled = false;
  /** Form field type */
  @Input() type: ControlType = 'text';
  /** If form field type is select, supply list of options */
  @Input() options: any[];
  /** The human readable label for an option */
  @Input() optionsLabel: string;
  /** The value for the option to supply to the form control */
  @Input() optionsValue: string;
  /** If field type is text area, use this many columns */
  @Input() rows = 4;
  /** Tooltip text */
  @Input() tooltip: string;
  /** Hint text */
  @Input() hint: string;
  /** HTML code to place in the prefix position in FRONT of the form field */
  @Input() prefix: string;
  /** HTML code to place in the suffix position in BACK of the form field */
  @Input() suffix: string;
  /** Show success icon when valid */
  @Input() showSuccess = false;
  /** Show error icon when invalid */
  @Input() showError = true;
  /** Show custom error message */
  @Input() errorCustom: false | string = false;
  /** Additional custom error messages */
  @Input() customMessages: any;
  /** If NUMBER, the max value allowed */
  @Input() max: number;
  /** If NUMBER, the min value allowed */
  @Input() min: number;
  /** The MAXIMUM number of characters allowed by this input */
  @Input() maxlength: number;
  /** The MINIMUM number of characters allowed by this input */
  @Input() minlength: number;
  /** If using a date control, this is the date to default start at */
  @Input() dateStart: any; // = new Date(1930, 0, 1);
  /** Content for autocomplete html ATTRIBUTE, not autocomplete control */
  @Input() autocomplete: string;
  /** Formatter used for custom controls */
  @Input() format: string;
  /** Determine the size of the form field */
  @Input() size?: 'small' | 'medium' | 'large';
   /** Formatter used for custom controls */
   @Input() multiple: boolean;
   /** Allow iconGroup radio buttons to be de-selectable */
  @Input() isDeselectable = false;
  /** Allow tab Index on the element */
  @Input() tabIndex = false;

  @ViewChild('fieldRef') fieldRef: ElementRef;

  /** Is this field required */
  public required = false;
  /** Is the browser internet explorer? */
  public isChrome = isBrowserChrome();
  /** Is the browser mobile? */
  public isFirefox = isBrowserFirefox();
  /** Is the browser mobile? */
  public isIE = isBrowserIE();
  /** Is the browser chrome? */
  public isMobile = isBrowserMobile();
  /** A custom form control used to present differently formatted data to the user than what is actually returned to the model */
  public controlCustom: FormControl;
  /** Used for the aria-label on mat-select dropdowns to make them ada compliant */
  public labelCustom: string;

  /** Prevent infinite loops when updating currency controls */
  // private valueUpdating = false;
  /** Is this control loaded */
  private loaded = false;

  constructor(private ref: ChangeDetectorRef, private slugPipe: SlugPipe) {}

  ngOnInit() {
    // Use text-field for date inputs for all browsers except desktop Chrome and Firefox
    if (this.type === 'date' && !((this.isChrome || this.isFirefox) && !this.isMobile)) {
      this.type = 'dateText';
    }

    // If name not supplied, autogenerate one from the placeholder
    if (!this.name && this.placeholder) {
      this.name = this.slugPipe.transform(this.placeholder);
    }

    // If autocomplete not supplied, try to guess the value based on the field name
    if (!this.autocomplete) {
      this.autocomplete = autoCompleteSuggest(this.formControlRef);
    }

    // Update the select label
    if (this.type === 'select') {
      this.formControlRef.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
        this.labelCustom = this.labelSelectCustomCreate();
      });
    }

    // Update the label for real date fields
    if (this.type === 'date') {
      this.labelCustom = this.placeholder + ': ' + this.formControlRef.value;
      this.formControlRef.valueChanges.pipe(untilDestroyed(this)).subscribe(date => {
        this.labelCustom = this.placeholder + ': ' + date;
        if (date && (this.isChrome || this.isFirefox)) {
          // Fix bug with google chrome where it allows years with more than 4 characters
          const dateArray = date.split('-');
          if (dateArray[0] && dateArray[0].length > 4) {
            dateArray[0] = dateArray[0].slice(0, 4);
            this.formControlRef.patchValue(dateArray.join('-'));
          }
        }
      });
    }

    this.controlCustom = new FormControl(null);

    this.setInitialState();
  }

  ngAfterViewInit() {
    // Disable input calendar dropdown for FF
    if (this.type === 'date') {
      this.fieldRef.nativeElement.addEventListener('click', (event: any) => event.preventDefault(), false);
    }

    // If form field type is custom control
    if (['currency', 'number', 'phoneNumber', 'ssn'].includes(this.type)) {
      this.controlCustom = inputMaskCreate(
        this.formControlRef,
        this.type,
        this,
        this.fieldRef ? fromEvent(this.fieldRef.nativeElement, 'keyup') : null,
        this.format,
      );
      this.ref.detectChanges();
    }

    // Set date format for "date" text fields
    if (this.type === 'dateText') {
      this.controlCustom = inputMaskCreate(
        this.formControlRef,
        'dateText',
        this,
        this.fieldRef ? fromEvent(this.fieldRef.nativeElement, 'keyup') : null,
      );
      this.ref.detectChanges();
    }
    this.loaded = true;
  }

  ngOnChanges() {
    if (this.loaded) {
      this.setInitialState();
    }
  }

  /**
   * Determine the initial state of this form field, IE set required and disabled flags
   */
  private setInitialState() { 
    // Check if required, set required flag
    this.required = isRequired(this.formControlRef);

    // If this is a checkbox and it is required then the user is required to check to proceed
    if (this.required && this.type === 'checkbox') {
      // Watch value changes
      this.formControlRef.valueChanges.pipe(untilDestroyed(this)).subscribe(val => {
        if (val === null) {
          return;
        }
        // If checkbox value is true, remove errors, otherwise set errors
        if (val === true) {
          this.formControlRef.setErrors(null);
        } else {
          this.formControlRef.setErrors({ required: true });
        }
        // Set touched and fire change detection
        this.formControlRef.markAsTouched();
        this.ref.markForCheck();
      });
    }

    // Easily disable control
    if (this.disabled) {
      this.formControlRef.disable();
    } 
    else if (this.disabled === false){
      this.formControlRef.enable();
    }

    // Custom label for select
    if (this.type === 'select') {
      this.labelCustom = this.labelSelectCustomCreate();
    }
  }

  /**
   * Change type of input control
   * IE does not support this so check for that and ignore
   *
   * @param event
   * @param to
   */
  public inputChangeType(event: KeyboardEvent, to: string) {
    if (!this.isIE) {
      (<any>event).target.type = to;
    }
  }

  /**
   * Speak the value of the current select box for screen readers
   */
  private labelSelectCustomCreate() {
    let label = this.placeholder;
    if (this.optionsLabel && this.options && this.options.length) {
      const optionSelected = this.options.filter(option => option.value === this.formControlRef.value)[0];
      if (optionSelected) {
        label += ': ' + optionSelected.label;
      }
    } else {
      label += ': ' + this.formControlRef.value;
    }
    return label;
  }

  /**
   * When the number control changes and a max length has been specified. Cap the number at the specified length
   * Chrome does not support maxLength for type=number inputs
   * @param event
   */
  public numberChange(event: any) {
    const val = event.target.value;
    if (this.maxlength && val.length > this.maxlength) {
      this.controlCustom.patchValue(parseInt(val.slice(0, this.maxlength)));
    }
  }

  ngOnDestroy() {}

  get isInputmask() {
    return !!this.type.match(/^mask-\S+$/);
  }
  get inputmask() {
    return this.type.match(/^mask-(\S+)$/)[1];
  }

  get customMessageMin(): string|null {
    return (this.customMessages && this.customMessages.min) || null;
  }
}
