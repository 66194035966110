<div class="modal-header" automationid="Section_FeedbackHeader">
  <h3 class="modal-title"><i class="fa fa-comments-o" aria-hidden="true"></i> Send Feedback</h3>
  <button type="button" class="close" aria-label="Close" mat-dialog-close tabindex="1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" id="modal-body" automationid="Section_FeedbackComment">
  <p>Found a bug or would like to request a new feature? Let us know!</p>
  <form>
    <mat-form-field appearance="outline">
      <textarea matInput placeholder="Leave a comment" rows="4"></textarea>
    </mat-form-field>
  </form>
</div>

<div class="modal-footer" style="justify-content: space-between;" automationid="Section_FeedbackFooter">
  <button type="button" mat-button class="float-start pull-left dismiss" mat-dialog-close tabindex="1">
    <i class="fa fa-remove" aria-hidden="true"></i> Close
  </button>
  <button
  type="button"
  tabindex="1"
  mat-button
  class="mat-reverse"
  (click)="submit()"
  aria-label="Close"
  automationid="BtnSendFeedback">
  <i class="fa fa-comments-o" aria-hidden="true"></i> Send feedback
</button>
</div>
