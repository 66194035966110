<div id="modal" aria-modal="true" aria-describedby="modal-title" role="alertdialog" focusContain automationid="Page_Hint">
  <div class="modal-header" automationid="Section_Header">
    <h2 class="modal-title" [innerHtml]="data.title" id="modal-title"></h2>
    <button type="button" class="close" aria-label="Close" mat-dialog-close automationid="BtnClose">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" id="modal-body" [innerHtml]="data.body"></div>

  <div class="modal-footer text-center" style="justify-content: center;" automationid="Section_Footer">
    <button class="btn btn-outline-secondary w-50" aria-label="Close" (click)="submit()" (keydown.enter)="submit()" appFocus>
      Close
    </button>
  </div>
</div>
