<ng-container *ngIf="state">
  <!-- Loading -->
  <div *ngIf="(state.loading && !disableLoading) || (disableLoading && !state?.data)">
    <ng-container *ngIf="!showCustomLoadingContent; else customLoadingContent">
      <i class="fa fa-spinner fa-spin"></i> Loading...
    </ng-container>
    <ng-template #customLoadingContent>
      <ng-content select="[loadingContent]"></ng-content>
    </ng-template>
  </div>

  <!-- Error -->
  <ng-container *ngIf="errorShow && (state.error || state.errorModifying)">
    <app-error [error]="(state.error || state.errorModifying) | json" [canClose]="false">
      {{ state?.error?.message || state?.errorModifying?.message }}
    </app-error>
  </ng-container>

  <!-- Content -->
  <ng-container *ngIf="(!state?.loading && !state?.error) || (disableLoading && state?.data)"> <ng-content></ng-content> </ng-container>
</ng-container>
