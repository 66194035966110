import { Component } from '@angular/core';

@Component({
  selector: 'app-no-content',
  template: `
    <div class="container mt-3">
      <mat-card>
        <h1>404: page missing</h1>
        <p><a routerLink="/">Click here to go to the homepage.</a></p>
      </mat-card>
    </div>
  `,
})
export class NoContentComponent {}
