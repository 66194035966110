import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { debounceTime, switchMap, filter, catchError } from 'rxjs/operators';

export type LogEntry = Record<string, any>;
/**
 * A logging service that queues log events and sends them to the backend on an interval

 * TODO:
 * Switch to logs being passed through the observable instead of on the class property
 * Queued observables get out of order when checking from the stream instead of the class
 */
@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  /** Holds the log entries in queue so they can be sent as batches */
  private queue: LogEntry[] = [];
  /** Super props are appended to every log entry and are only needed to be sent once */
  private superProps: Record<string, any> = {};
  /** Manage the flow of data through the app including sending to the webapi */
  private queue$ = new BehaviorSubject<LogEntry[]>([]);
  /** Subscription for queue and http call */
  private sub: Subscription | undefined;
  private savingInProgress = false;

  constructor(private http: HttpClient) { }

  /**
   * Start sending logs
   * @param path Path to logging webapi
   * @param delay How long to queue/buffer log requests
   */
  public start(path: string, delay = 5 * 1000) {
    // Create sub
    this.sub = this.queue$
      .pipe(
        // Throttle how often the web api is hit
        debounceTime(delay),
        // Only pass if queue has entries
        filter(queue => !!queue.length && !this.savingInProgress),
        // Make HTTP call
        switchMap(queue => {
          this.savingInProgress = true;
          // Store reference to queue
          const queueSrc = [...queue];
          // Clear out current queue so that new entries are added and will be sent with a subsequent call
          this.queue = [];
          // Post to backend
          return this.http.post(path, queueSrc).pipe(
            catchError(err => {
              // On error, prepend log entries back into the queue so they will be sent with the next post
              this.queue = [...queueSrc, ...this.queue];
              return of(err);
            }),
          );
        }),
      )
      .subscribe(() => (this.savingInProgress = false));
  }

  /**
   * Add a log entry to be sent to the backend. Log entries are queued and sent in batches
   * @param logEntry
   */
  public track<t>(logEntry: t) {
    if (!this.sub) {
      console.warn('Logging has not yet been started');
    }

    this.queue = [...this.queue, { ...this.superProps, ...(<Record<string, any>>logEntry) }];
    if (!this.savingInProgress) {
      this.queue$.next(this.queue);
    }
  }

  /**
   * Add or change a global super property which is appended to every log request
   * @param props
   */
  public superPropsAdd<t>(props: t) {
    this.superProps = { ...this.superProps, ...(<Record<string, any>>props) };
  }

  /**
   * Stop sending log request
   */
  public stop() {
    if (this.sub) {
      this.sub.unsubscribe();
      this.sub = undefined;
    }
  }

  /**
   * Reset the log queue and super props
   */
  public reset() {
    this.queue = [];
    this.superProps = {};
  }
}

