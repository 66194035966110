<p *ngIf="isLoading" class="text-center py-5">
  <i class="fa fa-spinner fa-spin"></i> Loading...
</p>

<!-- FORMFREE IFRAME -->
<div class="iframe-container" *ngIf="showIframe" automationid="Section_FormFree">
  <iframe [src]="iframeUrl | safeHtml:'resourceUrl'"></iframe>
</div>

<!-- ACCOUNT LIST -->
<div class="account-list-container" *ngIf="showAccountList" automationid="Section_AccountList">
  <h2 class="subtitle text-center">Who does the account(s) belong to?</h2>
  <ul class="account-list">
    <li *ngFor="let account of accountList">
      <div>
        <div class="pull-left">
          <div class="financial-institution">{{account.financialInstitutionName}}</div>
          <div class="account-details">{{account.voaAccountName}}: #{{account.accountNumber}}</div>
        </div>
        <div class="pull-right">
          <div class="account-balance">{{account.voaBalance | currency}}</div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="pt-4">
        <mat-form-field>
          <mat-select [(ngModel)]="account.ownerId" required [formControl]="getControlRef(account.voaAccountId)" automationid="AssetOwnerId" placeholder="Account belongs to:">
            <mat-option *ngFor="let borrower of borrowers" [value]="borrower.value">
              {{borrower.label}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="getControlRef(account.voaAccountId).hasError('required')">Account Belongs to <strong>is required</strong></mat-error>
        </mat-form-field>
      </div>
    </li>
  </ul>
</div>

<!-- ERROR HANDLING -->

<div class="errors" *ngIf="error" automationid="Section_ErrorHandling">
  <div role="alert" class="alert alert-danger icon my-4">{{error}}</div>
</div>

<div *ngIf="showAccountList" class="nav-buttons nav-buttons-evoa btn-group d-flex justify-content-between"
  automationid="Section_NavButtons">
  <button mat-flat-button class="btn py-2 back w-50" type="button" (click)="previousPage()" automationid="BtnBack">
    <i class="fa fa-long-arrow-left me-1" aria-hidden="true"></i> Back
  </button>
  <button mat-flat-button class="btn w-50 py-2 next w-50" type="button" (click)="processAccounts(bnlItem)" automationid="BtnNext">
    Next <i class="fa fa-long-arrow-right ms-1" aria-hidden="true"></i>
  </button>
</div>
