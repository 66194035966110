import { AbstractControl, ValidatorFn, Validators, FormGroup, FormControl, FormArray, ValidationErrors } from '@angular/forms';
import { objectTraverser } from './objectTraverser.utils';

/**
 * Return a specific form control from a formgroup from a string
 * TODO: Add defensive coding around fields that don't return a control
 * TODO: Figure out a better way to handle custom form controls
 * @param field
 * @param form
 * @param arrayIndexes
 */
export const controlsGetNested = (field: string, form: any, arrayIndexes: any = []): AbstractControl => {
  // console.log('controlsGetNested', field, form);
  // Throw an error if a mapped field was not found
  const mappingError = (fieldError: string, location: number) =>
    console.error(
      `No form control found for ${fieldError} at ${location}, please fix the mapping or use a $$custom prefix: `,
      field,
      form,
    );

  // Add a control to the custom field
  const addCustomControl = (fieldNew: string) => {
    console.log(form);
    if (!form.get('$$custom').get(fieldNew)) {
      const controlNew = new FormControl();
      form.get('$$custom').addControl(fieldNew, controlNew);
      return controlNew;
    }
  };

  // If this is a nested form field represented by dot notation, IE: 'contactInfo.name.first'
  if (field.indexOf('.') !== -1) {
    // Get the first property in the path
    const pathCurrent = field.split('.')[0];
    // Remove the first property from the path
    const pathNext = field
      .split('.')
      .slice(1)
      .join('.');

    // If the current path has the $$custom prefix and a formcontrol for pathnext has not yet been created, create it
    if (pathCurrent === '$$custom' && form.get('$$custom') && !form.get('$$custom').get(pathNext)) {
      const formGroupNew = form.get('$$custom') as FormGroup;
      const controlNew = new FormControl();
      formGroupNew.addControl(pathNext, controlNew);
      return controlNew;
    }

    // If path current is an array
    if (pathCurrent.indexOf('[') !== -1 && pathCurrent.indexOf(']') !== -1) {
      // If array, default index is 0
      let indexDefault = 0;
      // If a custom index was supplied in the brackets, IE 'field[2]'
      // Extract the value out of the bracket notation
      const arrayIndex = pathCurrent.match(/\[(.*?)\]/g);
      // If a value was returned
      if (arrayIndex && arrayIndex[0] && arrayIndex[0].length) {
        // Remove the brackets from the string
        const stringReplaced = arrayIndex[0].replace('[', '').replace(']', '');
        // Check if a string was returned and isn't empty, convert to number
        indexDefault = stringReplaced && stringReplaced.length ? parseInt(stringReplaced) : 0;
      }
      // Get the current array index. If not specified via route params then default to 0
      const indexCurrent = arrayIndexes && arrayIndexes.length ? parseInt(arrayIndexes[0]) : indexDefault;
      // Get the current path by removing the bracket from the array
      const pathCurrentAlt = pathCurrent.split('[')[0];
      // Get the form control for that property
      let controlNext: AbstractControl;
      if (
        form.controls &&
        form.controls[pathCurrentAlt] &&
        form.controls[pathCurrentAlt].controls &&
        form.controls[pathCurrentAlt].controls[indexCurrent]
      ) {
        controlNext = form.controls[pathCurrentAlt].controls[indexCurrent];
      } else {
        mappingError(pathCurrentAlt, 1);
        const control = new FormControl();
        // form.controls.push(control);
        return control;
      }

      // Now recurse into this form control to grab the nested form control
      return controlsGetNested(pathNext, controlNext, arrayIndexes);
    } else {
      // Get the form control for that property
      let controlNext: AbstractControl;
      if (form.controls && form.controls[pathCurrent]) {
        controlNext = form.controls[pathCurrent];
        return controlsGetNested(pathNext, controlNext, arrayIndexes);
      } else {
        if (pathCurrent === 'loan.$$custom') {
          return addCustomControl(pathCurrent);
        } else {
          mappingError(pathCurrent, 2);
          const control = new FormControl();
          return control;
        }
      }
      // const controlNext = form.controls[pathCurrent];

      // Now recurse into this form control to grab the nested form control
      // return controlsGetNested(pathNext, controlNext, arrayIndexes);
    }
  } else if (form && form.controls && form.controls[field]) {
    // Not a nested form field, return the control
    if (form.controls[field]) {
      return form.controls[field];
    } else {
      mappingError(field, 3);
      const control = new FormControl();
      // form.controls.push(control);
      return control;
    }
  } else if (field.indexOf('[') !== -1 && field.indexOf(']') !== -1) {
    const stringReplaced = field.replace('[', '').replace(']', '');
    return form.controls[stringReplaced];
  } else {
    // TODO: Figure out better way to handle custom form fields
    mappingError(field, 4);
    // No form control found for this field, creating a new one
    const control = new FormControl();
    return control;
  }
};

export function requiredCustom(): ValidatorFn {  
  return (control: AbstractControl): { [key: string]: any } | null =>  
      (control.value === undefined || control.value === null || control.value === 0) 
          ? {requiredCustom: true} : null;
}

/**
 * Takes a form field element and creates the validators array needed by abstract control
 * @param control
 * @param element
 */
export const controlSetValidation = (validatorsSrc: CvFormBuilder.Validators) => {
  // Create an array to hold validators
  const validators: ValidatorFn[] = [];
  if (validatorsSrc && validatorsSrc.required) {
    validators.push(Validators.required);
  }
  if (validatorsSrc && validatorsSrc.minLength) {
    validators.push(Validators.minLength(validatorsSrc.minLength));
  }
  if (validatorsSrc && validatorsSrc.maxLength) {
    validators.push(Validators.maxLength(validatorsSrc.maxLength));
  }
  if (validatorsSrc && validatorsSrc.email) {
    validators.push(Validators.email);
  }
  if (validatorsSrc && validatorsSrc.pattern) {
    validators.push(Validators.pattern(validatorsSrc.pattern));
  }
  if (validatorsSrc && validatorsSrc.requiredCustom) {
    validators.push(requiredCustom());
  }
  if (validatorsSrc && (validatorsSrc.max || validatorsSrc.max == 0)) {
    validators.push(validatorMax(validatorsSrc.max));
  }
  if (validatorsSrc && (validatorsSrc.min || validatorsSrc.min == 0)) {
    validators.push(validatorMin(validatorsSrc.min));
  }
  return validators;
};

export function validatorMax(max: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null =>  {
    const controlValue: number = control.value;

    if (controlValue===null) {
      return null;
    }

    return isNaN(controlValue) || /\D/.test(controlValue.toString()) || (controlValue > max)
      ? {"max": max}
      : null;
  }
}

export function validatorMin(min: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null =>  {
    const controlValue: number = control.value;

    if (controlValue===null) {
      return null;
    }

    return isNaN(controlValue) || /\D/.test(controlValue.toString()) || (controlValue < min)
      ? {"min": min}
      : null;
  }
}
/**
 * Add a new form control form array
 * @param field
 * @param form
 * @param defaults Any default data to prepopulate the model with, IE {borrowerId: '123455' }
 */
export const modelAdd = (
  field: string,
  form: FormGroup,
  indexes: FormGroup,
  modelDefaults: FormGroup,
  defaults?: any,
) => {
  // console.log('modelAdd', field, form, indexes.getRawValue(), modelDefaults, defaults);
  // TODO: Object traverser
  // const arrayControl = controlsGetNested(field, form) as FormArray;
  const arrayControl: FormArray = objectTraverser(field, form, indexes.getRawValue(), 'controls');
  let controlNew;
  const fieldShort = field
    .replace('[', '')
    .replace(']', '')
    .trim();
  // Get model from defaults first
  if (modelDefaults && modelDefaults.value[fieldShort]) {
    controlNew = cloneAbstractControl(modelDefaults.controls[fieldShort]);
    controlNew.patchValue(modelDefaults.value[fieldShort]); // Patch in defaults from original model
  } else if (arrayControl.controls.length) {
    // If no default model, try and clone from existing model in array
    controlNew = cloneAbstractControl(arrayControl.controls[0]);
  } else {
    console.error('modelAdd: This formArray is empty and has no model to infer from');
  }
  if (defaults) {
    controlNew.patchValue(defaults);
  }
  arrayControl.push(controlNew);
  return arrayControl.controls.length - 1;
};

/**
 * Clone an abstract control
 * @param control
 * @param reset
 */
export const cloneAbstractControl = (control: AbstractControl, reset = true) => {
  let newControl: AbstractControl;

  if (control instanceof FormGroup) {
    const formGroup = new FormGroup({}, control.validator, control.asyncValidator);
    const controls = control.controls;

    Object.keys(controls).forEach(key => {
      formGroup.addControl(key, cloneAbstractControl(controls[key]));
    });

    newControl = formGroup;
  } else if (control instanceof FormArray) {
    const formArray = new FormArray([], control.validator, control.asyncValidator);

    control.controls.forEach(formControl => formArray.push(cloneAbstractControl(formControl)));

    newControl = formArray;
  } else if (control instanceof FormControl) {
    newControl = new FormControl(control.value, control.validator, control.asyncValidator);
  } else {
    console.error('Error: unexpected control value');
  }

  if (control.disabled) {
    newControl.disable({ emitEvent: false });
  }

  if (reset) {
    newControl.reset();
  }

  return newControl;
};

/**
 * Automatically generate a form group complete with controls. Will recurse through nested objects/arrays.
 * @param model - An object or JSON of the model. Can contain nested objects and arrays
 * @param defaultRequired - Should all fields be required. Default is false
 */
export const formGroupCreate = (model: any): FormGroup => {
  // console.log('formGroupCreate', model);
  const formModel: any = {};
  // Loop through all props in the model
  Object.keys(model).forEach(key => {
    // If this is a nested object, recurse to create form group
    if (model[key] && typeof model[key] === 'object' && !Array.isArray(model[key])) {
      formModel[key] = formGroupCreate(model[key]);
    } else if (model[key] && typeof model[key] === 'object' && Array.isArray(model[key])) {
      // If this is an array, recurse to create a form array
      const formArray: any[] = [];
      model[key].forEach((item: any) => formArray.push(formGroupCreate(item)));
      formModel[key] = new FormArray(formArray); // this.fb.array(formArray);
    } else {
      // Standard value
      formModel[key] = [null, []];
    }
  });
  return new FormGroup(formModel); // this.fb.group(formModel);
};

/**
 * Clean up an array of object by removing nulls, undefined, empty arrays/objects etc
 * @param entity
 * @param optionsSrc
 */
export const entityCleanup = (
  entity: any,
  optionsSrc?: {
    removeNulls?: boolean;
    removeUndefined?: boolean;
    removeEmptyStrings?: boolean;
    removeEmptyArray?: boolean;
    removeEmptyObject?: boolean;
    removeGlobally?: string[];
  },
) => {
  // Set defaults, flatten optionsSrc on top
  const options = {
    removeNulls: true,
    removeUndefined: true,
    removeEmptyStrings: true,
    removeEmptyArray: true,
    removeEmptyObject: true,
    removeGlobally: <string[]>[],
    ...optionsSrc,
  };

  // If entity is an array
  if (typeof entity === 'object' && Array.isArray(entity)) {
    const arr = entity.map(arrOne => {
      if (typeof arrOne === 'object') {
        // If prop is an object, recurse and update this key
        arrOne = entityCleanup(arrOne, options);
      }
      return arrOne;
    });
    return arr;
  }

  // If entity is an object
  if (typeof entity === 'object' && !Array.isArray(entity)) {
    const obj = { ...entity };
    // Loop through all keys in this object
    Object.keys(obj).forEach(key => {
      // If this is a property to remove globally
      if (options.removeGlobally.length && options.removeGlobally.indexOf(key) !== -1) {
        delete obj[key];
      }

      // Remove empty strings
      if (options.removeEmptyStrings && typeof obj[key] === 'string' && obj[key] === '') {
        delete obj[key];
      }

      // If prop is null and removeNulls is set
      if ((options.removeNulls && obj[key] === null) || (options.removeUndefined && obj[key] === undefined)) {
        delete obj[key];
      }

      // If obj is an array or object
      if (typeof obj[key] === 'object') {
        // If prop is an object, recurse and update this key
        obj[key] = entityCleanup(obj[key], options);
        // After element has been cleaned up
        // Remove empty arrays
        if (typeof obj[key] === 'object' && Array.isArray(obj[key]) && options.removeEmptyArray && !obj[key].length) {
          delete obj[key];
        }
        // Remove empty objects
        if (
          typeof obj[key] === 'object' &&
          !Array.isArray(obj[key]) &&
          options.removeEmptyObject &&
          !Object.keys(obj[key]).length
        ) {
          delete obj[key];
        }
      }
    });
    return obj;
  }
};

export const isElementRequired = (element: CvFormBuilder.Feature): boolean => {
  return element && element.fields && element.fields.length > 0
  && element.fields[0].validators && element.fields[0].validators.required;
}
