import { RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';
import { PropertyTypeEnum, CPOSdateTypeEnum, CPOSPageValidatorsEnum } from 'src/app/shared/models';

export const propertyPages: CvFormBuilder.Pages = {
  guidPageA: {
    title: 'Property Intro Section',
    titleShow: false,
    pageId: 'start',
    showButtonNext: false,
    showButtonBack: false,
    fullscreen: true,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'start',
        data: {
          sectionLabel: 'Property',
          sectionTitle: `Let's get some details about your <strong>purchase</strong>`,
          sectionImage: `/assets/img/application/property.png`,
        },
        visible: {
          rules: [
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 1,
            },
          ],
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'start',
        data: {
          sectionLabel: 'Property',
          sectionTitle: `Let's get some details about your <strong>refinance</strong>`,
        },
        visible: {
          rules: [
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 2,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageB: {
    title: 'Where is the <strong>property located</strong>?',
    titleShort: 'Property Location',
    pageId: 'property-located',
    validatorId: 'propertyValidation',
    content: [
      <CvFormBuilder.FormField>{
        id: 'purchase',
        type: 'formField',
        field: 'loan.$$custom.loan.addressSubject.streetName',
        placeholder: 'Street Address',
        formFieldType: 'text',
        hidden: true,
        validators: {
          required: true,
          tbdStreetAddress: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 1,
            },
            {
              field: 'loan.$$custom.signedPurchaseAgreement',
              operator: RuleExprOp.NE,
              value: false,
            },
          ],
        },
      },
      <CvFormBuilder.Feature>{
        id: 'purchase',
        type: 'feature',
        featureId: 'address-autocomplete',
        columns: 7,
        data: {
          addressPath: 'loan.$$custom.loan.addressSubject.streetName',
          cityPath: 'loan.$$custom.loan.addressSubject.cityName',
          statePath: 'loan.$$custom.loan.addressSubject.stateName',
          zipPath: 'loan.$$custom.loan.addressSubject.zipCode',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.addressSubject.streetName',
            validators: {
              required: true,
              tbdStreetAddress: true,
            },
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 1,
            },
            {
              field: 'loan.$$custom.signedPurchaseAgreement',
              operator: RuleExprOp.NE,
              value: false,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        id: 'purchase',
        type: 'formField',
        field: 'loan.$$custom.loan.addressSubject.unitNumber',
        placeholder: 'Unit #',
        formFieldType: 'text',
        columns: 5,
        visible: {
          rules: [
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 1,
            },
            {
              field: 'loan.$$custom.signedPurchaseAgreement',
              operator: RuleExprOp.NE,
              value: false,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        id: 'refinance',
        type: 'formField',
        field: 'loan.$$custom.loan.addressSubject.streetName',
        placeholder: 'Street Address',
        formFieldType: 'text',
        hidden: true,
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 2,
            },
          ],
        },
      },
      <CvFormBuilder.Feature>{
        id: 'refinance',
        type: 'feature',
        featureId: 'address-autocomplete',
        columns: 7,
        data: {
          addressPath: 'loan.$$custom.loan.addressSubject.streetName',
          cityPath: 'loan.$$custom.loan.addressSubject.cityName',
          statePath: 'loan.$$custom.loan.addressSubject.stateName',
          zipPath: 'loan.$$custom.loan.addressSubject.zipCode',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.addressSubject.streetName',
            validators: {
              required: true,
            },
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 2,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        id: 'refinance',
        type: 'formField',
        field: 'loan.$$custom.loan.addressSubject.unitNumber',
        placeholder: 'Unit #',
        formFieldType: 'text',
        columns: 5,
        visible: {
          rules: [
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 2,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.addressSubject.cityName',
        placeholder: 'City',
        formFieldType: 'text',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.addressSubject.stateName',
        placeholder: 'State',
        formFieldType: 'select',
        dataField: 'df-219',
        columns: 6,
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Feature>{
        id: 'state-lookup',
        type: 'feature',
        featureId: 'state-lookup',
        columns: 6,
        data: {
          cityPath: 'loan.$$custom.loan.addressSubject.cityName',
          statePath: 'loan.$$custom.loan.addressSubject.stateName',
          zipPath: 'loan.$$custom.loan.addressSubject.zipCode',
          countyPath: 'loan.$$custom.loan.addressSubject.countyName',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.addressSubject.zipCode',
            validators: {
              required: true,
            },
          },
        ],
      },
      /**
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.addressSubject.zipCode',
        placeholder: 'Zip',
        formFieldType: 'text',
        maxlength: 5,
        minlength: 5,
        columns: 6,
        validators: {
          required: true,
        },
      }, */
      /** This needs to be last and ensures the lookup response is available for page validation */
      <CvFormBuilder.FormField>{
        type: 'formField',
        hidden: true,
        field: 'loan.$$custom.zipLookupResponse',
        formFieldType: 'text',
      },
    ],
  }, // end page

  guidPageBB: {
    title: 'Where are you <strong>looking to buy</strong>?',
    titleShort: 'Property Location',
    validatorId: CPOSPageValidatorsEnum.propertyValidation,
    pageId: 'looking-to-buy',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `I Don't Know Where I'm Buying Yet`,
          body: `
            <p>You may be in the process of shopping for a home, but don't even know what city you will end up buying in. If so, please provide a ZIP Code of one of the areas you are interested in. This will help us estimate various fees and taxes that may be associated with your new home.</p>
          `,
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.addressSubject.cityName',
        placeholder: 'City',
        formFieldType: 'text',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.addressSubject.stateName',
        placeholder: 'State',
        formFieldType: 'select',
        dataField: 'df-219',
        columns: 6,
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Feature>{
        id: 'state-lookup',
        type: 'feature',
        featureId: 'state-lookup',
        columns: 6,
        data: {
          cityPath: 'loan.$$custom.loan.addressSubject.cityName',
          statePath: 'loan.$$custom.loan.addressSubject.stateName',
          zipPath: 'loan.$$custom.loan.addressSubject.zipCode',
          countyPath: 'loan.$$custom.loan.addressSubject.countyName',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.addressSubject.zipCode',
            validators: {
              required: true,
            },
          },
        ],
      },

      /**
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.addressSubject.zipCode',
        placeholder: 'Zip',
        formFieldType: 'text',
        columns: 6,
        validators: {
          required: true,
        },
      },
       */
      /** This needs to be last and ensures the lookup response is available for page validation */
      <CvFormBuilder.FormField>{
        type: 'formField',
        hidden: true,
        field: 'loan.$$custom.zipLookupResponse',
        formFieldType: 'text',
      },
    ],
  }, // end page

  guidPageC: {
    title: `Tell us about the property you're refinancing`,
    titleShow: false,
    pageId: 'occupy-or-use',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center page-title">Tell us about <strong>the property</strong> you want to purchase</h2>',
        visible: {
          rules: [
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.NE,
              value: 2,
            },
            {
              field: 'loan.$$custom.nonSpouseCoborrower',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h2 class="text-center page-title">Tell us about <strong>the property</strong> you\'re refinancing</h2>',
        visible: {
          rules: [
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 2,
            },
            {
              field: 'loan.$$custom.nonSpouseCoborrower',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center page-title"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, this is the property to be purchased:</h2>',
        visible: {
          rules: [
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.NE,
              value: 2,
            },
            {
              field: 'loan.$$custom.nonSpouseCoborrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center page-title"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, this is the property to be refinanced:</h2>',
        visible: {
          rules: [
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 2,
            },
            {
              field: 'loan.$$custom.nonSpouseCoborrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end

      // with street addr
      <CvFormBuilder.FormField>{
        type: 'html',
        html: `<h3 class="text-center mb-4 title-sub d-flex justify-content-center">
  <span><i class="fa fa-map-pin mr-2 fa-2x" aria-hidden="true"></i> </span>
  <span><strong>{{loan.$$custom.loan.addressSubject.streetName}} {{loan.$$custom.loan.addressSubject.unitNumber}}<br/> {{loan.$$custom.loan.addressSubject.cityName}}
  {{loan.$$custom.loan.addressSubject.stateName}} {{loan.$$custom.loan.addressSubject.zipCode}}</strong></span></h3>`,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.nonSpouseCoborrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },

      <CvFormBuilder.Html>{
        type: 'html',
        html: '<p class="text-center">How do you intend to occupy or use this property?</p>',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.nonSpouseCoborrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Subject Property Occupancy Type',
        field: 'loan.$$custom.subjectPropertyOccupancyType',
        errorCustom: 'Selecting an option for occupancy type <strong>is required</strong>.',
        formFieldType: 'buttonGroup',
        formFieldData: [
          {
            label: 'I will be living at this property full-time (Primary Residence)',
            value: 1,
          },
          {
            label: 'I will vacation at this property (Second Home)',
            value: 3,
          },
          {
            label: 'This is an investment (Investment Property)',
            value: 2,
          },
        ],
        validators: {
          required: true,
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Property Type',
        field: 'loan.$$custom.loan.addressSubject.propertyType',
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'Single-Family',
            value: PropertyTypeEnum.SingleFamily,
          },
          {
            label: 'Single-Family Home with HOA',
            value: PropertyTypeEnum.PUD,
          },
          {
            label: 'Condo',
            value: PropertyTypeEnum.Condominium,
          },
          {
            label: 'Townhouse',
            value: PropertyTypeEnum.TownHouse,
          },
          {
            label: 'Co-op',
            value: PropertyTypeEnum.Cooperative,
          },
          {
            label: 'Two-to-Four Unit',
            value: 88, // Two-4 unit is the same as a single family home in LC, only difference is # units. This val will be replaced in map file by single family
          },
          {
            label: 'Manufactured Home - Single Wide',
            value: PropertyTypeEnum.ManufacturedHousingSingleWide,
          },
          {
            label: 'Manufactured Home - Double Wide',
            value: PropertyTypeEnum.ManufacturedHousingDoubleWide,
          },
          {
            label: 'Modular',
            value: PropertyTypeEnum.Modular,
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.nonSpouseCoborrower',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.addressSubject.numberOfUnits',
        placeholder: 'Number of Units',
        formFieldType: 'select',
        formFieldData: [
          {
            label: '2',
            value: 2,
          },
          {
            label: '3',
            value: 3,
          },
          {
            label: '4',
            value: 4,
          },
        ],
        validators: {
          // required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.nonSpouseCoborrower',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.$$custom.loan.addressSubject.propertyType',
              operator: RuleExprOp.EQ,
              value: 88,
            },
          ],
        },
      }, // end

      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        id: 'propertyType',
        data: {
          title: `Property types explained`,
          body: `
            <h3>Single-Family</h3>
            <p>Sometimes referred to as Single-Family Detached Homes, these are built on a single lot, and do not share a wall with another home.</p>
            <h3>Single-Family Home with HOA</h3>
            <p>With similar characteristics as a typical Single-Family Home, these homes are part of a Home Owners Association (HOA).</p>
            <h3>Condo</h3>
            <p>Condominiums are single, individually owned units within a larger building sharing walls with other units.</p>
            <h3>Townhouse</h3>
            <p>Townhouses are like Single-Family Homes but share a wall with another unit. They generally share the same roof and yard space.</p>
            <h3>Co-op</h3>
            <p>Cooperatives (Co-ops) are similar in some ways to condominiums. However, with Co-ops each unit is NOT individually owned. Instead, homeowners own the entire building together.</p>
            <h3>Two-to-Four Unit</h3>
            <p>Also known as Multi-Family Home, this is a building or structure that is designed to house several different families in separate housing units, and consists of 2 or more units. All units are purchased together.</p>
            <h3>Manufactured/Single-wide</h3>
            <p>Manufactured homes are built on steel beam frames with axles and tires attached underneath. The house is generally 15 feet by 72 feet long and can be relocated.</p>
            <h3>Manufactured/Double-wide</h3>
            <p>Manufactured homes are built on steel beam frames with axles and tires attached underneath. The house is generally double the size of a single-wide and may come in 2 halves for separate delivery.</p>
            <h3>Modular</h3>
            <p>A Modular home is built in different sections (from 2-5 sections). The sections are delivered to the final site and joined together creating a complete home. Unlike manufactured homes, modular homes do not have axles attached to them.</p>
          `,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.nonSpouseCoborrower',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      }, // end
    ],
  }, // end page

  guidPageD: {
    title: 'What is the original <strong>purchase price</strong> and <strong>date</strong>?',
    titleShort: 'Property Details',
    pageId: 'purchase-price-date',
    validatorId: CPOSPageValidatorsEnum.purchaseDate,
    content: [
      // no street addr
      <CvFormBuilder.FormField>{
        type: 'html',
        html: `<h3 class="text-center mb-4 title-sub d-flex justify-content-center">
        <span><i class="fa fa-map-pin mr-2 fa-2x" aria-hidden="true"></i> </span>
        <span class="line-height-2x"><strong>{{loan.$$custom.loan.addressSubject.cityName}}
        {{loan.$$custom.loan.addressSubject.stateName}} {{loan.$$custom.loan.addressSubject.zipCode}}</strong></span></h3>`,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.addressSubject.streetName',
              operator: RuleExprOp.EQ,
              value: '',
            },
          ],
        },
      },
      // with street addr
      <CvFormBuilder.FormField>{
        type: 'html',
        html: `<h3 class="text-center mb-4 title-sub d-flex justify-content-center">
        <span><i class="fa fa-map-pin mr-2 fa-2x" aria-hidden="true"></i> </span>
        <span><strong>{{loan.$$custom.loan.addressSubject.streetName}} {{loan.$$custom.loan.addressSubject.unitNumber}}<br/> {{loan.$$custom.loan.addressSubject.cityName}}
        {{loan.$$custom.loan.addressSubject.stateName}} {{loan.$$custom.loan.addressSubject.zipCode}}</strong></span></h3>`,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.addressSubject.streetName',
              operator: RuleExprOp.NE,
              value: '',
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Original Purchase Price',
        field: 'loan.$$custom.loan.addressSubject.purchasePrice',
        formFieldType: 'currency',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        maxlength: 10,
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'Original Purchase Date',
        data: {
          dateType: CPOSdateTypeEnum.originalPurchaseDate,
        },
        fields: [
          {
            field: 'loan.$$custom.loan.addressSubject.purchaseDate',
            validators: {
              // required: true,
            },
          },
        ],
      },
    ],
  }, // end page

  guidPageE: {
    title: "Let's calculate the <strong>loan amount</strong> you're looking for.",
    titleShort: 'Loan Details',
    pageId: 'loan-amount',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `How is my loan amount calculated?`,
          body: `
            <h3>Purchase Price</h3>
            <p>This is the amount of money that you are paying to purchase your home.</p>
            <h3>Down Payment (%)</h3>
            <p>The down payment is a percentage of the total sales price of your home, which is given to the home seller as part of the purchase. The remainder of the purchase price to the seller comes from your mortgage.</p>
            <h3>Loan Amount</h3>
            <p>This is the amount of money you are seeking to borrow from your lender.</p>
            <h3>Down Payment Source</h3>
            <p>This indicates where you will be getting the money that you use for your down payment.</p>
          `,
        },
      }, // end

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Purchase Price',
        field: 'loan.$$custom.loan.addressSubject.purchasePrice',
        formFieldType: 'currency',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        maxlength: 10,
        validators: {
          required: true,
          maxLength: 8,
        },
      }, // end

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Down Payment',
        field: 'loan.$$custom.loan.addressSubject.downPayment',
        formFieldType: 'currency',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        columns: 7,
        maxlength: 10,
        validators: {
          required: true,
          maxLength: 8,
        },
      }, // end

      <CvFormBuilder.Html>{
        type: 'html',
        html: '<div class="text-center pt-4">or</div>',
        columns: 1,
      }, // end

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: '%',
        field: 'loan.$$custom.loan.addressSubject.downPaymentType',
        formFieldType: 'mask-percentage-nosuffix',
        maxlength: 3,
        columns: 4,
        validators: {
          required: true,
          maxLength: 3,
        },
      }, // end

      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'income-calc',
      }, // end

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.financialInfo.downPaymentTypeCode',
        placeholder: 'Primary Down Payment Source',
        formFieldType: 'select',
        // dataField: 'df-89',
        // Hard coding data for now since enums do not match what client wants
        formFieldData: [
          {
            label: 'Checking/Savings',
            value: 1,
          },
          {
            label: 'Equity pending from sale',
            value: 4,
          },
          {
            label: 'Gift from friend or relative',
            value: 6,
          },
          {
            label: 'Retirement Funds',
            value: 12,
          },
          {
            label: 'Sale of stocks and bonds',
            value: 7,
          },
          {
            label: 'Trust funds',
            value: 11,
          },
          {
            label: 'Other',
            value: 19,
          },
        ],
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `Down Payment Source`,
          body: `
            <h3>Down Payment Source</h3>
            <p>This indicates where you will be getting the money that you use for your down payment.</p>
          `,
        },
      }, // end
    ],
  }, // end page

  guidPageEE: {
    title: `Let's calculate the <strong>loan amount</strong> you're looking for.`,
    pageId: 'loan-amount-refi',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `How is my loan amount calculated?`,
          body: `
            <h3>Estimated Property Value</h3>
            <p>How much do you think your home is worth? The value of your home is typically based on the sales price of similar homes recently sold in your neighborhood.</p>
            <h3>Remaining Mortgage Balance</h3>
            <p>This is the payoff amount for the mortgage that you currently have in place for your home.</p>
            <h3>Cash Out</h3>
            <p>If you currently have equity in your home, you may want to use some portion of that equity to get immediate cash to use for reasons other than home improvements or paying off high interest debt. For example, you may refinance at a new loan amount to get cash back to pay for a wedding, college, or just to have cash on hand for a rainy day.</p>
            <h3>Loan Amount</h3>
            <p>If you are applying for a cash out refinance loan, then the loan amount is your existing mortgage balance plus the cash out amount. If you are refinancing your mortgage with no cash out, then the loan amount is simply your existing mortgage balance. The loan amount does not include loan closing costs.</p>
          `,
        },
      }, // end

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Estimated Property Value',
        field: 'loan.$$custom.loan.addressSubject.currentEstimatedValue',
        formFieldType: 'currency',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        maxlength: 10,
        validators: {
          required: true,
        },
      }, // end

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Remaining Mortgage Balance',
        field: 'loan.otherInterviewData.firstMortgage',
        formFieldType: 'currency',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        maxlength: 10,
        validators: {
          required: true,
        },
      }, // end

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Cash Out',
        field: 'loan.otherInterviewData.cashOutAmount',
        formFieldType: 'currency',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        maxlength: 10,
        customMessages: <CvFormBuilder.CustomMessages>{
          min: 'Cash Out Amount must be greater than $0'
        },
        validators: {
          required: true,
          min: 1
        },
        visible: {
          rules: [
            {
              field: 'loan.purposeOfRefinance',
              operator: RuleExprOp.LE,
              value: 6,
            },
          ],
        },
      }, // end

      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'income-calc',
      }, // end
    ],
  }, // end page

  guidPageY: {
    title: 'Please <strong>contact</strong> your loan officer',
    pageId: 'invalid-state',
    isLastPage: true,
    content: [
      {
        type: 'html',
        html:
          '<p class="text-danger">Unfortunately, due to the state your property is located in, our team is unable to process this loan application.</p>',
      },
      {
        type: 'html',
        html: '<p class="text-danger">Please contact me at your earliest convenience to see where I can help you.</p>',
      },
    ],
  }, // end page

  guidPageF: {
    title: 'Borrower owns additional properties',
    titleShow: false,
    pageId: 'additional-properties',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">Do you own any <strong>additional properties</strong> besides the property you currently live at and the property you are purchasing?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 1,
            },
            {
              field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.ownership',
              operator: RuleExprOp.EQ,
              value: 0,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, do you own any <strong>additional properties</strong> besides the property you currently live at and the property you are purchasing?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 1,
            },
            {
              field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.ownership',
              operator: RuleExprOp.EQ,
              value: 0,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">Do you own any <strong>additional properties</strong> besides the property you are purchasing?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 1,
            },
            {
              field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.ownership',
              operator: RuleExprOp.NE,
              value: 0,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, do you own any <strong>additional properties</strong> besides the property you are purchasing?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 1,
            },
            {
              field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.ownership',
              operator: RuleExprOp.NE,
              value: 0,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">Do you own any <strong>additional properties</strong> besides the property you currently live at and the property you are refinancing?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 2,
            },
            {
              field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.ownership',
              operator: RuleExprOp.EQ,
              value: 0,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, do you own any <strong>additional properties</strong> besides the property you currently live at and the property you are refinancing?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 2,
            },
            {
              field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.ownership',
              operator: RuleExprOp.EQ,
              value: 0,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">Do you own any <strong>additional properties</strong> besides the property you are refinancing?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 2,
            },
            {
              field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.ownership',
              operator: RuleExprOp.NE,
              value: 0,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, do you own any <strong>additional properties</strong> besides the property you are refinancing?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 2,
            },
            {
              field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.ownership',
              operator: RuleExprOp.NE,
              value: 0,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Additional properties owned?',
        class: 'additional-properties',
        field: 'loan.transactionInfo.borrowers[0].borrowerDetail.additionalPropertiesOwned',
        formFieldType: 'iconGroup',
        errorCustom: 'Selecting an option for additional properties owned <strong>is required</strong>.',
        formFieldData: [
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ],
        validators: {
          required: true,
        },
      },
    ],
  }, // end page

  guidPageFF: {
    title: 'Co-Borrower owns additional properties',
    titleShow: false,
    pageId: 'additional-properties-spouse',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, do you own any <strong>additional properties</strong> besides the property you currently live at and the property you are purchasing?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 1,
            },
            {
              field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.ownership',
              operator: RuleExprOp.EQ,
              value: 0,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, do you own any <strong>additional properties</strong> besides the property you currently live at and the property you are purchasing?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 1,
            },
            {
              field: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.ownership',
              operator: RuleExprOp.EQ,
              value: 0,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, do you own any <strong>additional properties</strong> besides the property you are purchasing?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 1,
            },
            {
              field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.ownership',
              operator: RuleExprOp.NE,
              value: 0,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, do you own any <strong>additional properties</strong> besides the property you are purchasing?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 1,
            },
            {
              field: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.ownership',
              operator: RuleExprOp.NE,
              value: 0,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, do you own any <strong>additional properties</strong> besides the property you currently live at and the property you are refinancing?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 2,
            },
            {
              field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.ownership',
              operator: RuleExprOp.EQ,
              value: 0,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, do you own any <strong>additional properties</strong> besides the property you currently live at and the property you are refinancing?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 2,
            },
            {
              field: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.ownership',
              operator: RuleExprOp.EQ,
              value: 0,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, do you own any <strong>additional properties</strong> besides the property you are refinancing?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 2,
            },
            {
              field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.ownership',
              operator: RuleExprOp.NE,
              value: 0,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, do you own any <strong>additional properties</strong> besides the property you are refinancing?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.loanPurposeType',
              operator: RuleExprOp.EQ,
              value: 2,
            },
            {
              field: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.ownership',
              operator: RuleExprOp.NE,
              value: 0,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Additional properties owned?',
        class: 'additional-properties',
        field: 'loan.transactionInfo.borrowers[1].borrowerDetail.additionalPropertiesOwned',
        errorCustom: 'Selecting an option for additional properties owned <strong>is required</strong>.',
        formFieldType: 'iconGroup',
        formFieldData: [
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ],
        validators: {
          required: true,
        },
      },
    ],
  }, // end page

  guidPageZ: {
    title: 'Property Review Section',
    titleShow: false,
    pageId: 'summary',
    isLastPage: true,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        // featureId: 'summary',
        featureId: 'summary-properties',
      },
    ],
  }, // end page
};
