import { FormControl, AbstractControl, FormGroup, FormArray } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { formatters } from './formatters.utils';
import { ControlType } from '../form-field.component';
import { combineLatest, Observable } from 'rxjs';

/**
 * Guesses value for the autocomplete attribute by looking at the field name.
 * See https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
 * @param formControl
 */
export const autoCompleteSuggest = (formControl: FormControl) => {
  // No field found, return null
  if (!formControl) {
    return null;
  }
  // Get name of field
  let field = formControlGetField(formControl);

  // No field found, return null
  if (!field) {
    return null;
  }

  // Convert to lower case
  field = field.toLowerCase();

  // Guess autocomplete attribute value
  if (field.includes('city')) {
    return 'address-level2';
  }
  if (field.includes('state')) {
    return 'address-level1';
  }
  if (field.includes('zip')) {
    return 'postal-code';
  }
  if (field.includes('address')) {
    return 'street-address';
  }
  if (field.includes('email')) {
    return 'email';
  }
  if (field.includes('name')) {
    return 'name';
  }
  if (field.includes('phone') || field.includes('tel')) {
    return 'tel';
  }
  return null;
};

/**
 * Get the name of the field associated with this form control
 * @param formControl
 */
export const formControlGetField = (formControl: FormControl) => {
  if (formControl.parent) {
    const formGroup = formControl.parent.controls;
    // Get name of field
    return (
      Object.keys(formGroup)
        .find(name => formControl === (<any>formGroup)[name])
        .trim() || null
    );
  }
  return null;
};

/**
 * Check if a field is required
 * @param abstractControl
 */
export const isRequired = (abstractControl: AbstractControl): boolean => {
  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }
  if ((<any>abstractControl)['controls']) {
    for (const controlName in (<any>abstractControl)['controls']) {
      if ((<any>abstractControl)['controls'][controlName]) {
        if (isRequired((<any>abstractControl)['controls'][controlName])) {
          return true;
        }
      }
    }
  }
  return false;
};

/**
 * Check if current browser is Chrome
 */
export const isBrowserChrome = () => {
  const ua = window.navigator.userAgent;
  const isChrome = /Chrome/i.test(ua) && !/Edge/i.test(ua);

  return isChrome;
};

/**
 * Check if current browser is Firefox
 */
export const isBrowserFirefox = () => {
  const ua = window.navigator.userAgent;
  const isFirefox = /Firefox/i.test(ua);

  return isFirefox;
};

/**
 * Check if current browser is IE
 */
export const isBrowserIE = () => {
  const ua = window.navigator.userAgent;
  const isIE = /MSIE|Trident/.test(ua);

  return isIE;
};

/**
 * Check if current browser is a mobile browser
 */
export const isBrowserMobile = () => {
  const ua = window.navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);

  return isMobile;
};

/**
 * Clone an existing abstract control
 * @param control
 */
export const cloneAbstractControl = (control: AbstractControl) => {
  let newControl: AbstractControl;

  if (control instanceof FormGroup) {
    const formGroup = new FormGroup({}, control.validator, control.asyncValidator);
    const controls = control.controls;

    Object.keys(controls).forEach(key => {
      formGroup.addControl(key, cloneAbstractControl(controls[key]));
    });

    newControl = formGroup;
  } else if (control instanceof FormArray) {
    const formArray = new FormArray([], control.validator, control.asyncValidator);

    control.controls.forEach(formControl => formArray.push(cloneAbstractControl(formControl)));

    newControl = formArray;
  } else if (control instanceof FormControl) {
    newControl = new FormControl(control.value, control.validator, control.asyncValidator);
  } else {
    console.error('Error: unexpected control value');
  }

  if (control.disabled) {
    newControl.disable({ emitEvent: false });
  }

  return newControl;
};

/**
 * Creates a input mask control that shares state with a source control. Used to present different data visually to the user than is patched to the model
 * @param srcControl - A form control
 * @param type - The data type to format. Corresponds to the formatters
 * @param componentRef - A reference to the component with an OnDestroy
 * @param keyboardEvent - An observable of the keyboard event to pass to the input formatted, IE 'fromEvent(this.fieldRef.nativeElement, 'keyup')'
 */
export const inputMaskCreate = (
  srcControl: FormControl,
  type: ControlType,
  componentRef: any,
  keyboardEvent?: Observable<KeyboardEvent>,
  format?: string,
) => {
  const controlCustom = new FormControl(null); // Create new control
  // Is the source control's value changing when input from the custom control
  // Used to avoid infinite patchValue loop between src and new
  let canUpdate = true;

  const input = type && formatters[type] ? formatters[type].input : (val: any, _event?: KeyboardEvent, _format?: string) => val;
  const output = type && formatters[type] ? formatters[type].output : (val: any) => val;
  // Set default value for custom control
  controlCustom.patchValue(input(srcControl.value, null, format));

  // Get the source observable and combine with keyboard event if possible
  if (keyboardEvent) {
    combineLatest(srcControl.valueChanges, keyboardEvent).pipe(untilDestroyed(componentRef)).subscribe((val: any) => {
      controlCustom.patchValue(input(val[0], val[1] || null, format), { emitEvent: canUpdate });
    });
  }
  else {
    combineLatest(srcControl.valueChanges).pipe(untilDestroyed(componentRef)).subscribe((val: any) => {
      controlCustom.patchValue(input(val[0], null, format), { emitEvent: canUpdate });
    });
  }

  // When the source controls STATUS changes
  srcControl.statusChanges.pipe(untilDestroyed(componentRef)).subscribe(() => {
    updateControlState(srcControl, controlCustom);
    updateControlErrors(srcControl, controlCustom);
  });

  // When the custom components value changes
  controlCustom.valueChanges.pipe(untilDestroyed(componentRef)).subscribe((val: any) => {
    canUpdate = false;
    // srcControl.markAsTouched();
    srcControl.patchValue(output(val));
    canUpdate = true;
  });
  // the way change detetection is registered isn't quite right
  // the form-field component uses ChangeDetectorRef to register for changes
  // this doesn't work for this wrapped field because the original control receives the change events
  // we still need to propagate the values using OnChange
  // particularly when events are suppressed to ensure the value is synced
  srcControl.registerOnChange((value: any) =>
    controlCustom.setValue(input(value, null, format), { emitEvent: false })
  );

  updateControlState(srcControl, controlCustom);

  return controlCustom;

  function updateControlState(source: FormControl, target: FormControl) {
    // console.log('source statusChanges',source, source.errors);
    // status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED'
    // Disable custom control
    if (source.disabled && !target.disabled) {
      target.disable();
    }
    // Enable custom control
    if (!source.disabled && target.disabled) {
      target.enable();
    }
    if (source.touched) {
      target.markAsTouched();
    }
    if (source.untouched) {
      target.markAsUntouched();
    }
  }
  function updateControlErrors(source: FormControl, target: FormControl) {
    // Push errors to custom control
    if (source.errors) {
      target.setErrors(source.errors);
      target.markAsTouched();
    }
    // Remove errors from custom control
    if (!source.errors && target.errors) {
      target.setErrors(null);
    }
  }
};
