import { Pipe, PipeTransform } from '@angular/core';

// Usage: {{ value | parseDomFromString }}
// This will convert '&lt;div&gt;' to <div>
@Pipe({
  name: 'decodeHtmlEntities',
})
export class DecodeHtmlEntitiesPipe implements PipeTransform {
  constructor() {}

  public transform(value: string): string {
    const parser = new DOMParser();
    return parser.parseFromString(value, 'text/html').body.textContent;
  }
}
