import { Pipe, PipeTransform } from '@angular/core';
import { isDefined } from '../utils/data-validation-util';

@Pipe({
  name: 'arrayItem',
})
export class ArrayItemPipe implements PipeTransform {
  transform(value: any[]): any[] {
    return Array.isArray(value)
      ? value.filter(v => isDefined(v))
      : value;
  }
}
