import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

// Pipes
import { DatePipe, CurrencyPipe } from '@angular/common';
import { FilterPipe } from './pipes/filter.pipe';
import { DebouncePipe } from './pipes/debounce.pipe';
import { StringPipe } from './pipes/string.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { DecodeHtmlEntitiesPipe } from './pipes/decode-html-entities.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { CountPipe } from './pipes/count.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { TextCasePipe } from './pipes/text-case.pipe';
import { HtmlRemovePipe } from './pipes/html-remove.pipe';
import { SlugPipe } from './pipes/slug.pipe';
import { ArrayItemPipe } from './pipes/array-item.pipe';
import { EnumToStringPipe } from './pipes/enum-to-string.pipe';

// Directives
import { FullScreenDirective } from './directives/full-screen.directive';
import { FocusDirective } from './directives/focus.directive';
import { ModalLaunchDirective } from './directives/modal-launch.directive';
import { DomObserverDirective } from './directives/dom-observer.directive';
import { DebounceInputDirective } from './directives/debounce-input.directive';
import { FocusContainDirective } from './directives/focus-contain.directive';
import { AnalyticsDirective } from './directives/analytics.directive';
import { NoPasteDirective } from './directives/nopaste.directive';


// Pipes + Directives
export const APP_PIPES_DIRECTIVES = [
  // Pipes
  FilterPipe,
  DebouncePipe,
  StringPipe,
  SortPipe,
  SafeHtmlPipe,
  DecodeHtmlEntitiesPipe,
  PhoneNumberPipe,
  CountPipe,
  DurationPipe,
  TextCasePipe,
  HtmlRemovePipe,
  SlugPipe,
  ArrayItemPipe,
  EnumToStringPipe,

  // Directives
  FullScreenDirective,
  FocusDirective,
  ModalLaunchDirective,
  DomObserverDirective,
  DebounceInputDirective,
  FocusContainDirective,
  AnalyticsDirective,
  NoPasteDirective,
];

@NgModule({
  imports: [
    // Angular
    CommonModule,
  ],
  providers: [DatePipe, CurrencyPipe, SlugPipe],
  declarations: [APP_PIPES_DIRECTIVES],
  exports: [APP_PIPES_DIRECTIVES],
  entryComponents: [],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
