import {Directive, ElementRef, HostListener} from '@angular/core';

/**
 * This directive allows you to keep the focus events inside a DOM element. This is especially useful
 * for modals and expandable navigation menus. Users will only be able to "tab" through "focusable"
 * elements inside the DOM element that uses this directive
 * USAGE:
 * <div id="modal" focusContain></div>
 */
@Directive({
  selector: '[focusContain]'
})
export class FocusContainDirective {

  constructor(
    private hostElement: ElementRef
  ) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent): any {
    if (e.key === 'Tab') {
      const focusableEls = this.hostElement
        && this.hostElement.nativeElement
        && this.hostElement.nativeElement.querySelectorAll
        && this.hostElement.nativeElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');

      // Error handling
      if (!(focusableEls && focusableEls.length)) return;

      // Store reference to first and last
      const firstFocusableEl = focusableEls[0];
      const lastFocusableEl = focusableEls[focusableEls.length - 1];

      if (firstFocusableEl === lastFocusableEl) {
        return;
      }

      // Handle keypresses
      if (e.shiftKey) {
        /* shift + tab */
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } else {
        /* tab */
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
    }
  }
}
