<form [formGroup]="dateFormGroup">
  <h3 style="padding-bottom: 5px; font-size: 14px">{{ element.placeholder }}</h3>
  <div class="row date-fields" style="max-width: 280px">
    <div class="col-2 month">
      <mat-form-field>
        <mat-label>Month</mat-label>
        <input
          matInput
          #month
          type="text"
          [required]="isRequired"
          id="{{monthId}}"
          name="month"
          formControlName="month"
          (keydown)="onKeydownTabMonth(month.value, $event)"
          (blur)="onBlurMonth(month.value)"
          [attr.aria-label]="dateType + ' Month'">
      </mat-form-field>
    </div>
    <div class="col-1">/</div>
    <div class="col-2 day">
      <mat-form-field>
        <mat-label>Day</mat-label>
        <input
          matInput
          #day
          type="text"
          [required]="isRequired"
          id="{{dayId}}"
          name="day"
          formControlName="day"
          (keydown)="onKeydownTabDay(day.value, $event)"
          (blur)="onBlurDay(day.value)"
          [attr.aria-label]="dateType + ' Day'">
      </mat-form-field>
    </div>
    <div class="col-1">/</div>
    <div class="col-4">
      <mat-form-field>
        <mat-label>Year</mat-label>
        <input
          matInput
          #year
          type="text"
          [required]="isRequired"
          minlength="4"
          id="{{yearId}}"
          name="year"
          formControlName="year"
          (keydown)="onKeydownYear(year.value, $event)"
          (blur)="onBlurYear(year.value)"
          [attr.aria-label]="dateType + ' Year'">
      </mat-form-field>
    </div>
    <mat-error [ngClass]="requiredErrorPosition">
      <div *ngIf="dateFormGroup?.errors?.showDateIsRequired" class="small">
        {{ fullName }}{{ dateType }} is <strong>Required</strong>
      </div>
    </mat-error>
  </div>
  <div>
    <mat-error>
      <div *ngIf="(allFieldsValid && dateFormGroup?.errors?.invalidStartDate)" class="small">
          Your Start Date must be a date in the past.
      </div>
      <div *ngIf="(allFieldsValid && dateFormGroup?.errors?.invalidEndDate)" class="small">
          Your End Date must be a date in the past that ended after your Start Date.
      </div>
      <div *ngIf="(allFieldsValid &&
                  monthFormControl.value &&
                  dayFormControl.value &&
                  yearFormControl.value &&
                  yearHas4Digits &&
                  !isYearLessThan1900 &&
                  !isYearGreaterThan2100 &&
                  dateFormGroup?.errors?.invalidPurchaseDate)" class="small">
          Your Original Purchase Date must be a date in the past.
      </div>
      <div *ngIf="(allFieldsValid && dateFormGroup?.errors?.invalidProjectedEndOfServiceDate)" class="small">
          Your Projected End of Service Date must be a future date.
      </div>
      <div *ngIf="(allFieldsValid && dateFormGroup?.errors?.invalidincomeEndDate)" class="small">
        End Date is more than 2 years from today.
      </div>
      <div *ngIf="(allFieldsValid && dateFormGroup?.errors?.ageLessThan18)" class="small">
        {{ lessThan18ErrorMsg }}
      </div>
      <div *ngIf="(allFieldsValid && dateFormGroup?.errors?.invalidAppraisalDate)" class="small">
        Your Preferred Date must be a future date.
      </div>
      <div *ngIf="(monthFormControl?.touched && monthFormControl?.errors?.required && !dateFormGroup?.errors?.showDateIsRequired) ||
                  (!monthFormControl?.pristine && monthFormControl?.errors?.invalidMonth)" class="small">
        Please enter a valid 2-digit month.
      </div>
      <div *ngIf="((dayFormControl?.touched && dayFormControl?.errors?.required && !dateFormGroup?.errors?.showDateIsRequired) ||
                  (!dayFormControl?.pristine && dayFormControl?.errors?.invalidDay && !moreThanMaxDaysInMonth))" class="small">
        Please enter a valid 2-digit day.
      </div>
      <div *ngIf="(!dayFormControl?.pristine && dayFormControl?.errors?.invalidDay && moreThanMaxDaysInMonth)" class="small">
        {{ monthName }} does not have {{ daysNum }} days.
      </div>
      <div *ngIf="(yearFormControl?.touched && (yearFormControl?.errors?.required || yearFormControl?.errors?.minlength) && !dateFormGroup?.errors?.showDateIsRequired) ||
                  (!yearFormControl?.pristine && yearFormControl?.errors?.invalidYear && !isYearLessThan1900)" class="small">
        Please enter a valid 4-digit year.
      </div>
      <div *ngIf="!yearFormControl?.pristine && isYearLessThan1900" class="small">
        Please enter a year after 1900.
      </div>
      <div *ngIf="dateFormGroup?.errors?.invalidOptionalDate" class="small">
        Please enter a valid date.
      </div>
    </mat-error>
  </div>
</form>
