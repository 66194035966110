<app-header-simple></app-header-simple>

<main class="container" *ngIf="config$ | async as config" automationid="Page_Activation">

  <div class="row">
    <div class="col col-12" [ngClass]="isLsidProvidedInQuery ? 'col-sm-7 col-md-8 col-lg-8 offset-lg-1 order-2 order-sm-1' : 'col-lg-8 offset-lg-2'">
      <div class="card">

        <div class="card-body py-5 px-md-5">

          <ng-container *ngIf="isFormContentLoading; else showForm">
            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Loading...
          </ng-container>

          <ng-template #showForm>
          <div class="row">
            <div class="col-lg-6">
              <div class="text-center mb-5">
                  <h1 *ngIf="config && config['clover.account.activate.page.title'];else title" [innerHtml]="config['clover.account.activate.page.title'].value"></h1>
                  <ng-template #title>Let's activate your account</ng-template>
              </div>
              <div class="image-container">
                  <img *ngIf="config && config['clover.account.activate.page.image'];else image" [src]="config['clover.account.activate.page.image'].value" alt=""/>
                  <ng-template #image><img src="/assets/img/activate-account.png" alt=""/></ng-template>
              </div>
            </div>
            <div class="col-lg-6">
              <form [formGroup]="formMain" novalidate (ngSubmit)="onActivation(formMain)" automationid="Section_Activation">

                <div class="form-heading mb-5">
                  <h1>Account Activation</h1>
                </div>

                <div role="alert" class="alert alert-danger icon my-4" *ngIf="error">{{error}}</div>

                <mat-form-field>
                  <input matInput placeholder="Email" type="text" id="userName" formControlName="userName" automationid="Email">
                  <mat-error>Email is required</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="{{securityQuestion}}" type="text" id="securityAnswer" formControlName="securityAnswer" automationid="SecurityAnswer">
                  <mat-error>Security answer is required</mat-error>
                </mat-form-field>
                <div class="password-container">
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Enter a new password"
                      [type]="showNewPassword ? 'text' : 'password'"
                      id="password"
                      formControlName="password"
                      (focus)="showPasswordRequirements = true"
                      (blur)="showPasswordRequirements = false"
                      automationid="NewPassword"
                    >
                    <mat-error>Valid password is required</mat-error>
                  </mat-form-field>
                  <button
                    class="toggle-show-password"
                    type="button"
                    (click)="showNewPassword = !showNewPassword"
                    [attr.aria-pressed]="!!showNewPassword"
                    automationid="BtnShowNewPassword"
                  >
                    <i
                      class="fa"
                      [class.fa-eye]="!showNewPassword"
                      [class.fa-eye-slash]="showNewPassword"
                      aria-label="View and hide password toggle button"
                    ></i>
                  </button>
                </div>
                <app-password-requirements
                  [password]="formMain.value.password"
                  [isVisible]="showPasswordRequirements || (formMain.get('password').invalid && formMain.get('password').touched)"
                  automationid="PasswordRequirements"
                ></app-password-requirements>


                <div class="password-container">
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Confirm your password"
                      [type]="showConfirmPassword ? 'text' : 'password'"
                      id="passwordConfirm"
                      formControlName="passwordConfirm"
                      [errorStateMatcher]="passwordErrorMatcher"
                      automationid="PasswordConfirm"
                    >
                    <mat-error>
                      <ng-container *ngIf="formMain.hasError('notSamePassword'); else showDefaultConformPasswordError">
                        Password does not match
                      </ng-container>
                      <ng-template #showDefaultConformPasswordError>
                        Confirm password is required
                      </ng-template>
                    </mat-error>
                  </mat-form-field>
                  <button
                    class="toggle-show-password"
                    type="button"
                    (click)="showConfirmPassword = !showConfirmPassword"
                    [attr.aria-pressed]="!!showConfirmPassword"
                    automationid="BtnShowPasswordConfirm"
                  >
                    <i
                      class="fa"
                      [class.fa-eye]="!showConfirmPassword"
                      [class.fa-eye-slash]="showConfirmPassword"
                      aria-label="View and hide password toggle button"
                    ></i>
                  </button>
                </div>

                <div class="mt-2 mb-4" automationid="BtnSection">
                  <p>
                    By clicking "Activate &amp; Sign In" you agree to our
                    <a (click)="onTermsClick()">Terms of Use</a>
                    and our
                    <a (click)="onPrivacyClick()">Privacy Policy</a>.
                  </p>
                </div>

                <div class="text-center">
                  <button
                    mat-flat-button
                    color="accent"
                    class="activation"
                    type="submit"
                    [disabled]="waiting" [ngClass]="{'btn-waiting': waiting}"
                    automationid="BtnWaiting"
                  >
                    <ng-container *ngIf="!waiting; else showLoading">
                      Activate &amp; Sign In
                    </ng-container>
                    <ng-template #showLoading>
                      <i class="fa fa-spinner fa-spin"></i>
                      Loading...
                    </ng-template>
                  </button>
                </div>

              </form>
            </div>
          </div>
          </ng-template>
        </div>

      </div>
    </div>
    <div *ngIf="isLsidProvidedInQuery" class="col col-12 col-sm-5 col-md-4 col-lg-3 order-1 order-sm-2">
      <div class="sidebar sidebar-right">
        <app-sidebar-right noLoanLoading="true"></app-sidebar-right>
      </div>
    </div>
  </div>

</main>

<app-footer></app-footer>
