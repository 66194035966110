import { RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';
import { CPOSdateTypeEnum, CPOSEventsEnum, CPOSPageValidatorsEnum } from 'src/app/shared/models';

export const creditPages: CvFormBuilder.Pages = {
  guidPageA: {
    title: 'Credit Intro Section',
    titleShow: false,
    pageId: 'start',
    showButtonNext: false,
    showButtonBack: false,
    fullscreen: true,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'start',
        data: {
          sectionLabel: 'Credit',
          sectionTitle: 'Lastly, a few <strong>final details</strong> about you.',
          sectionImage: `/assets/img/application/credit.png`,
        },
      },
    ],
  }, // end page

  guidPageB: {
    title: 'What is your <strong>date of birth</strong> and <strong>Social Security number</strong>?',
    pageId: 'info',
    validatorId: CPOSPageValidatorsEnum.creditPullOptional,
    eventIds: {
      onNext: CPOSEventsEnum.creditAuthorized,
    },
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: 'Why do we need this information?',
          body: `
            <p>Your credit report provides more than just your credit score; all of the information within the report helps us to understand what loan products you qualify for and how low of an interest rate we can offer you.</p>
          `,
        },
      }, // end

      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'Date of Birth',
        data: {
          dateType: CPOSdateTypeEnum.dob,
          monthId: 'dobMonthB',
          dayId: 'dobDayB',
          yearId: 'dobYearB'

        },
        fields: [
          {
            field: 'loan.transactionInfo.borrowers[0].dateOfBirth',
            validators: {
              // required: true,
            },
          },
        ],
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Social Security Number',
        name: 'borrowerSSN',
        field: 'loan.transactionInfo.borrowers[0].ssn',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          // required: true,
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Confirm Social Security',
        name: 'borrowerCSSN',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        field: 'loan.$$custom.ssn',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          // required: true,
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder:
          'I ({{loan.transactionInfo.borrowers[0].firstName}} {{loan.transactionInfo.borrowers[0].lastName}}) authorize lender or its designated representatives to obtain a credit report from the national credit reporting agencies in connection with my home loan application.',
        field: 'loan.transactionInfo.loanApplications[].isRunCreditAuthorized',
        formFieldType: 'checkbox',
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder:
          'I ({{loan.transactionInfo.borrowers[0].firstName}} {{loan.transactionInfo.borrowers[0].lastName}}) authorize my lender or its designated representatives to submit a request to our third party data vendors to retrieve my income history to facilitate my mortgage loan application.',
        field: 'loan.$$custom.isRunEvoiEvoeAuthorized',
        formFieldType: 'checkbox',
        validators: {
          // required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.showVoiVoeAuthInCredit',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end
    ],
  }, // end page

  // Multiple borrowers screen
  guidPageBB: {
    title: 'What is your <strong>date of birth</strong> and <strong>Social Security number</strong>?',
    pageId: 'info-coborrower',
    validatorId: CPOSPageValidatorsEnum.creditPullSpouseOptional,
    eventIds: {
      onNext: CPOSEventsEnum.creditAuthorized,
    },
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `Why do we need this information?`,
          body: `
            <p>Your credit report provides more than just your credit score; all of the information within the report helps us to understand what loan products you qualify for and how low of an interest rate we can offer you.</p>
          `,
        },
      }, // end

      <CvFormBuilder.ContentColumns>{
        columns: [
          {
            width: 6,
            content: [
              <CvFormBuilder.Html>{
                type: 'html',
                html: '{{loan.transactionInfo.borrowers[0].firstName}} {{loan.transactionInfo.borrowers[0].lastName}}',
              }, // end
              <CvFormBuilder.Feature>{
                type: 'feature',
                featureId: 'date-control',
                placeholder: 'Date of Birth',
                data: {
                  dateType: CPOSdateTypeEnum.dob,
                  firstName: 'loan.transactionInfo.borrowers[0].firstName',
                  lastName: 'loan.transactionInfo.borrowers[0].lastName',
                  monthId: 'dobMonthB',
                  dayId: 'dobDayB',
                  yearId: 'dobYearB'
                },
                fields: [
                  {
                    field: 'loan.transactionInfo.borrowers[0].dateOfBirth',
                    validators: {
                      // required: true,
                    },
                  },
                ],
              },
              <CvFormBuilder.FormField>{
                type: 'formField',
                placeholder: 'Social Security Number',
                name: 'borrowerSSN',
                field: 'loan.transactionInfo.borrowers[0].ssn',
                suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
                formFieldType: 'ssn',
                maxlength: 9,
                validators: {
                  // minLength: 9,
                  // maxLength: 9,
                  // required: true,
                },
              }, // end
              <CvFormBuilder.FormField>{
                type: 'formField',
                placeholder: 'Confirm Social Security',
                name: 'borrowerCSSN',
                suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
                field: 'loan.$$custom.ssn',
                formFieldType: 'ssn',
                maxlength: 9,
                validators: {
                  // required: true,
                  // minLength: 9,
                  // maxLength: 9,
                },
              }, // end
            ],
          },
          {
            width: 6,
            content: [
              <CvFormBuilder.Html>{
                type: 'html',
                html: '{{loan.transactionInfo.borrowers[1].firstName}} {{loan.transactionInfo.borrowers[1].lastName}}',
              }, // end
              <CvFormBuilder.Feature>{
                type: 'feature',
                featureId: 'date-control',
                placeholder: 'Date of Birth',
                data: {
                  dateType: CPOSdateTypeEnum.dob,
                  firstName: 'loan.transactionInfo.borrowers[1].firstName',
                  lastName: 'loan.transactionInfo.borrowers[1].lastName',
                  monthId: 'dobMonthCB',
                  dayId: 'dobDayCB',
                  yearId: 'dobYearCB'
                },
                fields: [
                  {
                    field: 'loan.transactionInfo.borrowers[1].dateOfBirth',
                    validators: {
                      // required: true,
                    },
                  },
                ],
              },
              <CvFormBuilder.FormField>{
                type: 'formField',
                placeholder: 'Social Security Number',
                name: 'coBorrowerSSN',
                field: 'loan.transactionInfo.borrowers[1].ssn',
                suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
                formFieldType: 'ssn',
                maxlength: 9,
                validators: {
                  // required: true,
                  // minLength: 9,
                  // maxLength: 9,
                },
              }, // end
              <CvFormBuilder.FormField>{
                type: 'formField',
                placeholder: 'Confirm Social Security',
                name: 'coBorrowerCSSN',
                suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
                field: 'loan.$$custom.ssnSpouse',
                maxlength: 9,
                formFieldType: 'ssn',
                validators: {
                  // required: true,
                  // minLength: 9,
                  // maxLength: 9,
                },
              }, // end
            ],
          },
        ],
      }, // end CvFormBuilder.ContentColumns

      /** Show both checkboxes
      <CvFormBuilder.FormField>{
        id: 'joint',
        type: 'formField',
        placeholder: `We ({{loan.transactionInfo.borrowers[0].firstName}} {{loan.transactionInfo.borrowers[0].lastName}}, {{loan.transactionInfo.borrowers[1].firstName}} {{loan.transactionInfo.borrowers[1].lastName}}) authorize lender or its designated representatives to obtain a credit report from the national credit reporting agencies in connection with my home loan application.`,
        field: 'loan.transactionInfo.loanApplications[].isRunCreditAuthorized',
        formFieldType: 'checkbox',
        validators: {
          // required: true,
        },
      }, // end
       */
      <CvFormBuilder.FormField>{
        id: 'single',
        type: 'formField',
        placeholder: `I ({{loan.transactionInfo.borrowers[0].firstName}} {{loan.transactionInfo.borrowers[0].lastName}}) authorize lender or its designated representatives to obtain a credit report from the national credit reporting agencies in connection with my home loan application.`,
        field: 'loan.transactionInfo.loanApplications[].isRunCreditAuthorized',
        formFieldType: 'checkbox',
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder:
          'I ({{loan.transactionInfo.borrowers[0].firstName}} {{loan.transactionInfo.borrowers[0].lastName}}) authorize my lender or its designated representatives to submit a request to our third party data vendors to retrieve my income history to facilitate my mortgage loan application.',
        field: 'loan.$$custom.isRunEvoiEvoeAuthorized',
        formFieldType: 'checkbox',
        validators: {
          // required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.showVoiVoeAuthInCredit',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        id: 'single',
        type: 'formField',
        placeholder: `I ({{loan.transactionInfo.borrowers[1].firstName}} {{loan.transactionInfo.borrowers[1].lastName}}) authorize lender or its designated representatives to obtain a credit report from the national credit reporting agencies in connection with my home loan application.`,
        field: 'loan.$$custom.isRunCreditAuthorizedSpouse',
        formFieldType: 'checkbox',
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.FormField>{
        id: 'single',
        type: 'formField',
        placeholder:
          'I ({{loan.transactionInfo.borrowers[1].firstName}} {{loan.transactionInfo.borrowers[1].lastName}}) authorize my lender or its designated representatives to submit a request to our third party data vendors to retrieve my income history to facilitate my mortgage loan application.',
        field: 'loan.$$custom.isRunEvoiEvoeAuthorizedSpouse',
        formFieldType: 'checkbox',
        validators: {
          // required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.showVoiVoeAuthInCredit',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end
    ],
  }, // end page

  guidPageE: {
    title: 'Credit Scores',
    titleShow: false,
    pageId: 'credit-response',
    showButtonNext: false,
    showButtonBack: false,
    canSave: false,
    isLastPage: true,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: `credit-full`,
        data: {
          skipCreditReo: false,
        },
      },
    ],
  }, // end page

  guidPageZ: {
    title: 'Summary',
    isLastPage: true,
    pageId: 'summary',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'summary',
      },
    ],
  }, // end page
};
