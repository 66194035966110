<div class="loader-container text-center">
  <div class="my-4">
    <svg id="loading-graphic-svg" viewBox="0 0 330 212">
      <svg:defs>
        <svg:clipPath id="b">
          <svg:path d="m245.57 207.68v-156.38h-8.75z" class="fill-primary" />
        </svg:clipPath>
        <svg:clipPath id="c">
          <svg:rect transform="translate(195.83,-14.77)" width="81.99" height="253.28" class="fill-shaded" />
        </svg:clipPath>
        <svg:linearGradient id="a" x1="139.54" x2="168.55" y1="116.19" y2="115.84" gradientTransform="translate(.00015573 -.3238)" gradientUnits="userSpaceOnUse">
          <svg:stop class="stop-color-primary" offset="0" />
          <svg:stop style="stop-color:#ffffff;stop-opacity:0" offset="1" />
        </svg:linearGradient>
      </svg:defs>
      <svg:g>
        <svg:g id="loader-svg" transform="translate(-11.546 -14.329)">
          <svg:g id="loader">
            <svg:g id="laptop">
              <svg:g id="keyboard">
                <svg:path d="m286.35 216.47h-210.47l5.21 8.18 200.31-0.06z" stroke-miterlimit="10" style="fill:#f5f5f5;stroke-miterlimit:10;stroke-width:3.35;" class="stroke-primary" />
                <svg:path id="keyboard-shadow" d="m159 218h-80l3 5z" style="fill:#ccc" />
              </svg:g>
              <svg:g id="screen">
                <svg:rect transform="translate(91,82)" width="180" height="134" style="fill:#ccc;stroke-linejoin:round;stroke-width:3;" class="stroke-primary" />
                <svg:rect transform="rotate(89.99 86.907 176.88)" width="117.74" height="165.59" style="fill:#f5f5f5;stroke-linejoin:round;stroke-width:3.35;" class="stroke-primary" />
                <svg:path id="Screen-glare" d="m101 99v106h21z" class="fill-shaded" />
              </svg:g>
            </svg:g>
            <svg:g id="page">
              <svg:rect id="body-paper" transform="translate(123.01,51.46)" width="122.74" height="153.99" stroke-miterlimit="10" style="fill:#fff;stroke-miterlimit:10;stroke-width:.5" class="stroke-shaded" />
              <svg:g id="shadow">
                <svg:path d="m245.57 207.68v-156.38h-8.75z" style="fill-rule:evenodd" class="fill-shaded" />
                <svg:g clip-path="url(#b)">
                  <svg:rect transform="translate(195.83,-14.77)" width="81.99" height="253.28" class="fill-shaded" />
                  <svg:g clip-path="url(#c)">
                    <svg:rect transform="translate(235.46,45.8)" width="11.48" height="167.38" class="fill-shaded" />
                  </svg:g>
                </svg:g>
              </svg:g>
              <svg:g id="header-controls">
                <svg:rect transform="translate(123.01,51.46)" width="122.74" height="14.51" class="fill-primary" />
                <svg:rect transform="translate(153.13,57.04)" width="81.46" height="4.46" style="fill:#fff" />
                <svg:circle transform="translate(141.98,57.04)" cx="2.23" cy="2.23" r="2.23" style="fill:#fff" />
                <svg:circle transform="translate(135.28,57.04)" cx="2.23" cy="2.23" r="2.23" style="fill:#fff" />
                <svg:circle transform="translate(128.59,57.04)" cx="2.23" cy="2.23" r="2.23" style="fill:#fff" />
              </svg:g>
              <svg:g id="lines">
                <svg:rect transform="translate(132.05,159)" width="99.31" height="4.46" class="fill-shaded" />
                <svg:rect transform="translate(131.93,170.04)" width="99.31" height="4.46" class="fill-shaded" />
                <svg:rect transform="translate(131.93,181.2)" width="45.75" height="4.46" class="fill-shaded" />
              </svg:g>
            </svg:g>
            <svg:g id="completion">
              <svg:rect transform="translate(262.73,20.77)" width="46.29" height="43.23" stroke-miterlimit="10" style="fill:#fff;stroke-miterlimit:10;stroke-width:2.62;" class="stroke-primary" />
              <svg:g id="header">
                <svg:rect transform="translate(261.42,17.71)" width="48.91" height="9.61" class="fill-primary" />
                <svg:circle transform="translate(273.99,21.41)" cx="1.48" cy="1.48" r="1.48" style="fill:#fff" />
                <svg:circle transform="translate(269.55,21.41)" cx="1.48" cy="1.48" r="1.48" style="fill:#fff" />
                <svg:circle transform="translate(265.12,21.41)" cx="1.48" cy="1.48" r="1.48" style="fill:#fff" />
              </svg:g>
              <svg:rect transform="translate(276,33)" width="21" height="21" rx="3" class="fill-shaded" />
              <svg:rect transform="translate(276,33)" width="21" height="21" rx="3" stroke-miterlimit="10" style="fill:none;stroke-miterlimit:10;stroke-width:2;" class="stroke-primary" />
              <svg:path d="m292.11 39.39a1.44 1.44 0 0 0-2 0l-4.63 4.78-2-2a1.44 1.44 0 0 0-2 0 1.42 1.42 0 0 0 0 2l3 3a1.44 1.44 0 0 0 2 0l5.65-5.8a1.42 1.42 0 0 0-0.02-1.98z" class="fill-primary" />
              <svg:path d="m292.11 39.39a1.44 1.44 0 0 0-2 0l-4.63 4.78-2-2a1.44 1.44 0 0 0-2 0 1.42 1.42 0 0 0 0 2l3 3a1.44 1.44 0 0 0 2 0l5.65-5.8a1.42 1.42 0 0 0-0.02-1.98z" stroke-miterlimit="10" style="fill:none;stroke-miterlimit:10;stroke-width:.89;" class="stroke-primary" />
            </svg:g>
            <svg:g id="guage">
              <svg:rect id="box" transform="translate(18.67,139.05)" width="59.14" height="55.23" stroke-miterlimit="10" style="fill:#fff;stroke-miterlimit:10;stroke-width:3.35" class="stroke-primary" />
              <svg:g id="page-header">
                <svg:rect transform="translate(17,135.15)" width="62.49" height="12.27" class="fill-primary" />
                <svg:circle transform="translate(33.05,139.87)" cx="1.89" cy="1.89" r="1.89" style="fill:#fff" />
                <svg:circle transform="translate(27.38,139.87)" cx="1.89" cy="1.89" r="1.89" style="fill:#fff" />
                <svg:circle transform="translate(21.72,139.87)" cx="1.89" cy="1.89" r="1.89" style="fill:#fff" />
              </svg:g>
              <svg:path d="m25.93 178.69h12.07a7.63 7.63 0 0 1 1.39-3.31l-7.1-10a19.68 19.68 0 0 0-6.36 13.31z" style="fill:#f5f5f5" />
              <svg:path d="m41.05 173.22a7.59 7.59 0 0 1 5.45-2.62v-12a19.53 19.53 0 0 0-12.5 4.71z" class="fill-shaded" />
              <svg:path d="m49.2 158.56v12a7.59 7.59 0 0 1 5.39 2.55l7-9.9a19.55 19.55 0 0 0-12.39-4.65z" style="fill:#e3e3e3" />
              <svg:path d="m63.74 164.9-7.08 10a7.62 7.62 0 0 1 1.45 3.4h12.08a19.6 19.6 0 0 0-6.45-13.4z" style="fill:#ccc" />
              <svg:g stroke-miterlimit="10">
                <svg:path d="m49.2 157.73v12a7.63 7.63 0 0 1 5.39 2.55l7-9.9a19.48 19.48 0 0 0-12.39-4.65z" style="fill:none;stroke-miterlimit:10;stroke-width:1.44;" class="stroke-primary" />
                <svg:path d="m41.05 172.38a7.63 7.63 0 0 1 5.45-2.62v-12a19.52 19.52 0 0 0-12.5 4.71z" style="fill:none;stroke-miterlimit:10;stroke-width:1.44;" class="stroke-primary" />
                <svg:path d="m25.93 177.86h12.07a7.62 7.62 0 0 1 1.39-3.32l-7.1-10a19.68 19.68 0 0 0-6.36 13.32z" style="fill:none;stroke-miterlimit:10;stroke-width:1.44;" class="stroke-primary" />
                <svg:path d="m63.74 164.06-7.08 10a7.58 7.58 0 0 1 1.45 3.39h12.08a19.62 19.62 0 0 0-6.45-13.39z" style="fill:none;stroke-miterlimit:10;stroke-width:1.44;" class="stroke-primary" />
              </svg:g>
              <svg:path d="m50.33 178.15a2 2 0 1 1-3.32-2c0.64-1 1.81-3 11.84-15.77-6.48 14.84-7.85 16.71-8.52 17.77z" style="fill:#ccc" />
              <svg:path d="m49.27 177.49a2 2 0 1 1-3.32-2c0.64-1.06 1.8-3 11.83-15.77-6.47 14.84-7.87 16.71-8.51 17.77z" stroke-miterlimit="10" class="fill-primary" style="stroke-miterlimit:10;stroke-width:.48;" class="stroke-primary" />
            </svg:g>
            <svg:g id="mobile-phone">
              <svg:rect transform="translate(293.5,124.5)" width="45" height="80" rx="7" class="fill-shaded" />
              <svg:rect transform="translate(293.5,124.5)" width="45" height="80" rx="7" stroke-miterlimit="10" style="fill:none;stroke-miterlimit:10;stroke-width:3;" class="stroke-primary" />
              <svg:rect transform="translate(297,131.5)" width="37.5" height="64" stroke-miterlimit="10" style="fill:#fff;stroke-miterlimit:10;stroke-width:2;" class="stroke-primary" />
              <svg:rect transform="translate(299.5,144)" width="33" height="5.87" class="fill-primary" />
              <svg:rect transform="translate(299.5,153.13)" width="33" height="3.87" class="fill-primary" />
              <svg:rect transform="translate(299.5,160.13)" width="33" height="3.87" class="fill-primary" />
              <svg:rect transform="translate(299.5,167.13)" width="33" height="3.87" class="fill-primary" />
              <svg:rect transform="translate(299.5,173.13)" width="33" height="3.87" class="fill-primary" />
              <svg:rect transform="translate(299.5,180.88)" width="33" height="8.25" rx="3" style="fill:#ccc" />
              <svg:path d="m334 133h-13.95l13.95 62z" style="fill:#ccc;opacity:.5" />
              <svg:path d="m297.45 154.07v39.93h7.55z" style="fill:#ccc;opacity:.3" />
            </svg:g>
            <svg:g id="shield">
              <svg:path d="m82.21 58.37c-6.61 8.78-19.6 8.63-19.6 8.63v21.79c0 12.49 19.6 20 19.6 20s19.59-7.54 19.59-20v-21.79s-12.98 0.15-19.59-8.63z" stroke-miterlimit="10" style="fill:#fff;stroke-miterlimit:10;stroke-width:3.35;" class="stroke-primary" />
              <svg:path d="M 82.44,61.93 C 76.36,69.68 65,69 65,69 v 19.2 c 0,11 17.42,17.67 17.42,17.67 z" style="fill:#ccc;opacity:.4" />
              <svg:path d="m88.25 81.31c0-5-2.61-9-5.82-9s-5.81 4-5.81 9h-2.62v12.92h16.18v-12.92z" style="fill:#fff" />
              <svg:path d="m89.71 80.6h-1.5v-3a6 6 0 0 0-12 0v3h-1.5a1.5 1.5 0 0 0-1.5 1.5v12a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-12a1.5 1.5 0 0 0-1.5-1.5zm-12-3a4.5 4.5 0 0 1 9 0v3h-9zm12 16.49h-15v-12h15z" stroke-miterlimit="10" class="fill-primary" style="stroke-miterlimit:10;stroke-width:1.12;" class="stroke-primary" />
              <svg:path d="m82.21 91.09a1.5 1.5 0 0 0 1.5-1.5v-3a1.5 1.5 0 0 0-3 0v3a1.5 1.5 0 0 0 1.5 1.5z" stroke-miterlimit="10" class="fill-primary" style="stroke-miterlimit:10;stroke-width:1.12;" class="stroke-primary" />
            </svg:g>
          </svg:g>
        </svg:g>
      </svg:g>
      <svg:g id="spinner">
        <svg:path d="m136.6 113.31h-14.334a50.772 50.772 0 0 0 48.699 50.697v-14.361a36.439 36.439 0 0 1-34.365-36.336z" style="fill:url(#a);stroke-width:0" />
        <svg:path d="m170.97 62.965a50.772 50.772 0 0 0-48.699 50.668h14.334a36.439 36.439 0 0 1 34.365-36.363z" class="fill-primary" style="stroke-width:0" />
      </svg:g>
    </svg>
  </div>

  <div class="loader-message">
    <h1>Loading...</h1>

    <ng-content></ng-content>

  </div>
</div>
