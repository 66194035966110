import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Directive({
  selector: '[debounceInput]'
})
export class DebounceInputDirective implements OnInit, OnDestroy {

  @Input() debounceTime = 500;
  @Output() debounceChange: EventEmitter<any> = new EventEmitter();

  private subs: Subscription[] = [];

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    // Listen for keyup events
    // Filter unique values
    // Emit the value at the specified interval
    const debounceChange = fromEvent(this.elementRef.nativeElement, 'keyup')
      .pipe(
        map(() => {
          return this.elementRef
            && this.elementRef.nativeElement
            && this.elementRef.nativeElement.value || null;
        }),
        debounceTime(this.debounceTime),
        distinctUntilChanged()
      );

    this.subs.push(debounceChange.subscribe(input => this.debounceChange.emit(input)));
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length) {
      this.subs.forEach(x => x.unsubscribe());
    }
  }

}
