<!------------------------------
   CENTER / SECTION EXPANDING NAV
------------------------------->
<div class="navbar-collapse" id="navbar-section-nav" [ngClass]="{ 'collapse show': isSectionNavOpen }" automationid="Section_Center">
  <div class="navbar-mobile from-top">
    <div class="navbar-content">
      <!-- APPLICATION ROUTE -->
      <ng-container *ngIf="whichNavToShow === whichNavEnum.Application">
        <app-application-nav
          [showProgress]="false"
          [canAccessAll]="false"
          [isSectionNavOpen]="isSectionNavOpen"
          [attr.aria-hidden]="!isSectionNavOpen"
          [uniqueId]="'navbarSection'"
        ></app-application-nav>
      </ng-container>
      <!-- DASHBOARD ROUTE -->
      <ng-container *ngIf="whichNavToShow === whichNavEnum.Dashboard">
        <app-dashboard-nav [showSectionIcon]="false" [isSectionNavOpen]="isSectionNavOpen"></app-dashboard-nav>
      </ng-container>
    </div>
  </div>
  <div class="nav-mobile-bg" (click)="isSectionNavOpen = !isSectionNavOpen"></div>
</div>
<!------------------------------
   DESKTOP NAV
------------------------------->
<nav class="navbar d-flex py-md-3 px-0" automationid="Section_DesktopNavigation">
  <div class="col-2 col-md-4">
    <button
      class="navbar-toggler px-0"
      type="button"
      aria-controls="navbar-main"
      aria-expanded="false"
      aria-label="Open Global Menu"
      (click)="isMainNavOpen = !isMainNavOpen; leftNavCloseButton.focus();track()"
      automationid="BtnOpenGlobalMenu"
    >
      <i class="fa fa-bars" aria-hidden="true"></i>
    </button>
  </div>

  <div class="col-8 col-md-4 text-center">
    <a class="main-header-logo d-none d-md-block" routerLink="/">
      <app-company-logo></app-company-logo>
    </a>
    <div class="mobile-dropdown d-md-none">
      <ng-container [ngSwitch]="whichNavToShow">
        <!-- APPLICATION ROUTE -->
        <ng-container *ngSwitchCase="whichNavEnum.Application">
          <div
            (click)="isSectionNavOpen = !isSectionNavOpen"
            aria-label="Open section menu to navigate through sections within your loan application"
            [attr.aria-hidden]="!isSectionNavOpen"
            automationid="DivOpenSectionMenu"
          >
            <app-application-nav
              [showList]="false"
              [showExpandArrow]="true"
              [progressRadius]="33"
              [isSectionNavOpen]="isMainNavOpen"
              [attr.aria-hidden]="!isMainNavOpen"
              [uniqueId]="'openSectionMenu'"
            ></app-application-nav>
          </div>
        </ng-container>
        <!-- DASHBOARD ROUTE -->
        <ng-container *ngSwitchCase="whichNavEnum.Dashboard">
          <div (click)="isSectionNavOpen = !isSectionNavOpen" aria-label="Section Menu for Loan Documentation">
            <app-dashboard-nav [showList]="false" [showExpandArrow]="true"></app-dashboard-nav>
          </div>
        </ng-container>
        <!-- ALL OTHER ROUTES -->
        <ng-container *ngSwitchDefault>
          <div class="main-header-logo">
            <app-company-logo></app-company-logo>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="col-2 col-md-4 text-end">
    <!-- ONLY SHOW IN 1003 AND DASHBOARD -->
    <ng-container *ngIf="whichNavToShow === whichNavEnum.Application || whichNavToShow === whichNavEnum.Dashboard">
      <!-- LARGER SCREENS -->
      <div class="d-none d-lg-block phone-number">
        <ng-container *ngIf="loPhoneNumber$ | async as phoneNumber">
          <a href="tel:{{ phoneNumber }}" attr.aria-label="Call your loan officer at {{ phoneNumber }}">{{ phoneNumber }}</a>
        </ng-container>
      </div>
      <!-- SMALLER SCREENS -->
      <div class="d-lg-none">
        <button
          class="navbar-toggler px-0"
          type="button"
          aria-controls="navbar-main"
          aria-expanded="false"
          aria-label="Open to View Your Loan Team"
          (click)="isCommNavOpen = !isCommNavOpen; rightNavCloseButton.focus()"
          automationid="BtnOpenLoanTeam"
        >
          <i class="fa fa-comments-o" aria-hidden="true"></i>
        </button>
      </div>
      <!--<button [matMenuTriggerFor]="appMenu" class="btn-user-menu">-->
      <!--<i aria-hidden="true" role="presentation" class="fa fa-user-circle-o me-1"></i> <span class="me-1">My Account</span>-->
      <!--</button>-->
      <!--<mat-menu #appMenu="matMenu" xPosition="before">-->
      <!--<ng-container *ngTemplateOutlet="utilityNav"></ng-container>-->
      <!--</mat-menu>-->
    </ng-container>
  </div>
</nav>

<!------------------------------
   LEFT / MAIN MENU EXPANDING NAV
------------------------------->
<div
  class="navbar-collapse"
  id="navbar-main"
  role="dialog"
  aria-labelledby="left-nav"
  [ngClass]="{ 'collapse show': isMainNavOpen }"
  [attr.aria-hidden]="!isMainNavOpen"
  automationid="Section_LeftMainMenuExpandingNav"
>
  <div class="navbar-mobile from-left" focusContain>
    <div class="close-button">
      <button #leftNavCloseButton (click)="isMainNavOpen = false" aria-label="Close Navigation" [tabIndex]="isMainNavOpen ? '0' : '-1'" automationid="BtnCloseNavigation">
        <!--        <i aria-hidden="true" class="fa fa-close"></i>-->
      </button>
    </div>

    <div class="navbar-content">
      <div class="main-menu-logo">
        <app-company-logo configKey="clover.logo.menu.image"></app-company-logo>
      </div>

      <ul class="navbar-nav main-nav-items">
        <li class="nav-item">
          <a
            class="nav-link"
            (click)="onMyLoansClicked()"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            [tabIndex]="isMainNavOpen ? '0' : '-1'"
            automationid="AMyLoans"
          >
            My Loans
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            (click)="onMyAccountClicked()"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            [tabIndex]="isMainNavOpen ? '0' : '-1'"
            automationid="AMyAccount"
          >
            My Account
          </a>
        </li>
      </ul>

      <ul class="navbar-nav">
        <li class="nav-item"><ng-container *ngTemplateOutlet="utilityNav"></ng-container></li>
      </ul>
    </div>
  </div>
  <div class="nav-mobile-bg" (click)="isMainNavOpen = !isMainNavOpen" [attr.aria-hidden]="isMainNavOpen" automationid="DivIsMainNavOpen"></div>
</div>

<!------------------------------
   RIGHT / COMMUNICATIONS EXPANDING NAV
------------------------------->
<div
  class="navbar-collapse"
  id="navbar-communications"
  [ngClass]="{ 'collapse show': isCommNavOpen }"
  [attr.aria-hidden]="!isCommNavOpen"
  automationid="Section_RightCommunicationExpandingNav"
>
  <div class="navbar-mobile from-right" focusContain>
    <div class="close-button">
      <button
        #rightNavCloseButton
        (click)="isCommNavOpen = false"
        aria-label="Close loan team menu"
        [tabIndex]="isCommNavOpen ? '0' : '-1'"
        automationid="BtnCloseLoanTeamMenu"
      ></button>
    </div>
    <app-sidebar-right [isCommNavOpen]="isCommNavOpen" automationid="DivIsCommNavOpen"></app-sidebar-right>
  </div>
  <div class="nav-mobile-bg" (click)="isCommNavOpen = !isCommNavOpen"></div>
</div>

<!------------------------------
   Utility nav
   Used in user dropdown and below main nav in mobile
------------------------------->
<ng-template #utilityNav>
  <!--<button mat-menu-item disabled>Version {{ version }}</button>-->
  <!--
  <button mat-menu-item class="nav-link" (click)="modals.open('FeedbackComponent')">
    <i class="fa fa-comments-o" aria-hidden="true"></i> Send Feedback
  </button>
  -->
  <a class="nav-link sign-out" (click)="logOut()" [tabIndex]="isMainNavOpen ? '0' : '-1'" automationid="ASignOut">
    <i class="fa fa-unlock-alt me-3" aria-hidden="true"></i> Sign Out
  </a>
</ng-template>
