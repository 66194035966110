import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-loading-graphic',
  templateUrl: './loading-graphic.component.html',
  styleUrls: ['./loading-graphic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingGraphicComponent implements OnInit, OnDestroy {

  constructor() {}

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
