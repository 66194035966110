import { RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';
import { CPOSPageValidatorsEnum } from 'src/app/shared/models';

export const demographicPages: CvFormBuilder.Pages = {
  guidPageA: {
    title: 'Demographics Intro Section',
    titleShow: false,
    pageId: 'start',
    isLastPage: true,
    showButtonNext: false,
    showButtonBack: false,
    fullscreen: true,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'start',
        data: {
          sectionLabel: 'Demographics',
          sectionTitle: `We have a few questions about your <strong>demographics</strong>`,
          sectionImage: `/assets/img/application/demographics.png`,
          sectionBody: `The following information is requested by the Federal Government for certain types of loans related to a dwelling in order to monitor the lender's compliance with equal credit opportunity, fair housing and home mortgage disclosure laws. You are not required to furnish this information, but are encouraged to do so. The law provides that a lender may not discriminate either on the basis of this information, or on whether you choose to furnish it. If you furnish the information, please provide both ethnicity and race. For race, you may check more than one designation. If you do not furnish ethnicity, race, or sex, under Federal regulations, this lender is required to note the information on the basis of visual observation and surname if you have made or complete this application in person.`,
        },
      },
    ],
  }, // end page

  guidPageB: {
    title: 'What is your race?',
    pageId: 'race',
    validatorId: CPOSPageValidatorsEnum.hasAtLeastOne,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<p><strong>Select all that apply</strong></p>',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'American Indian or Alaskan Native',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[0].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Enter Enrolled or Principle Tribe',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[0].freeFormDemographic',
        formFieldType: 'autoComplete',
        dataField: 'df-495',
      }, // end

      /**
       * Asian
       */
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Asian',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Asian Indian',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].subDemographics[0].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Chinese',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].subDemographics[1].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Filipino',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].subDemographics[2].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Japanese',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].subDemographics[3].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Korean',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].subDemographics[4].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Vietnamese',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].subDemographics[5].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Other Asian',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].subDemographics[6].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Enter Other Asian Race(s)',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].subDemographics[6].freeFormDemographic',
        formFieldType: 'autoComplete',
        dataField: 'df-496',
        hint: 'Laotian, Thai, Pakistani, Cambodian, etc.',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].subDemographics[6].selected',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end

      /**
       * Black
       */
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Black or African American',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[2].selected',
        formFieldType: 'checkbox',
      }, // end

      /**
       * Hawaiin
       */
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Native Hawaiian or Other Pacific Islander',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[3].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Native Hawaiian',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[3].subDemographics[0].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Guamanian or Chamorro',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[3].subDemographics[1].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Samoan',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[3].subDemographics[2].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Other Pacific Islander',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[3].subDemographics[3].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Enter Other Pacific Islander Race(s)',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[3].subDemographics[3].freeFormDemographic',
        formFieldType: 'autoComplete',
        dataField: 'df-498',
        hint: 'Fijian, Fongan, etc.',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[3].subDemographics[3].selected',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'White',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[4].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'I do not wish to provide this information',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[5].selected',
        formFieldType: 'checkbox',
      }, // end
    ],
  }, // end page

  guidPageC: {
    title: 'What is your ethnicity?',
    pageId: 'ethnicity',
    validatorId: CPOSPageValidatorsEnum.hasAtLeastOne,
    content: [
      /**
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'ethnicity'
      },
      */
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<p><strong>Select all that apply</strong></p>',
      }, // end
      /**
       * Hispanic
       */
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Hispanic or Latino',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.ethnicities[0].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Mexican',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.ethnicities[0].subDemographics[0].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Puerto Rican',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.ethnicities[0].subDemographics[1].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Cuban',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.ethnicities[0].subDemographics[2].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Other Hispanic or Latino',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.ethnicities[0].subDemographics[3].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Enter Other Hispanic or Latino Ethnicities',
        field:
          'loan.transactionInfo.borrowers[0].hmdaDemographics.ethnicities[0].subDemographics[3].freeFormDemographic',
        formFieldType: 'text',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.ethnicities[0].subDemographics[3].selected',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Not Hispanic or Latino',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.ethnicities[1].selected',
        formFieldType: 'checkbox',
      }, // end

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'I do not wish to provide this information',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.ethnicities[2].selected',
        formFieldType: 'checkbox',
      }, // end
    ],
  }, // end page

  guidPageD: {
    title: 'What is your sex?',
    pageId: 'sex',
    validatorId: CPOSPageValidatorsEnum.hasAtLeastOne,
    isLastPage: true,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<p><strong>Select all that apply</strong></p>',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Male',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.sex[0].selected',
        formFieldType: 'checkbox',
        validators: {
          // required: true,
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Female',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.sex[1].selected',
        formFieldType: 'checkbox',
        validators: {
          // required: true,
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'I do not wish to provide this information',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.sex[2].selected',
        formFieldType: 'checkbox',
        validators: {
          // required: true,
        },
      }, // end
    ],
  }, // end page

  guidPageBPrimary: {
    title: `What is your race?`,
    titleShow: false,
    pageId: 'race-primary',
    validatorId: CPOSPageValidatorsEnum.hasAtLeastOne,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center page-title">{{loan.transactionInfo.borrowers[0].firstName}}, what is your race?</h2>',
      }, // end
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<p><strong>Select all that apply</strong></p>',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'American Indian or Alaskan Native',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[0].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Enter Enrolled or Principle Tribe',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[0].freeFormDemographic',
        formFieldType: 'autoComplete',
        dataField: 'df-495',
      }, // end

      /**
       * Asian
       */
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Asian',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Asian Indian',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].subDemographics[0].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Chinese',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].subDemographics[1].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Filipino',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].subDemographics[2].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Japanese',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].subDemographics[3].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Korean',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].subDemographics[4].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Vietnamese',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].subDemographics[5].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Other Asian',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].subDemographics[6].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Enter Other Asian Race(s)',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].subDemographics[6].freeFormDemographic',
        formFieldType: 'autoComplete',
        dataField: 'df-496',
        hint: 'Laotian, Thai, Pakistani, Cambodian, etc.',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[1].subDemographics[6].selected',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end

      /**
       * Black
       */
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Black or African American',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[2].selected',
        formFieldType: 'checkbox',
      }, // end

      /**
       * Hawaiin
       */
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Native Hawaiian or Other Pacific Islander',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[3].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Native Hawaiian',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[3].subDemographics[0].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Guamanian or Chamorro',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[3].subDemographics[1].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Samoan',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[3].subDemographics[2].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Other Pacific Islander',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[3].subDemographics[3].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Enter Other Pacific Islander Race(s)',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[3].subDemographics[3].freeFormDemographic',
        formFieldType: 'autoComplete',
        dataField: 'df-498',
        hint: 'Fijian, Fongan, etc.',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[3].subDemographics[3].selected',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'White',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[4].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'I do not wish to provide this information',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.races[5].selected',
        formFieldType: 'checkbox',
      }, // end
    ],
  }, // end page

  guidPageCPrimary: {
    title: 'What is your ethnicity?',
    titleShow: false,
    pageId: 'ethnicity-primary',
    validatorId: CPOSPageValidatorsEnum.hasAtLeastOne,
    content: [
      /**
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'ethnicity'
      },
      */
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center page-title">{{loan.transactionInfo.borrowers[0].firstName}}, what is your ethnicity?</h2>',
      }, // end
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<p><strong>Select all that apply</strong></p>',
      }, // end
      /**
       * Hispanic
       */
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Hispanic or Latino',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.ethnicities[0].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Mexican',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.ethnicities[0].subDemographics[0].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Puerto Rican',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.ethnicities[0].subDemographics[1].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Cuban',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.ethnicities[0].subDemographics[2].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Other Hispanic or Latino',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.ethnicities[0].subDemographics[3].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Enter Other Hispanic or Latino Ethnicities',
        field:
          'loan.transactionInfo.borrowers[0].hmdaDemographics.ethnicities[0].subDemographics[3].freeFormDemographic',
        formFieldType: 'text',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.ethnicities[0].subDemographics[3].selected',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Not Hispanic or Latino',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.ethnicities[1].selected',
        formFieldType: 'checkbox',
      }, // end

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'I do not wish to provide this information',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.ethnicities[2].selected',
        formFieldType: 'checkbox',
      }, // end
    ],
  }, // end page

  guidPageDPrimary: {
    title: 'What is your sex?',
    titleShow: false,
    pageId: 'sex-primary',
    validatorId: CPOSPageValidatorsEnum.hasAtLeastOne,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center page-title">{{loan.transactionInfo.borrowers[0].firstName}}, what is your sex?</h2>',
      }, // end
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<p><strong>Select all that apply</strong></p>',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Male',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.sex[0].selected',
        formFieldType: 'checkbox',
        validators: {
          // required: true,
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Female',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.sex[1].selected',
        formFieldType: 'checkbox',
        validators: {
          // required: true,
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'I do not wish to provide this information',
        field: 'loan.transactionInfo.borrowers[0].hmdaDemographics.sex[2].selected',
        formFieldType: 'checkbox',
        validators: {
          // required: true,
        },
      }, // end
    ],
  }, // end page

  guidPageBSecondary: {
    title: 'What is your race?',
    titleShow: false,
    pageId: 'race-secondary',
    validatorId: CPOSPageValidatorsEnum.hasAtLeastOne,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center page-title">{{loan.transactionInfo.borrowers[1].firstName}}, what is your race?</h2>',
      }, // end
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<p><strong>Select all that apply</strong></p>',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'American Indian or Alaskan Native',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[0].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Enter Enrolled or Principle Tribe',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[0].freeFormDemographic',
        formFieldType: 'autoComplete',
        dataField: 'df-495',
      }, // end

      /**
       * Asian
       */
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Asian',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[1].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Asian Indian',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[1].subDemographics[0].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Chinese',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[1].subDemographics[1].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Filipino',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[1].subDemographics[2].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Japanese',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[1].subDemographics[3].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Korean',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[1].subDemographics[4].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Vietnamese',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[1].subDemographics[5].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Other Asian',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[1].subDemographics[6].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Enter Other Asian Race(s)',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[1].subDemographics[6].freeFormDemographic',
        formFieldType: 'autoComplete',
        dataField: 'df-496',
        hint: 'Laotian, Thai, Pakistani, Cambodian, etc.',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[1].subDemographics[6].selected',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end

      /**
       * Black
       */
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Black or African American',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[2].selected',
        formFieldType: 'checkbox',
      }, // end

      /**
       * Hawaiin
       */
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Native Hawaiian or Other Pacific Islander',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[3].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Native Hawaiian',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[3].subDemographics[0].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Guamanian or Chamorro',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[3].subDemographics[1].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Samoan',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[3].subDemographics[2].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Other Pacific Islander',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[3].subDemographics[3].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Enter Other Pacific Islander Race(s)',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[3].subDemographics[3].freeFormDemographic',
        formFieldType: 'autoComplete',
        dataField: 'df-498',
        hint: 'Fijian, Fongan, etc.',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[3].subDemographics[3].selected',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'White',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[4].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'I do not wish to provide this information',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.races[5].selected',
        formFieldType: 'checkbox',
      }, // end
    ],
  }, // end page

  guidPageCSecondary: {
    title: 'What is your ethnicity?',
    titleShow: false,
    pageId: 'ethnicity-secondary',
    validatorId: CPOSPageValidatorsEnum.hasAtLeastOne,
    content: [
      /**
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'ethnicity'
      },
      */
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center page-title">{{loan.transactionInfo.borrowers[1].firstName}}, what is your ethnicity?</h2>',
      }, // end
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<p><strong>Select all that apply</strong></p>',
      }, // end
      /**
       * Hispanic
       */
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Hispanic or Latino',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.ethnicities[0].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Mexican',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.ethnicities[0].subDemographics[0].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Puerto Rican',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.ethnicities[0].subDemographics[1].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Cuban',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.ethnicities[0].subDemographics[2].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Other Hispanic or Latino',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.ethnicities[0].subDemographics[3].selected',
        formFieldType: 'checkbox',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'ms-5',
        placeholder: 'Enter Other Hispanic or Latino Ethnicities',
        field:
          'loan.transactionInfo.borrowers[1].hmdaDemographics.ethnicities[0].subDemographics[3].freeFormDemographic',
        formFieldType: 'text',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.ethnicities[0].subDemographics[3].selected',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Not Hispanic or Latino',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.ethnicities[1].selected',
        formFieldType: 'checkbox',
      }, // end

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'I do not wish to provide this information',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.ethnicities[2].selected',
        formFieldType: 'checkbox',
      }, // end
    ],
  }, // end page

  guidPageDSecondary: {
    title: 'What is your sex?',
    titleShow: false,
    pageId: 'sex-secondary',
    validatorId: CPOSPageValidatorsEnum.hasAtLeastOne,
    isLastPage: true,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center page-title">{{loan.transactionInfo.borrowers[1].firstName}}, what is your sex?</h2>',
      }, // end
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<p><strong>Select all that apply</strong></p>',
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Male',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.sex[0].selected',
        formFieldType: 'checkbox',
        validators: {
          // required: true,
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Female',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.sex[1].selected',
        formFieldType: 'checkbox',
        validators: {
          // required: true,
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'I do not wish to provide this information',
        field: 'loan.transactionInfo.borrowers[1].hmdaDemographics.sex[2].selected',
        formFieldType: 'checkbox',
        validators: {
          // required: true,
        },
      }, // end
    ],
  }, // end page

  guidPageZ: {
    title: 'Summary',
    pageId: 'summary',
    isLastPage: true,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'summary',
      },
    ],
  }, // end page
};
