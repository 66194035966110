import { Directive, ElementRef, Input, OnDestroy, AfterViewInit } from '@angular/core';
import Inputmask from 'inputmask';
import './inputmask.aliases';

@Directive({
  selector: '[appInputmask]'
})
export class InputmaskDirective implements AfterViewInit, OnDestroy {

  @Input('appInputmask')
  alias: string;

  private installedMask: Inputmask.Instance;
  constructor(private element: ElementRef) { }

  ngAfterViewInit() {
    this.installedMask = this.createInputmask(this.alias).mask(this.element.nativeElement);
  }

  private createInputmask(alias: string): Inputmask.Instance {
    return new Inputmask(alias);
  }

  ngOnDestroy() {
    if (this.installedMask) this.installedMask.remove();
  }
}
