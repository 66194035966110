import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  SimpleChanges,
  OnChanges,
  Input,
  Output, EventEmitter,
} from '@angular/core';
import { ApiService } from '$api';
import { AnalyticsService, AppSettings, PostMessageService } from '$shared';
import { filter, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { ICPOSIVOAViewModel, IRequestedDocumentViewModel } from 'src/app/shared/models';
import { FormGroup, AbstractControl, FormControl, Validators } from '@angular/forms';

export enum FormFreeStatus {
  IframeLoaded = 'IframeLoaded',
  AccountListLoading = 'AccountListLoading',
  AccountListLoaded = 'AccountListLoaded',
  AccountsProcessed = 'AccountsProcessed',
  Error = 'Error',
}
export interface FormFreeResult {
  finished: boolean;
  success: boolean;
  error: boolean;
  errorMessage?: string;
  status?: FormFreeStatus;
  selectedAccounts?: ICPOSIVOAViewModel[];
  assetIds?: string[];
}

@UntilDestroy()
@Component({
  selector: 'app-form-free',
  templateUrl: './form-free.component.html',
  styleUrls: ['./form-free.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFreeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() bnlItem: Partial<IRequestedDocumentViewModel>;
  @Input() isSpouseOnTheLoan: boolean;
  @Input() isPrimaryBorrowerAsset: boolean;
  @Input() borrowers: CvFormBuilder.FormFieldData[];
  @Output() result: EventEmitter<FormFreeResult> = new EventEmitter<FormFreeResult>();
  @Output() cancelClicked = new EventEmitter();
  iframeUrl: string;
  isLoading = false;
  isFinished = false;
  showIframe = false;
  showAccountList = false;
  accountList: ICPOSIVOAViewModel[] = [];
  selectedAccountList: ICPOSIVOAViewModel[] = [];
  error: string;
  accountAssignmentGroup: FormGroup;

  constructor(
    private api: ApiService,
    private settings: AppSettings,
    private ref: ChangeDetectorRef,
    private postMessageService: PostMessageService,
    private analytics: AnalyticsService,
    private router: Router,
  ) {
    this.accountAssignmentGroup = new FormGroup({});
  }

  ngOnInit(): void {
    // Listen for messages from the FormFree iframe
    this.postMessageService.listenForMessages()
      .pipe(
        untilDestroyed(this),
        filter(message => message === 'closeFrame'),
        take(1)
      )
      .subscribe(() => {
      this.showIframe = false;
      if (this.isSpouseOnTheLoan && this.isPrimaryBorrowerAsset) {
        this.getBankAccounts(this.bnlItem);
      } else {
        this.processAccounts(this.bnlItem);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bnlItem) {
      this.getEverifyIframeUrl(this.bnlItem);
    }
  }

  // Required for untilDestroyed()
  ngOnDestroy(): void {}

  /**
   * Get FormControl associated with finantial account
   * @param voaAccountId - VOA account identifier
   */
  getControlRef(voaAccountId: string): AbstractControl {
    return this.accountAssignmentGroup.get(voaAccountId);
  }

  /**
   * We first need to get the URL of the iframe in order to present the
   * borrower with the FormFree website
   * @param bnlItem Current BNL item from the list of BNL items
   */
  getEverifyIframeUrl(bnlItem: Partial<IRequestedDocumentViewModel>): void {

    if (!bnlItem) return;

    this.isLoading = true;
    this.ref.markForCheck();

    const loanAppId = this.settings.loanApplicationId;
    const borrowerId = bnlItem.borrowerId;
    const assetId = bnlItem.underlyingId;

    this.api.everify.getIframeUrl(loanAppId, borrowerId, assetId).subscribe((response) => {
      this.iframeUrl = response
        && response.response
        && response.response.value
        || null;

      if (this.iframeUrl) {
        this.showIframe = true;
        this.result.emit({
          error: false,
          success: false,
          finished: false,
          status: FormFreeStatus.IframeLoaded,
        });
      } else {
        this.setError(`An error occurred. Please try again.`);
      }
      this.isLoading = false;
      this.ref.markForCheck();

    }, () => {
      this.isLoading = false;
      this.setError(`An error occurred. Please try again.`);
      this.ref.markForCheck();
    });
  }

  /** Handle error messages */
  setError(error: string = null): void {
    this.error = error;
    this.result.emit({
      error: true,
      errorMessage: error,
      finished: true,
      success: false,
      status: FormFreeStatus.Error,
    });
    this.ref.markForCheck();
  }

  /**
   * This is called after we successfully log in with FormFree. It will give a list
   * of accounts, allowing the borrower to choose an account to verify
   * @param bnlItem Current BNL item from the list of BNL items
   */
  getBankAccounts(bnlItem: Partial<IRequestedDocumentViewModel>): void {
    this.isLoading = true;
    this.ref.markForCheck();

    const loanAppId = this.settings.loanApplicationId;
    const borrowerId = bnlItem.borrowerId;
    const financialId = bnlItem.financialInstitutionId;

    this.result.emit({
      finished: false,
      success: false,
      error: false,
      status: FormFreeStatus.AccountListLoading,
    });

    this.api.everify.getAccountList(loanAppId, borrowerId, financialId).subscribe((apiResponse) => {
      this.accountList = apiResponse
        && apiResponse.response
        && apiResponse.response.accounts
        || null;

      if (this.accountList && this.accountList.length) {
        this.accountAssignmentGroup = new FormGroup({});
        this.accountList.forEach(x => {
          this.accountAssignmentGroup.addControl(x.voaAccountId, new FormControl('', Validators.required));
        });

        this.showAccountList = true;
        this.isLoading = false;
        this.result.emit({
          finished: false,
          success: false,
          error: false,
          status: FormFreeStatus.AccountListLoaded,
        });
        this.ref.markForCheck();
      } else {
        this.isLoading = false;
        this.setError(`You have no accounts to verify. Either you already verified them or none were found for you.`);
      }
    }, () => {
      this.isLoading = false;
      this.setError(`An error occurred while retrieving your account list. Please try again.`);
    });
  }

  /**
   * Initiates back-end call to retrieve list of verified accounts and save them
   * @param bnlItem Current BNL item from the list of BNL items
   */
  processAccounts(bnlItem: Partial<IRequestedDocumentViewModel>): void {
    this.accountAssignmentGroup.updateValueAndValidity();
    if (!this.accountAssignmentGroup.valid) {
      for (var i in this.accountAssignmentGroup.controls) {
        this.accountAssignmentGroup.controls[i].markAsTouched();
      }
      return;
    }

    const loanAppId = this.settings.loanApplicationId;
    const assetInfoId = bnlItem.underlyingId;
    const borrowerId = bnlItem.borrowerId;

    const accountsData = {
      loanId: loanAppId,
      accounts: this.accountList.map(x => {
        return {
          jointAccount: x.ownerId === 'joint',
          ownerId: x.ownerId === 'joint' ? bnlItem.borrowerId : x.ownerId,
          voaAccountId: x.voaAccountId
        };
      }),
      assetInfoId,
      borrowerId,
      userAccountId: -1,
    };

    this.showAccountList = false;
    this.isLoading = true;
    this.ref.markForCheck();

    this.api.everify.processAccounts(accountsData).subscribe((apiResponse) => {
      this.isLoading = false;
      this.isFinished = true;

      this.analytics.trackEvent('Secure Account Linking Account(s) Recieved', {
        'Account Data Returned': 'Succesful'
      });

      this.accountList = (apiResponse && apiResponse.response && apiResponse.response.result) || null;

      this.analytics.trackEvent('Secure Account Linked accounts', {
        'Account(s) Linked': (this.accountList && this.accountList.length) ? 'Yes' : 'No',
        'Number of accounts linked': (this.accountList && this.accountList.length) ? this.accountList.length : 0,
        // 'Cancel': 'No'
      });

      if (this.accountList && this.accountList.length) {
        this.result.emit({
          error: false,
          success: true,
          finished: true,
          status: FormFreeStatus.AccountsProcessed,
          selectedAccounts: this.accountList,
          assetIds: this.accountList.map(x => x.assetInfoId),
        });
        this.ref.markForCheck();

        // Track event
        if (this.router.url.indexOf('/tasks/everify') > -1) {
          this.analytics.trackEvent('Link Bank Account Completed BDC');
        }
      } else {
        this.setError(`You have no accounts to verify. Either you already verified them or none were found for you.`);
      }
    }, () => {
      this.analytics.trackEvent('Secure Account Linking Account(s) Recieved', {
        'Account Data Returned': 'failure'
      });
      // TODO: handle processed accounts errors here
      this.isLoading = false;
      this.setError(`An error occurred while processing your account list. Please try again.`);
      this.ref.markForCheck();
    });
  }

  previousPage(): void {
    this.cancelClicked.emit();
  }
}
