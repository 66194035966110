// Test
export enum RuleExprOp {
  AN = 'AN',
  OR = 'OR',
  GT = 'GT',
  GE = 'GE',
  EQ = 'EQ',
  NE = 'NE',
  LT = 'LT',
  LE = 'LE',
  IN = 'IN',
  NI = 'NI',
  COUNT = 'COUNT',
}

// Test
export enum Actions {
  ArrayAddAppend = 'ArrayAddAppend',
  ArrayRemoveLast = 'ArrayRemoveLast',
  ArrayRemoveAtIndex = 'ArrayRemoveAtIndex',
  ArraySetIndexL1 = 'ArraySetIndexL1',
  ArraySetIndexL2 = 'ArraySetIndexL2',
  ArraySetIndexL3 = 'ArraySetIndexL3',
}

export enum ButtonActions {
  /** Go to next route */
  routeDefault,
  /** Go to next route */
  routeNext,
  /** Go to specified route */
  routeGoTo,
  /** Go to previous route */
  routePrevious,
  /** Go to next section */
  sectionNext,
  /** Add a model to an existing array and update the index */
  modelAdd,
  /** Change an array index */
  indexChange,
  /** Pop the delete modal */
  modalDelete,
  /** Change some data in the form model */
  formValueChange,
  /** Emit an action to the parent component */
  action
}
