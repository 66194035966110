import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '$api';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AuthorizationFormValidators, RepeatPasswordErrorStateMatcher } from '../../shared/validators/authorization-form.validators';
import { AppSettings } from '$shared';
import { IAuthenticationRequestViewModel, AuthenticationRequestTypeEnum } from 'src/app/shared/models';


@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyAccountComponent implements OnInit {
  /** Show loading state in UI */
  isWaiting: boolean;
  /** Show errors in UI */
  error: string;
  /** Show info messages in UI */
  info: string;
  /** Show success messages in UI */
  success: string;
  /** Loading indicator */
  isLoading = false;
  /** Formgroup we will bind to the form in the UI */
  formMain: FormGroup;
  /** Toggle password visibility */
  showCurrentPassword = false;
  showNewPassword = false;
  showConfirmPassword = false;
  /** Show confirm password errors */
  passwordErrorMatcher = new RepeatPasswordErrorStateMatcher();
  /** Toggle password requirements */
  showPasswordRequirements = false;
  /** Need to use these every time user name changes */
  userNameValidators: ValidatorFn[];
  /** Used to compare if the username as changed */
  currentUserName: string;

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private ref: ChangeDetectorRef,
    private settings: AppSettings,
  ) {}

  ngOnInit() {

    // Save these for later
    this.userNameValidators = [Validators.required, AuthorizationFormValidators.validEmail];

    // Store to compare when username changes
    this.currentUserName = this.settings.userName;
    // Create form and set default values
    this.formMain = this.fb.group({
      userName: [this.settings.userName, this.userNameValidators],
      password: [null, [
        AuthorizationFormValidators.oneLowercase,
        AuthorizationFormValidators.oneUppercase,
        AuthorizationFormValidators.oneDigit,
        AuthorizationFormValidators.specialCharacters,
        AuthorizationFormValidators.eightCharacters,
      ]],
      passwordConfirm: [null],
      passwordCurrent: [null, Validators.required],
    }, {validator: AuthorizationFormValidators.samePasswords});
  }

  /**
   * Check the API to see if the username is already being used
   */
  checkExistingUserName(userName: string): void {
    // Do not check if the field is invalid
    if (this.formMain.get('userName').invalid) return;
    // Do not check if the field is the same as when the page first loaded
    if (userName === this.currentUserName) return;
    this.api.userAccount.checkAvailability(userName).subscribe(apiResponse => {
      if (apiResponse.response) {
        this.markUserNameInvalid(userName);
      }
    });
  }

  markUserNameInvalid(userName: string): void {

    const userNameControl = this.formMain.get('userName');

    // Add a validator that does not allow the username
    userNameControl.setValidators([
      ...this.userNameValidators,
      AuthorizationFormValidators.doesNotEqual(userName),
    ]);

    // Update validity
    userNameControl.updateValueAndValidity();
  }

  /**
   * Called when form is submitted / save button is clicked
   * @param data
   */
  updateMyAccount(formMain: FormGroup): void {

    if (formMain.invalid) return;

    const formMainValues = formMain.value;

    // Clear messages
    this.setError();
    this.setInfo();
    this.setSuccess();

    const updatedUserAccountName = this.currentUserName && this.currentUserName !== formMainValues.userName
      ? formMainValues.userName
      : null;


    // Check if borrower is attempting to update username
    // or password. If neither, do nothing at all
    if (!(formMainValues.password || updatedUserAccountName)) {
      this.setInfo(`No changes have been made to your account.`);
      return;
    }

    // Prepare API request
    const authRequest = {
      authenticationRequestViewModel: <IAuthenticationRequestViewModel>{
        authenticationRequestType: AuthenticationRequestTypeEnum.UpdateAccount,
        authentication: {
          newPassword: (formMainValues.password || null),
          password: (formMainValues.passwordCurrent || null),
          userAccountName: (this.currentUserName || null),
          updatedUserAccountName,
        }
      }
    };

    this.isWaiting = true;
    this.api.userAccount.updateAccount(authRequest).subscribe(apiResponse => {
      if (apiResponse
        && apiResponse.response
        && apiResponse.response.succeeded
      ) {
        // Check to see which type of success message we should show here
        if (formMainValues.password && updatedUserAccountName) {
          this.setSuccess(`Your email and password has been updated!`);
        } else if (formMainValues.password) {
          this.setSuccess(`Your password has been updated!`);
        } else if (updatedUserAccountName) {
          this.setSuccess(`Your email address has been updated!`);
        }
        // Update saved username
        if (updatedUserAccountName) {
          this.currentUserName = updatedUserAccountName;
          this.settings.userName = updatedUserAccountName;
        }
      } else {
        this.setError(`An error occurred trying to access your account. Please check your password and try again.`);
      }
      this.isWaiting = false;
    }, () => {
      this.setError(`An error occurred trying to access your account. Please check your password and try again.`);
      this.isWaiting = false;
    });
  }

  setError(error: string = null) {
    this.error = error;
    this.ref.markForCheck();
  }

  setInfo(info: string = null) {
    this.info = info;
    this.ref.markForCheck();
  }

  setSuccess(success: string = null) {
    this.success = success;
    this.ref.markForCheck();
  }

}
